
export const capitalizeString = (str: string): string => {
  if (!str) return '';

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getMarkColor = (mark: number): string => {
  if (mark >= 8) return 'green';
  if (mark >= 5) return 'goldenrod';
  return 'red';
};

export const getQuery = (data: any): string => {
  return `?${new URLSearchParams(data).toString()}`;
}

export const nameToInitials = (fullName: string): string => {
  const namesArray = fullName.trim().split(' ');
  let name;
  if (namesArray.length === 1) {
    name = `${namesArray[0].charAt(0)}`;
  }
  else { name = `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`; }

  return name.toUpperCase()
}
