import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import Navigation from "./navigation";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import { ToastContainer } from "react-toastify";
import 'react-reflex/styles.css';
import 'react-tagsinput/react-tagsinput.css'

const container = document.getElementById('root')!;
const root = createRoot(container);

let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navigation></Navigation>
        <ToastContainer autoClose={2000} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
