import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { ListParams } from '../../models/common'
import { issueActions } from './issueSlice'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common'
import { ListIssuesResponse } from '../../models';

function* handleListIssues(action: PayloadAction<ListParams>) {

  const query = getQuery(action.payload.data);
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/projects/${action.payload.project_id}/list-exceptions${query}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(issueActions.listIssuesSuccess(result.payload));
  }
  return { error, result };
}

function* handleIssueDetail(action: PayloadAction) {
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/exceptions/${action.payload}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(issueActions.listIssueDetailSuccess(result.payload));
  }
  return { error, result };
}

function* handleListIssuesGraph(action: PayloadAction) {
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/projects/${action.payload}/get-exception-graph`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(issueActions.listIssueGraphDataSuccess(result.payload));
  }
  return { error, result };
}

export function* issueSaga() {
  yield takeLatest(issueActions.listIssues.type, handleListIssues);
  yield takeLatest(issueActions.listIssueDetail.type, handleIssueDetail);
  yield takeLatest(issueActions.listIssueGraphData.type, handleListIssuesGraph);
}
