import { RootState } from '../../../../app/store';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListPlansResponse , ListCardsResponse, NewCardAddPayload, BuySubscriptionPayload} from '../../../../models/billing';
import { ListParams, ListResponse} from '../../../../models/common';

export interface BillingState {
  plans: ListPlansResponse[],
  listPlansStatus: string,
  cards: ListCardsResponse[],
  listCardsStatus: string,
  addNewCardStatus: string,
  buySubscriptionStatus: string,
  cancelSubscriptionStatus: string,
  updateDefaultSourceStatus: string
}

const initialState: BillingState = {
  plans: [],
  listPlansStatus: '',
  cards: [],
  listCardsStatus: '',
  addNewCardStatus: '',
  buySubscriptionStatus: '',
  cancelSubscriptionStatus: '',
  updateDefaultSourceStatus: ''
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    listPlans(state, action: PayloadAction) {
      console.log('inside')
      state.listPlansStatus = 'pending'
    },
    listPlansSuccess(
      state,
      action: PayloadAction<ListResponse<ListPlansResponse>>
    ) {
      state.plans = action.payload.results;
      state.listPlansStatus = 'success'
    },
    listPlansFailed(state, action: PayloadAction<string>) {
      state.listPlansStatus = 'failed'
    },
    listCards(state, action: PayloadAction) {
      state.listCardsStatus = 'pending'
    },
    listCardsSuccess(
      state,
      action: PayloadAction<ListResponse<ListCardsResponse>>
    ) {
      state.cards = action.payload.results;
      state.listCardsStatus = 'success'
    },
    listCardsFailed(state, action: PayloadAction<string>) {
      state.listCardsStatus = 'failed'
    },
    addNewCard(state, action: PayloadAction<NewCardAddPayload>) {
      state.addNewCardStatus = 'pending'
    },
    addNewCardSuccess(state, action: PayloadAction<ListResponse<ListCardsResponse>>) {
      state.addNewCardStatus = 'success'
      state.cards = action.payload.results;
    },
    addNewCardFailed(state, action: PayloadAction) {
      state.addNewCardStatus = 'failed'
    },
    buySubscription(state, action: PayloadAction<BuySubscriptionPayload>) {
      state.buySubscriptionStatus = 'pending'
    },
    buySubscriptionSuccess(state, action: PayloadAction) {
      state.buySubscriptionStatus = 'success'
    },
    buySubscriptionFailed(state, action: PayloadAction<string>) {
      state.buySubscriptionStatus = 'failed'
    },
    cancelSubscription(state, action: PayloadAction<string>) {
      state.cancelSubscriptionStatus = 'pending'
    },
    cancelSubscriptionSuccess(state, action: PayloadAction) {
      state.cancelSubscriptionStatus = 'success'
      state.buySubscriptionStatus = ''
    },
    cancelSubscriptionFailed(state, action: PayloadAction<string>) {
      state.cancelSubscriptionStatus = 'failed'
    },
    updateDefaultSource(state, action: PayloadAction<string>) {
      state.updateDefaultSourceStatus = 'pending'
    },
    updateDefaultSourceSuccess(state, action: PayloadAction<ListResponse<ListCardsResponse>>) {
      state.updateDefaultSourceStatus = 'success'
      state.cards = action.payload.results;
    },
    updateDefaultSourceFailed(state, action: PayloadAction<string>) {
      state.updateDefaultSourceStatus = 'failed'
    },


  },
});

// Actions
export const billingActions = billingSlice.actions;

// Selectors
export const selectPlansList = (state: RootState) => state.billing.plans;
export const selectCardsList = (state: RootState) => state.billing.cards;
export const selectAddNewCardStatus = (state: RootState) => state.billing.addNewCardStatus;
export const selectBuySubscriptionStatus = (state: RootState) => state.billing.buySubscriptionStatus;
export const selectCancelSubscriptionStatus = (state: RootState) => state.billing.cancelSubscriptionStatus;
// Reducer
const billingReducer = billingSlice.reducer;
export default billingReducer;
