import { selectCurrentProject } from '../../../features/projects/projectSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

const ProjectSetup = () => {

  const currentProj: any = useAppSelector(selectCurrentProject);
  console.log('current project is---', currentProj)
  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Project Setup</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="notificationMenu">
            <span>
              <img src="../assets/images/icons/Bell.svg" alt="notification" />
            </span>
              <span className="notify_dot"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="md-cont-wrapper bg-white w-100">
              <div className="col-md-10">
                <div className="repo-timeline mt-2 project-setup-tmline">
                  <ul className="list-unstyled">
                    <li className="d-flex pb-5">
                      <span className="timeline-circle success">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      <div className="repo_cont">
                        <h6>Registered</h6>
                      </div>
                    </li>
                    <li className="d-flex pb-5">
                    { currentProj && !currentProj.app_installed && <span className="timeline-circle pending">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>}
                     {  currentProj && currentProj.app_installed && <span className="timeline-circle success">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>}
                      <div className="repo_cont">
                        <h6>Install</h6>
                        <p className="mb-20">Add our script to your web app</p>
                        <pre><code><span className="token tag"><span className="token tag"><span className="token punctuation">&lt;</span>script</span> <span className="token attr-name">src</span><span className="token attr-value"><span className="token punctuation attr-equals">=</span><span className="token punctuation">"</span>{`'${process.env.REACT_APP_LOGDUCK_JS_URI}'`}<span className="token punctuation">"</span></span><span> </span><span className="token attr-name">crossorigin</span><span className="token attr-value"><span className="token punctuation attr-equals">=</span><span className="token punctuation">"</span>anonymous<span className="token punctuation">"</span></span><span className="token punctuation">&gt;</span></span><span className="token script"></span><span className="token tag"><span className="token tag"><span className="token punctuation">&lt;/</span>script</span><span className="token punctuation">&gt;</span></span> </code>
                          <code><span className="token tag"><span className="token tag"><span className="token punctuation">&lt;</span>script</span><span className="token punctuation">&gt;</span> <span className="token attr-value">window.feedback && window.feedback.init('${
                            currentProj && currentProj.app_id
                          }');</span></span><span className="token script"></span><span className="token tag"><span className="token tag"><span className="token punctuation">&lt;/</span>script</span></span> </code></pre>
                      </div>
                    </li>
                    <li className="d-flex">
                    {currentProj && !currentProj.identify_call_added && <span className="timeline-circle pending">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>}
                      {  currentProj && currentProj.identify_call_added && <span className="timeline-circle success">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>}
                      <div className="repo_cont repo_identify_user">
                        <h6>Identify Users</h6>
                        <p className="mb-0">Track users so that you can search for sessions by user name and email</p>
                        <pre><code>{`feedback.identify('THE_USER_ID_IN_YOUR_APP', {
      name: 'John Doe',
      email: 'johndoe@example.com',
   });               `}</code>

                        </pre>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSetup;
