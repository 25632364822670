import login from "../features/auth/pages/login";
import dashboard from "../features/dashboard/pages/dashboard";
import signup from "../features/auth/pages/signup";
import forgotPassword from "../features/auth/pages/forgotPassword";
import sessions from "../features/sessions/pages/sessions";
import issues from "../features/issues/pages/issues";
import teamMembers from "../features/teamMembers/pages/teamMembers";
import integration from "../features/integrations/pages/integration";
import feedbackForms from "../features/feedbackForms/pages/feedbackForms";
import feedbacks from "../features/feedbacks/pages/feedbackList";
import profile from "../features/settings/pages/profile";
import gdpr from "../features/settings/pages/gdpr";
import projectSetting from "../features/settings/pages/projectSetting";
import projectSetup from "../features/settings/pages/projectSetup";
import issueDetail from "../features/issues/pages/issueDetail";
import sessionDetail from "../features/sessions/pages/sessionDetail";
import organisation from "../features/settings/pages/organisation";
import project from "../features/settings/pages/project";
import acceptInvite from "../features/auth/pages/acceptCompanyInvite";
import verifyEmail from "../features/auth/pages/verifyEmail";
import resetPassword from "../features/auth/pages/resetPassword";
import slackConnect from "../features/integrations/pages/connectToSlack";
import billingPlans from "../features/settings/pages/billing/pages/plans";
import privacy from "../features/settings/pages/privacy"

const Routes = [
  {
    component: login,
    path: "/",
    title: "LogDuck - Login",
    to: "/dashboard",
    type: "public",
  },
  {
    component: dashboard,
    path: "/dashboard",
    title: "LogDuck - Dashboard",
    to: "/",
    type: "private",
  },
  {
    component: dashboard,
    path: "/settings",
    title: "LogDuck - Dashboard",
    to: "/",
    type: "private",
  },

  {
    component: signup,
    path: "/signup",
    title: "LogDuck - Register",
    to: "/dashboard",
    type: "public",
  },
  {
    component: signup,
    path: "/register-invited-user",
    title: "LogDuck - Register Invited User",
    to: "/dashboard",
    type: "public",
  },
  {
    component: acceptInvite,
    path: "/invite",
    title: "LogDuck - Accept Invitation",
    to: "/dashboard",
    type: "common",
  },
  {
    component: resetPassword,
    path: "/reset-password",
    title: "LogDuck - Reset Password",
    to: "/dashboard",
    type: "common",
  },
  {
    component: verifyEmail,
    path: "/verify-email",
    title: "LogDuck -Email Verification",
    to: "/dashboard",
    type: "common",
  },
  {
    component: forgotPassword,
    path: "/forgotpassword",
    title: "LogDuck - Forgot Password",
    to: "/dashboard",
    type: "public",
  },
  {
    component: sessions,
    path: "/sessions",
    title: "LogDuck - Sessions",
    to: "/",
    type: "private",
  },
  {
    component: issues,
    path: "/issues",
    title: "LogDuck - Issues",
    to: "/",
    type: "private",
  },
  {
    component: teamMembers,
    path: "/team-members",
    title: "LogDuck - Team Members",
    to: "/",
    type: "private",
  },
  {
    component: integration,
    path: "/integration",
    title: "LogDuck - Integration",
    to: "/",
    type: "private",
  },
  {
    component: feedbackForms,
    path: "/feedback-forms",
    title: "LogDuck - Feedback Forms",
    to: "/",
    type: "private",
  },
  {
    component: profile,
    path: "/settings/profile",
    title: "LogDuck - Profile",
    to: "/",
    type: "private",
  },
  {
    component: gdpr,
    path: "/settings/GDPR",
    title: "LogDuck - GDPR Setting",
    to: "/",
    type: "private",
  },
  {
    component: feedbacks,
    path: "/feedbacks",
    title: "LogDuck - Feedbacks",
    to: "/",
    type: "private",
  },
  {
    component: projectSetting,
    path: "/settings/project-setting",
    title: "LogDuck - Project Setting",
    to: "/",
    type: "private",
  },
  {
    component: projectSetup,
    path: "/settings/project-setup",
    title: "LogDuck - Project Setup",
    to: "/",
    type: "private",
  },
  {
    component: issueDetail,
    path: "/issue-detail/:id",
    title: "LogDuck - Issue Detail",
    to: "/",
    type: "private",
  },
  {
    component: sessionDetail,
    path: "/session-detail/:id",
    title: "LogDuck - Session Detail",
    to: "/",
    type: "private",
  },
  {
    component: organisation,
    path: "settings/organisation",
    title: "LogDuck - Organisation",
    to: "/",
    type: "private",
  },
  {
    component: project,
    path: "settings/project",
    title: "LogDuck - Projects",
    to: "/",
    type: "private",
  },
  {
    component: privacy,
    path: "settings/privacy",
    title: "LogDuck - Privacy",
    to: "/",
    type: "private",
  },
  {
    component: slackConnect,
    path: "/ConnectToSlack",
    title: "LogDuck - Connect to slack",
    to: "/",
    type: "private",
  },
  {
    component: billingPlans,
    path: "/settings/plans",
    title: "LogDuck -Payment Plans",
    to: "/",
    type: "private",
  },
];

export default Routes;
