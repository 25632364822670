import { FunctionComponent, useState, useEffect, useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

interface AddCardProps {
  setShowAddCards: any;
  currentPlan?: any
  addNewCard: any
  newCardAddStatus: string
  showPaymentButton: boolean
}

export const AddNewCard: FunctionComponent<AddCardProps> = (props) => {
  let { setShowAddCards, currentPlan, addNewCard, showPaymentButton } = props;
  const [showCardNumberError, setshowCardNumberError] = useState<string>('');
  const [showCardDateError, setshowCardDateError] = useState<string>('');
  const [showCardCvvError, setshowCardCvvError] = useState<string>('');

  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      []
    );

    return options;
  };

  const stripe: any = useStripe();
  const elements: any = useElements();
  const options = useOptions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // console.log('inside hanlde element')
    const card = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(card);

    console.log({ token, error });

  };

  const saveCard = async (event: any) => {
    console.log('IN SAVE CARD IN ADD NEW CARD COMPONNET')
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // console.log('inside hanlde element')
    const card = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(card);
  
    if (token) {
      addNewCard({ token: token.id, set_default: true })
    }
  }

  const inputStyle = {
    iconColor: '#c4f0ff',
    color: '#00000',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#D3D3D3',
    },
  }

  return (
    <>
      <div>
        <form className="project-setting-form" onSubmit={handleSubmit}>
          <div className="continer-fluid">
            <div className=" pt-4 px-4 pb-5">
              <h5>Add new card</h5>
              <div className="row border-bottom pb-4">
                <div className="col-5">
                  {/* <div className="mb-4">
                    <label htmlFor="">Name on Card</label>
                    <input tabIndex={1} type="text" className="form-control" placeholder="Name" />
                  </div> */}

                  <div className="mb-4">
                    <label>Card number</label>
                    <div className="cardNumberRow position-relative">
                      {/* <input tabIndex={5} type="text" className="form-control" placeholder="Card Number" />
                      <img src="assets/images/card.svg" alt="" /> */}
                      <div className="form-control">
                        <CardNumberElement
                          options={{
                            style: {
                              base: inputStyle,
                            },
                          }}
                          onChange={event => {
                            console.log("CardNumberElement [change]", event);
                            if (event && event.error && event.error.message) setshowCardNumberError(event.error.message)
                            else setshowCardNumberError('')
                          }}
                        />
                      </div>
                      {showCardNumberError && showCardNumberError != '' && <span className="text-danger">{showCardNumberError}</span>}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label>Expiry date</label>
                        {/* <input tabIndex={6} type="email" className="form-control" placeholder="MM/YY" /> */}
                        <div className="form-control">
                          <CardExpiryElement
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                            onReady={() => {
                              console.log("CardNumberElement [ready]");
                            }}
                            onChange={event => {
                              console.log("CardNumberElement [change]", event);
                              if (event && event.error && event.error.message) setshowCardDateError(event.error.message)
                              else setshowCardDateError('')
                            }}
                          />
                        </div>
                        {showCardDateError && showCardDateError != '' && <span className="text-danger">{showCardDateError}</span>}
                      </div>
                      <div className="col-md-6 mb-4">
                        <label>CVV</label>
                        {/* <input tabIndex={7} type="text" className="form-control" placeholder="245" /> */}
                        <div className="form-control">
                          <CardCvcElement
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                            onReady={() => {
                              console.log("CardNumberElement [ready]");
                            }}
                            onChange={event => {
                              console.log("CardNumberElement [change]", event);
                              if (event && event.error && event.error.message) setshowCardCvvError(event.error.message)
                              else setshowCardCvvError('')
                            }}

                          />
                        </div>
                        {showCardCvvError && showCardCvvError != '' && <span className="text-danger">{showCardCvvError}</span>}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-12 formBottom companyInfoFromBottom">
                {showPaymentButton && <p className="text-dark pb-1 mt-3">Your card will be charged €{(currentPlan.amount) / 100} monthly, By clicking ‘Pay now’ you are agree to our <a href="">terms and conditions</a></p>}
                <button type="button" className="btn btn-outline-secondary" onClick={() => setShowAddCards(false)}>Back</button>
                <button type="button" className="btn btn-info ms-3 saveCrdBtn" onClick={(e) => saveCard(e)}>Save Card</button>
                {showPaymentButton && <button type="submit" className="btn btn-info ms-3">Pay Now</button>}
              </div>

            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewCard;
