import React, { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { AddProjectPayload } from '../../models/project';

interface AddNewProjectModalProps {
  onSubmit: any;
  organisation_id: string;
}

export const AddNewProjectModel: FunctionComponent<AddNewProjectModalProps> = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm<AddProjectPayload>();

  const onSubmit: SubmitHandler<AddProjectPayload> = data => {
    data.organisation_id = props.organisation_id;
    props.onSubmit(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 pb-2">
        <label htmlFor="Email1" className="form-label">Project Name</label>
        <input type="text" className="form-control" id="InputEmail1" placeholder="Project name" {...register("name", { required: true, })} />
        {errors.name && <span>Please enter project name</span>}
      </div>
      <button type="submit" className="btn btn-info py-2 px-4">Add Project</button>
    </form>
  );
};
