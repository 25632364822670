import { Modal } from '../../../components/modals/modal';
import { AddNewCompanyModel } from '../../../components/modals/addNewOrganization';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { organizationActions, selectOrganizationList, fetchOrganizationStatus, setCurrentOrganizationStatus, selectCurrentOrganization, addNewOrganizationStatus, getOrganizationCount } from '../../../features/organizations/organizationSlice';
import { projectActions, selectProjectList, fetchProjectStatus, selectCurrentProject, addNewProjectStatus } from '../../../features/projects/projectSlice';
import { ListOrganizationResponse, AddOrganisationPayload } from '../../../models/organization';
import { AddProjectPayload } from '../../../models/project'
import { Pagination } from '../../../components/pagination';

const Organisation = () => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [isAddCompanyModalShown, setIsAddCompanyModalShown] = useState<boolean>(false);
  const organizations = useAppSelector(selectOrganizationList); // Organization List
  const addNewOrgStatus: string = useAppSelector(addNewOrganizationStatus) // Status of if new organisation added succes or not
  const currentOrganization: any = useAppSelector(selectCurrentOrganization); // List of current/active organisation
  const totalOrganizations: number = useAppSelector(getOrganizationCount);
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per


  console.log('total org coun', totalOrganizations)
  /**List organization on page load */
  useEffect(() => {
    const data = {
      limit: 20,
      skip: 0,
      sort_key: "created_at",
      sort_order: 1,
    };
    dispatch(organizationActions.listOrganization(data))
  }, [])

  /**Function to be called on submit of add new organisation form */
  const addNewOrganization = (data: AddOrganisationPayload) => {
    dispatch(organizationActions.addNewOrganization(data))
  }

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {
    setCurrentPage(page)
    let data = {
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(organizationActions.listOrganization(data));
  }

  /**When new organization added successfully, hide the add new org popup */
  useEffect(() => {
    if (addNewOrgStatus == 'success') {
      setIsAddCompanyModalShown(false)
    }
  }, [addNewOrgStatus])


  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">My companies</h1>
          <div className="d-flex justify-content-between align-items-center">
            <button type="button" className="btn btn-info addMemberBtn" data-bs-toggle="modal" onClick={() => setIsAddCompanyModalShown(!isAddCompanyModalShown)}>
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 1V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              Add New Organization
            </button>
          </div>
        </div>
        <Modal
          isShown={isAddCompanyModalShown}
          hide={() => setIsAddCompanyModalShown(!isAddCompanyModalShown)}
          headerText="Add Organization"
          modalContent={
            <AddNewCompanyModel
              onSubmit={addNewOrganization}
            />
          }
        />
        <div className="row">
          <div className="container-fluid">
            <div className="companies_dtl_col">
              {organizations.length > 0 && organizations.map((org: any, idx: number) => (
                <div className="companies_dtl_list d-flex align-items-center justify-content-between" key={idx}>
                  <div className="media">
                    <span className="logoIconLD logoIconLD_brn">
                      {org.name[0]}
                    </span>
                    <div className="media-body">
                      <h5 className="mb-1 text-secondary">{org.name}</h5>
                      <p className="mb-0">Admin |  {org.users ? org.users.length : 1} User(s)</p>
                    </div>
                  </div>
                  <div className="companies_dtl_btns">
                    {org._id == currentOrganization._id && <button className="btn btn-outline-light singInBtn active me-3">
                      <span className="me-1">
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 1L4.33333 7.66637L1 4.33333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      Signed in</button>}
                    <button className="btn p-0 btn-link h-auto"><svg width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.25 3C11.0784 3 11.75 2.32843 11.75 1.5C11.75 0.671573 11.0784 0 10.25 0C9.42157 0 8.75 0.671573 8.75 1.5C8.75 2.32843 9.42157 3 10.25 3Z" fill="#202020" fillOpacity="0.2" />
                      <path d="M2.25 3C3.07843 3 3.75 2.32843 3.75 1.5C3.75 0.671573 3.07843 0 2.25 0C1.42157 0 0.75 0.671573 0.75 1.5C0.75 2.32843 1.42157 3 2.25 3Z" fill="#202020" fillOpacity="0.2" />
                      <path d="M18.25 3C19.0784 3 19.75 2.32843 19.75 1.5C19.75 0.671573 19.0784 0 18.25 0C17.4216 0 16.75 0.671573 16.75 1.5C16.75 2.32843 17.4216 3 18.25 3Z" fill="#202020" fillOpacity="0.2" />
                    </svg>
                    </button>
                  </div>
                </div>))}



            </div>
          </div>
          {totalOrganizations && totalOrganizations > 0 && <Pagination
            totalRecords={totalOrganizations}
            currentPage={currentPage}
            onPageChange={(page: number) => onPageChange(page)}
            limit={limit}
            siblingCount={1}
          ></Pagination>}
        </div>
      </div>
    </>
  );
};

export default Organisation;
