import { FunctionComponent, useState, useEffect } from "react";

interface NoPlanProps {
  setShowPlans: any;
}

export const NoPlan: FunctionComponent<NoPlanProps> = (props) => {

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">My Plan</h1>
        </div>
        <div className="row">
          <div className="container-fluid">
            <div className="noPlan_col text-center d-flex justify-content-center align-items-center flex-column">
              <img src="../assets/images/cart.svg" />
              <h4>You haven’t subscribed to a plan yet</h4>
              <button type="button" className="btn btn-info py-2 px-4" onClick={()=> props.setShowPlans()}>Buy Plan</button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default NoPlan;
