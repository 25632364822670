import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ListParams } from '../../../../models/common'
import httpClient from "../../../../api/httpClient";
import { toast } from "react-toastify";
import { billingActions } from './billingSlice'
import { ListPlansResponse, NewCardAddPayload, BuySubscriptionPayload } from '../../../../models/billing'
import { organizationActions } from '../../../organizations/organizationSlice';

function* handleListPlans(action: PayloadAction) {

  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/subscription/list-plans`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(billingActions.listPlansSuccess(result.payload));
  }
  return { error, result };
}


function* handleListCards(action: PayloadAction) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/subscription/list-cards`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(billingActions.listCardsSuccess(result.payload));
  }
  return { error, result };
}


function* handleAddNewCard(action: PayloadAction<NewCardAddPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/subscription/add-card`,
  });

  if (error) {
    toast.error(error)
    yield put(billingActions.addNewCardFailed())
  } else {
    yield put(billingActions.addNewCardSuccess(result.payload));
    toast.success(result.message)
  }
  return { error, result };
}

function* handleBuyNewSubscription(action: PayloadAction<BuySubscriptionPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/subscription/buy-subscription`,
  });

  if (error) {
    toast.error(error)
    yield put(billingActions.buySubscriptionFailed(error.message))
  } else {
    yield put(billingActions.buySubscriptionSuccess());
    yield put(organizationActions.setCurrentOrganization(result.payload))
    toast.success(result.message)
  }
  return { error, result };
}

function* handleCancelSubscription(action: PayloadAction<string>) {

  const { result, error } = yield call(httpClient, {
    method: "put",
    url: `/subscription/cancel/${action.payload}`,
  });

  if (error) {
    toast.error(error)
    yield put(billingActions.cancelSubscriptionFailed(error.message))
  } else {
    yield put(billingActions.cancelSubscriptionSuccess());
    yield put(organizationActions.setCurrentOrganization(result.payload))
    toast.success(result.message)
  }
  return { error, result };
}

function* handleUpdateDefaultSource(action: PayloadAction<string>) {

  const { result, error } = yield call(httpClient, {
    data: { card_id: action.payload },
    method: "put",
    url: `/subscription/updateDefaultSource`,
  });

  if (error) {
    toast.error(error)
    yield put(billingActions.updateDefaultSourceFailed(error.message))
  } else {
    yield put(billingActions.updateDefaultSourceSuccess(result.payload));
    toast.success(result.message)
  }
  return { error, result };
}

export function* billingSaga() {
  yield takeLatest(billingActions.listPlans.type, handleListPlans);
  yield takeLatest(billingActions.listCards.type, handleListCards);
  yield takeLatest(billingActions.addNewCard.type, handleAddNewCard);
  yield takeLatest(billingActions.buySubscription.type, handleBuyNewSubscription);
  yield takeLatest(billingActions.cancelSubscription.type, handleCancelSubscription);
  yield takeLatest(billingActions.updateDefaultSource.type, handleUpdateDefaultSource);
}
