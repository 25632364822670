
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Chart } from "react-google-charts";
import { FunctionComponent } from "react";


export const options = {
  title: "Monthly Issues",
  hAxis: { title: "Last 30 days issues", titleTextStyle: { color: "#333" } },
  vAxis: {title: "Number of issues per day", minValue: 0 },
  chartArea: { width: "95%", height: "65%" },
};

interface analyticsProps {
  graphData: any;
}

export const IssueAnalytics: FunctionComponent<analyticsProps> = (props) => {

  return (

    <div className="analytics_col mb-5">
      <div>
        <form className="d-flex justify-content-between align-items-center mb-4">
          {/* <h2 className="sub_heading mb-0">Issues</h2> */}
          {/* <div className="d-flex justify-content-between align-items-center">
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Monthly
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another</a></li>
                <li><a className="dropdown-item" href="#">Something</a></li>
              </ul>
            </div>
            <span className="from_spn">From</span>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                12 May, 2022
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another</a></li>
                <li><a className="dropdown-item" href="#">Something</a></li>
              </ul>
            </div>
            <span className="to_spn">To</span>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                12 Dec, 2022
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another</a></li>
                <li><a className="dropdown-item" href="#">Something</a></li>
              </ul>
            </div>
          </div> */}

        </form>
        <Chart
          chartType="AreaChart"
          width="100%"
          height="400px"
          data={props.graphData}
          options={options}
        />
      </div>
    </div>
  );
};

export default IssueAnalytics;
