import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { dashboardActions } from './dashboardSlice'

function* handleListDashboardData(action: PayloadAction) {
  console.log('project id in api---', action.payload)

  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/projects/${action.payload}/dashboard-insights`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(dashboardActions.getDashboardStatsSuccess(result.payload));
  }
  return { error, result };
}


export function* dashboardSaga() {
  yield takeLatest(dashboardActions.getDashboardStats.type, handleListDashboardData);
}
