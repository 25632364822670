import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch } from '../../../app/hooks';
import { ForgotPasswordPayload } from '../../../models/auth'
import { authActions } from '../authSlice';

const ForgotPassword = () => {


  const dispatch = useAppDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordPayload>();

  const onSubmit: SubmitHandler<ForgotPasswordPayload> = data => {
    dispatch(authActions.forgotPassword(data));
  }
  return (
    <div className="w-100 container-fluid auth-sections">
      <div className="row h-100">
        <div className="col-md-6">
          <div className="sign-form">
            <div className="logo-container">
              <img src="assets/images/logo.svg" />
            </div>
            <div className="sign__form_col">
              <h4 className="auth-heading fw-bold mb-5">Enter email to reset password</h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <label>Email</label>
                  <input type="email" className="form-control" placeholder="email@address.com" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                  {errors.email?.type == 'required' && <span>Please enter email</span>}
                  {errors.email?.type == 'pattern' && <span>Please enter a valid email</span>}
                </div>
                <div className="mt-4 mb-5 d-flex justify-content-between align-items-center sign__form_footer pb-2">
                  <button className="btn btn-primary" type="submit">Send Email</button>
                </div>
              </form>
            </div>
          </div>

        </div>
        <div className="col-md-6 p-0">
        </div>
      </div>

    </div>
  );
};

export default ForgotPassword;
