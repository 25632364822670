import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ListParams } from '../../models/common'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common';
import { projectActions } from './projectSlice'
import { AddProjectPayload, SlackAuthenticationPayload, SlackChannelListResponse, UpdateProjectPayload } from '../../models/project'

function* handleListProject(action: PayloadAction<ListParams>) {

  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/projects${query}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(projectActions.listProjectSuccess(result.payload));
  }
  return { error, result };
}

function* handleAddNewProject(action: PayloadAction<AddProjectPayload>) {

  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/projects`,
  });

  if (error) {
    toast.error(error)
    yield put(projectActions.addNewProjectFailed(error));
  } else {
    yield put(projectActions.addNewProjectSuccess(result.payload));
  }
  return { error, result };
}

export function* handleSlackAuthentication(action: PayloadAction<SlackAuthenticationPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/slack/connect`,
  });
  if (error) {
    toast.error(error);
    yield put(projectActions.slackAuthenticateFailed())
  } else {
    yield put(projectActions.setCurrentProject(result.payload));
    yield put(projectActions.slackAuthenticateSuccess(result.message))
    toast.success(result.message);
  }

  return { error, result };
}

export function* handleListSlackChannel(action: PayloadAction<string>) {
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/slack/list-channels?project_id=${action.payload}`,
  });

  if (error) {
    //toast.error(error);
  } else {
    yield put(projectActions.listSlackChannelSuccess(result.payload));
  }

  return { error, result };
}

export function* handleUpdateProject(action: PayloadAction<UpdateProjectPayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload.data,
    method: "put",
    url: `/projects/${action.payload.project_id}`,
  });

  if (error) {
    toast.error(error);
    yield put(projectActions.updateCurrentProjectFailed(error));
  } else {
    yield put(projectActions.updateCurrentProjectSuccess(result.payload));
    toast.success(result.message);
  }

  return { error, result };
}



export function* projectSaga() {
  yield takeLatest(projectActions.listProject.type, handleListProject);
  yield takeLatest(projectActions.addNewProject.type, handleAddNewProject);
  yield takeLatest(projectActions.slackAuthenticate.type, handleSlackAuthentication);
  yield takeLatest(projectActions.listSlackChannel.type, handleListSlackChannel);
  yield takeLatest(projectActions.updateCurrentProject.type, handleUpdateProject);
}
