
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface logRequestProps {
  consoleLogs: any;
}

export const Logs: FunctionComponent<logRequestProps> = (props) => {

  return (
    <>
      <div className="right" style={{ flex: '50%' }}>
        <div>
          <div className="network_top d-flex justify-content-between align-items-center">
            <ul className="list-unstyled mb-0">
              <li>Logs</li>
            </ul>
          </div>
          <div className="network_filter">
            <input type="text" placeholder="Filter log entries" />
            <button>All</button>
            <button>Logs</button>
            <button>Wornings</button>
            <button>Errors</button>
          </div>
        </div>
        <div>
          <div className="accordion-panel">
            {props.consoleLogs && props.consoleLogs.length > 0 &&
              props.consoleLogs.map((log: any, idx: number) => (<div className="panel-default">
                <div className="panel-heading">
                  <div className="panelContent" key={idx}>
                    <p className="mb-0">{log.text}</p>
                  </div>
                </div>
              </div>))}
            {(!props.consoleLogs || props.consoleLogs.length == 0) && <span>No logs found</span>}
          </div>
        </div>
      </div>

    </>
  );
};

export default Logs;
