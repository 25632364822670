import { Modal } from '../../../components/modals/modal';
import { AddNewProjectModel } from '../../../components/modals/addNewProject';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { selectCurrentOrganization } from '../../../features/organizations/organizationSlice';
import { projectActions, selectProjectList, selectCurrentProject, addNewProjectStatus, getTotalProjectsCount } from '../../../features/projects/projectSlice';
import { AddProjectPayload } from '../../../models/project'
import { Pagination } from '../../../components/pagination';

const Project = () => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [isAddProjectModalShown, setIsAddProjectModalShown] = useState<boolean>(false);
  const projects = useAppSelector(selectProjectList); // Organization List
  const addNewProjStatus: string = useAppSelector(addNewProjectStatus) // Status of if new organisation added succes or not
  const currentOrganization: any = useAppSelector(selectCurrentOrganization); // List of current/active organisation
  const totalProjects: number = useAppSelector(getTotalProjectsCount);
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per
  const currentProj: any = useAppSelector(selectCurrentProject);

  /**List organization on page load */
  useEffect(() => {
    let obj = {
      organisation_id: currentOrganization._id,
      skip: 0,
      limit: 10
    }
    dispatch(projectActions.listProject(obj))
  }, [])

  /**Function to be called on submit of add new organisation form */
  const addNewProject = (data: AddProjectPayload) => {
    dispatch(projectActions.addNewProject(data))
  }

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {
    setCurrentPage(page)
    let data = {
      organisation_id: currentOrganization._id,
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(projectActions.listProject(data));
  }

  /**When new organization added successfully, hide the add new org popup */
  useEffect(() => {
    if (addNewProjStatus == 'success') {
      setIsAddProjectModalShown(false)
    }
  }, [addNewProjStatus])


  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">My projects</h1>
          <div className="d-flex justify-content-between align-items-center">
            <button type="button" className="btn btn-info addMemberBtn" data-bs-toggle="modal" onClick={() => setIsAddProjectModalShown(!isAddProjectModalShown)}>
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 1V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              Add New Project
            </button>
          </div>
        </div>
        <Modal
          isShown={isAddProjectModalShown}
          hide={() => setIsAddProjectModalShown(!isAddProjectModalShown)}
          headerText="Add Project"
          modalContent={
            <AddNewProjectModel
              onSubmit={addNewProject}
              organisation_id={currentOrganization._id}
            />
          }
        />
        <div className="row">
          <div className="container-fluid">
            <div className="companies_dtl_col">
              {projects.length > 0 && projects.map((proj: any, idx: number) => (
                <div className="companies_dtl_list d-flex align-items-center justify-content-between" key={idx}>
                  <div className="media">
                    <span className="logoIconLD logoIconLD_brn">
                      {proj.name[0]}
                    </span>
                    <div className="media-body">
                      <h5 className="mb-1 text-secondary">{proj.name}</h5>
                      {/* <p className="mb-0">Admin |  {org.users ? org.users.length : 1} User(s)</p> */}
                    </div>
                  </div>
                  <div className="companies_dtl_btns">
                    {currentProj && currentProj._id == proj._id && <button className="btn btn-outline-light singInBtn active me-3">
                      <span className="me-1">
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 1L4.33333 7.66637L1 4.33333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      Signed in</button>}
                    <button className="btn p-0 btn-link h-auto"><svg width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.25 3C11.0784 3 11.75 2.32843 11.75 1.5C11.75 0.671573 11.0784 0 10.25 0C9.42157 0 8.75 0.671573 8.75 1.5C8.75 2.32843 9.42157 3 10.25 3Z" fill="#202020" fillOpacity="0.2" />
                      <path d="M2.25 3C3.07843 3 3.75 2.32843 3.75 1.5C3.75 0.671573 3.07843 0 2.25 0C1.42157 0 0.75 0.671573 0.75 1.5C0.75 2.32843 1.42157 3 2.25 3Z" fill="#202020" fillOpacity="0.2" />
                      <path d="M18.25 3C19.0784 3 19.75 2.32843 19.75 1.5C19.75 0.671573 19.0784 0 18.25 0C17.4216 0 16.75 0.671573 16.75 1.5C16.75 2.32843 17.4216 3 18.25 3Z" fill="#202020" fillOpacity="0.2" />
                    </svg>
                    </button>
                  </div>
                </div>))}



            </div>
          </div>
          {totalProjects && totalProjects > 0 && <Pagination
            totalRecords={totalProjects}
            currentPage={currentPage}
            onPageChange={(page: number) => onPageChange(page)}
            limit={limit}
            siblingCount={1}
          ></Pagination>}
        </div>
      </div>
    </>
  );
};

export default Project;
