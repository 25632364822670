import NoPlan from "../components/noPlan";
import ActiveSubscription from "../components/activeSubscription";
import PlanList from "../components/planList";
import Payment from "../components/payment";
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { useEffect, useState } from 'react';
import { selectCurrentOrganization } from '../../../../../features/organizations/organizationSlice';
import { billingActions, selectPlansList, selectCardsList, selectAddNewCardStatus, selectBuySubscriptionStatus, selectCancelSubscriptionStatus } from "../billingSlice";
import { NewCardAddPayload, BuySubscriptionPayload } from "../../../../../models/billing"
import { Loader } from "../../../../../components/loader"

const Billing = () => {

  const dispatch = useAppDispatch();
  const currentOrganization: any = useAppSelector(selectCurrentOrganization);
  const [showPlansPage, setShowPlansPage] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState({});
  // const plans: any = useAppSelector(selectPlansList);
  const savedCards: any = useAppSelector(selectCardsList);
  const newCardAddStatus: string = useAppSelector(selectAddNewCardStatus);
  const buySubscriptionStatus: string = useAppSelector(selectBuySubscriptionStatus);
  const cancelSubscriptionStatus: string = useAppSelector(selectCancelSubscriptionStatus);

  // console.log('Plans are-------', plans)
 //  console.log('current paln', currentOrganization)
  // console.log('saved cards', savedCards)
  // console.log('buySubscriptionStatus status', buySubscriptionStatus)

  useEffect(() => {
    dispatch(billingActions.listPlans())
    dispatch(billingActions.listCards())
    setCurrentPlan({})
    setShowPlansPage(false)

  }, [currentOrganization]);

  const addNewCard = (data: NewCardAddPayload) => {
    dispatch(billingActions.addNewCard(data))
  }

  const buySubscription = (data: BuySubscriptionPayload) => {
    data.organisation_id = currentOrganization._id
    dispatch(billingActions.buySubscription(data));
  }

  const cancelSubscription = (id: string) => {
    dispatch(billingActions.cancelSubscription(id))
  }

  const updateSource = (id: string) => {
    dispatch(billingActions.updateDefaultSource(id))
  }

  useEffect(() => {
    if (newCardAddStatus == 'pending' || buySubscriptionStatus == 'pending') {
      setShowLoader(true)
    } else if (newCardAddStatus == 'success' || buySubscriptionStatus == 'success' || newCardAddStatus == 'failed' || buySubscriptionStatus == 'failed') {
      if (showLoader) setShowLoader(false)
    }

  }, [newCardAddStatus, buySubscriptionStatus])
  useEffect(() => {
    if (cancelSubscriptionStatus == 'success') {
      setCurrentPlan({})
      setShowPlansPage(false)
    }
  }, [cancelSubscriptionStatus])

  return (
    <>
      {showLoader && <Loader></Loader>}

      {Object.keys(currentPlan).length == 0 && currentOrganization && !showPlansPage && (!currentOrganization.active_subscription || currentOrganization.active_subscription.subscription_id == "free" || currentOrganization.active_subscription.subscription_id == "") &&
        <NoPlan
          setShowPlans={() => setShowPlansPage(true)}></NoPlan>}
      {currentOrganization && currentOrganization.active_subscription && (currentOrganization.active_subscription.subscription_id !== 'free' || buySubscriptionStatus == 'success') &&
        <ActiveSubscription
          currentPlan={currentPlan}
          newCardAddStatus={newCardAddStatus}
          addNewCard={addNewCard}
          cancelSubscription={cancelSubscription}
          subscription={currentOrganization.active_subscription}
          updateSource={updateSource}
          ></ActiveSubscription>}
      {showPlansPage &&
        <PlanList
          setCurrentPlan={setCurrentPlan}
          setShowPlans={() => setShowPlansPage(false)}></PlanList>}
      {!showPlansPage && currentPlan && Object.keys(currentPlan).length > 0 &&
        <Payment
          currentPlan={currentPlan}
          setShowPlans={() => setShowPlansPage(true)}
          setCurrentPlan={setCurrentPlan}
          newCardAddStatus={newCardAddStatus}
          addNewCard={addNewCard}
          updateSource={updateSource}
          buySubscription={buySubscription}
        ></Payment>}
    </>
  );
};

export default Billing;
