import React, { FunctionComponent } from 'react';

interface ScriptModalProps {
  scriptCode: string;
}


export const ScriptModal: FunctionComponent<ScriptModalProps> = (props) => {
  return (
    <code>
      <pre>{JSON.parse(props.scriptCode)}</pre>
    </code>
  );
};
