import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";
import { authActions } from '../features/auth/authSlice';
import { store } from "../app/store";

function* HttpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };
  try {
    const { data: result, error } = yield call(axiosInstance, payloadData);

    return {
      error,
      result,
    };
  } catch (error: any) {

    let myError = error?.response?.status ? error.response : error;
  
    if (myError?.status) {
     
      if (myError.status === 401) {
        store.dispatch(authActions.logout())
        yield put(push({ pathname: "/" }));
      } else {
        toast.error(myError.data?.message);
      }
    } else {
      toast.error(myError.data?.message);
    }
  }

  return {
    error: true,
    result: null,
  };
}

export default HttpClient;
