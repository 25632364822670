import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListFeedbackFormResponse, NewFeedbackFormPayload, NewFeedbackFormResponse } from '../../models/feedbackForm';
import { ListParams, ListResponse } from '../../models/common';

export interface FeedbackFormState {
  listFeedbackForm: string;
  addNewForm: string;
  forms?: ListFeedbackFormResponse[];
  totalFeedbackForms: number;
  updateFeedbackForm: string
}

const initialState: FeedbackFormState = {
  listFeedbackForm: '',
  addNewForm: '',
  forms: [],
  totalFeedbackForms: 0,
  updateFeedbackForm: ''
};

const feedbackFormSlice = createSlice({
  name: 'feedbackform',
  initialState,
  reducers: {
    listFeedbackForm(state, action: PayloadAction<ListParams>) {
      state.listFeedbackForm = 'pending';
    },
    listFeedbackFormSuccess(
      state,
      action: PayloadAction<ListResponse<ListFeedbackFormResponse>>
    ) {
      state.listFeedbackForm = 'success';
      state.forms = action.payload.results;
      state.totalFeedbackForms = action.payload.count
    },
    listFeedbackFormsFailed(state, action: PayloadAction<string>) {
      state.listFeedbackForm = 'failed';
    },
    addFeedbackForm(state, action: PayloadAction<NewFeedbackFormPayload>) {
      state.addNewForm = 'pending'
    },
    addFeedbackFormSuccess(state, action: PayloadAction<NewFeedbackFormResponse>) {
      state.addNewForm = 'success'
      state.forms?.unshift(action.payload);
      state.totalFeedbackForms = state.totalFeedbackForms + 1
    },
    updateFeedbackForm(state, action: PayloadAction<NewFeedbackFormPayload>) {
      state.updateFeedbackForm = 'pending'
    },
    updateFeedbackFormSuccess(state:any, action: PayloadAction<NewFeedbackFormResponse>) {
      state.updateFeedbackForm = 'success'
      const index: number = state.forms.findIndex((form:any) => form._id == action.payload._id);
      state.forms[index] = action.payload 
    }

  },
});

// Actions
export const feedbackFormActions = feedbackFormSlice.actions;

// Selectors
export const selectFeedbackFormLoading = (state: RootState) => state.feedbackForm.listFeedbackForm;
export const selectFeedbackFormList = (state: RootState) => state.feedbackForm.forms;
export const selectAddNewFormSuccess = (state: RootState) => state.feedbackForm.addNewForm;
export const feedbackFormsCount = (state: RootState) => state.feedbackForm.totalFeedbackForms;
export const updateFeedbackFormSuccess = (state: RootState) => state.feedbackForm.updateFeedbackForm;

// Reducer
const feedbackFormReducer = feedbackFormSlice.reducer;
export default feedbackFormReducer;
