import { FunctionComponent } from "react";
import { useAppSelector } from '../../../../../app/hooks';
import { selectPlansList } from "../billingSlice";

interface PlanListProps {
  setCurrentPlan: any;
  setShowPlans: any
}

export const PlanList: FunctionComponent<PlanListProps> = (props) => {

  const plans: any = useAppSelector(selectPlansList);

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Choose plan</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="dropdown paymentBtn">
              {/* <button className="btn btn-light dropdown-toggle rounded-pill" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Pay yearly (save 5%)
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul> */}
            </div>
            <div className="dropdown paymentBtn ms-3">
              <button className="btn btn-light dropdown-toggle rounded-pill" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                EUR
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">EUR</a></li>
                <li><a className="dropdown-item" href="#">USD</a></li>
                <li><a className="dropdown-item" href="#">INR</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row gx-2 priceTableRow">

            {plans && plans.length > 0 && plans.map((plan: any, idx: number) => (
              <div className="col-lg-4 col-md-12 mb-4" key={idx}>
                <div className="card pricingCard ">
                  {/* <div className="card-header">
                  <span>Most POPULAR</span>
                </div> */}
                  <div className="card-body">
                    <h5 className="card-title">{plan?.product?.name}</h5>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <div className="priceMonth">
                      <span className="h2">€ {(plan.amount) / 100} </span><span className="h6">/ Month</span>
                    </div>

                    <small>Benefits</small>
                    <ul className="list-group list-group-flush list-unstyled">
                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> All benefits of base plan plus</li>

                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Add upto 140 Users </li>

                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Cras id neque vel nibh tincidunt viverra</li>

                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Donec tristique erat ac tincidunt feugiat</li>

                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Aliquam nec mi non felis molestie molestie</li>

                      <li><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1.00098L5 9.00062L1 5.00098" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Aliquam lacinia risus eu eleifend tempor</li>
                    </ul>
                  </div>
                  <div className="card-footer border-0 bg-transparent">
                    <button className="btn btn-success w-100" onClick={() => { props.setCurrentPlan(plan); props.setShowPlans() }}>Choose Plan</button>
                  </div>
                </div>
              </div>))}

          </div>
        </div>
      </div>
    </>
  );
};

export default PlanList;
