
import { FunctionComponent } from "react";
import { DateTime } from "luxon";
import ReactStars from 'react-stars';
import { useNavigate } from "react-router-dom";
import { NoDataFound } from "../../../components/noDataFound"
interface feedbackProps {
  feedbacks: any;
  totalCount: number
}

export const Feedbacks: FunctionComponent<feedbackProps> = (props) => {
  console.log('in sub componnet', props.feedbacks)
  const navigate = useNavigate();

  return (
    <>
      <div className="col-lg-3">
        <div className="feedback_sidebar">
          <h5 className="mb-3">Feedbacks</h5>
          {props.feedbacks?.length > 0 && props.feedbacks.map((feedback: any, idx: number) => (
            <div className="feedback_col" key={idx}>
              <div className="d-flex justify-content-between mb-3">
                {/* <svg width="99" height="16" viewBox="0 0 99 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6316 0H0V15.6316H15.6316V0Z" fill="white" />
                <path d="M8.08549 11.6467L11.1651 13.5978C11.5588 13.8472 12.0475 13.4763 11.9307 13.0167L11.0409 9.51659C11.0159 9.4191 11.0189 9.31652 11.0495 9.22064C11.0802 9.12477 11.1372 9.03949 11.2142 8.97462L13.9758 6.67608C14.3387 6.37406 14.1514 5.77184 13.6852 5.74158L10.0787 5.50752C9.98157 5.50058 9.88839 5.46619 9.81004 5.40835C9.73169 5.35051 9.67138 5.2716 9.63614 5.18081L8.29107 1.79358C8.25451 1.69722 8.1895 1.61426 8.10467 1.55571C8.01985 1.49717 7.91922 1.46582 7.81615 1.46582C7.71308 1.46582 7.61246 1.49717 7.52763 1.55571C7.4428 1.61426 7.37779 1.69722 7.34123 1.79358L5.99616 5.18081C5.96092 5.2716 5.90061 5.35051 5.82226 5.40835C5.74391 5.46619 5.65074 5.50058 5.5536 5.50752L1.9471 5.74158C1.4809 5.77184 1.29361 6.37406 1.65648 6.67608L4.41809 8.97462C4.49505 9.03949 4.55214 9.12477 4.58279 9.22064C4.61345 9.31652 4.61641 9.4191 4.59136 9.51659L3.76619 12.7626C3.62602 13.314 4.21252 13.7591 4.6849 13.4598L7.54681 11.6467C7.6273 11.5954 7.72074 11.5682 7.81615 11.5682C7.91157 11.5682 8.005 11.5954 8.08549 11.6467Z" fill="#FF9409" stroke="#FF9409" strokeWidth="1.11654" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M36.4744 0H20.8428V15.6316H36.4744V0Z" fill="white" />
                <path d="M28.9283 11.6467L32.0079 13.5978C32.4016 13.8472 32.8903 13.4763 32.7735 13.0167L31.8837 9.51659C31.8587 9.4191 31.8616 9.31652 31.8923 9.22064C31.9229 9.12477 31.98 9.03949 32.057 8.97462L34.8186 6.67608C35.1815 6.37406 34.9942 5.77184 34.528 5.74158L30.9215 5.50752C30.8243 5.50058 30.7312 5.46619 30.6528 5.40835C30.5745 5.35051 30.5142 5.2716 30.4789 5.18081L29.1338 1.79358C29.0973 1.69722 29.0323 1.61426 28.9474 1.55571C28.8626 1.49717 28.762 1.46582 28.6589 1.46582C28.5559 1.46582 28.4552 1.49717 28.3704 1.55571C28.2856 1.61426 28.2206 1.69722 28.184 1.79358L26.8389 5.18081C26.8037 5.2716 26.7434 5.35051 26.665 5.40835C26.5867 5.46619 26.4935 5.50058 26.3964 5.50752L22.7899 5.74158C22.3237 5.77184 22.1364 6.37406 22.4992 6.67608L25.2609 8.97462C25.3378 9.03949 25.3949 9.12477 25.4256 9.22064C25.4562 9.31652 25.4592 9.4191 25.4341 9.51659L24.609 12.7626C24.4688 13.314 25.0553 13.7591 25.5277 13.4598L28.3896 11.6467C28.4701 11.5954 28.5635 11.5682 28.6589 11.5682C28.7543 11.5682 28.8478 11.5954 28.9283 11.6467Z" fill="#FF9409" stroke="#FF9409" strokeWidth="1.11654" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M57.3152 0H41.6836V15.6316H57.3152V0Z" fill="white" />
                <path d="M49.7691 11.6467L52.8487 13.5978C53.2424 13.8472 53.7311 13.4763 53.6143 13.0167L52.7245 9.51659C52.6995 9.4191 52.7024 9.31652 52.7331 9.22064C52.7638 9.12477 52.8208 9.03949 52.8978 8.97462L55.6594 6.67608C56.0223 6.37406 55.835 5.77184 55.3688 5.74158L51.7623 5.50752C51.6652 5.50058 51.572 5.46619 51.4936 5.40835C51.4153 5.35051 51.355 5.2716 51.3197 5.18081L49.9747 1.79358C49.9381 1.69722 49.8731 1.61426 49.7883 1.55571C49.7034 1.49717 49.6028 1.46582 49.4997 1.46582C49.3967 1.46582 49.2961 1.49717 49.2112 1.55571C49.1264 1.61426 49.0614 1.69722 49.0248 1.79358L47.6798 5.18081C47.6445 5.2716 47.5842 5.35051 47.5059 5.40835C47.4275 5.46619 47.3343 5.50058 47.2372 5.50752L43.6307 5.74158C43.1645 5.77184 42.9772 6.37406 43.3401 6.67608L46.1017 8.97462C46.1786 9.03949 46.2357 9.12477 46.2664 9.22064C46.297 9.31652 46.3 9.4191 46.275 9.51659L45.4498 12.7626C45.3096 13.314 45.8961 13.7591 46.3685 13.4598L49.2304 11.6467C49.3109 11.5954 49.4043 11.5682 49.4997 11.5682C49.5952 11.5682 49.6886 11.5954 49.7691 11.6467Z" fill="#FF9409" stroke="#FF9409" strokeWidth="1.11654" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M78.1579 0H62.5264V15.6316H78.1579V0Z" fill="white" />
                <path d="M70.6119 11.6467L73.6915 13.5978C74.0851 13.8472 74.5739 13.4763 74.4571 13.0167L73.5673 9.51659C73.5423 9.4191 73.5452 9.31652 73.5759 9.22064C73.6065 9.12477 73.6636 9.03949 73.7406 8.97462L76.5022 6.67608C76.8651 6.37406 76.6778 5.77184 76.2116 5.74158L72.6051 5.50752C72.5079 5.50058 72.4148 5.46619 72.3364 5.40835C72.2581 5.35051 72.1978 5.2716 72.1625 5.18081L70.8174 1.79358C70.7809 1.69722 70.7159 1.61426 70.631 1.55571C70.5462 1.49717 70.4456 1.46582 70.3425 1.46582C70.2395 1.46582 70.1388 1.49717 70.054 1.55571C69.9692 1.61426 69.9042 1.69722 69.8676 1.79358L68.5225 5.18081C68.4873 5.2716 68.427 5.35051 68.3486 5.40835C68.2703 5.46619 68.1771 5.50058 68.08 5.50752L64.4735 5.74158C64.0073 5.77184 63.82 6.37406 64.1828 6.67608L66.9445 8.97462C67.0214 9.03949 67.0785 9.12477 67.1092 9.22064C67.1398 9.31652 67.1428 9.4191 67.1177 9.51659L66.2926 12.7626C66.1524 13.314 66.7389 13.7591 67.2113 13.4598L70.0732 11.6467C70.1537 11.5954 70.2471 11.5682 70.3425 11.5682C70.4379 11.5682 70.5314 11.5954 70.6119 11.6467Z" fill="#FF9409" stroke="#FF9409" strokeWidth="1.11654" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M91.4546 11.6467L94.5343 13.5978C94.9279 13.8472 95.4167 13.4763 95.2999 13.0167L94.4101 9.51659C94.385 9.4191 94.388 9.31652 94.4186 9.22064C94.4493 9.12477 94.5064 9.03949 94.5833 8.97462L97.345 6.67608C97.7078 6.37406 97.5205 5.77184 97.0543 5.74158L93.4478 5.50752C93.3507 5.50058 93.2575 5.46619 93.1792 5.40835C93.1008 5.35051 93.0405 5.2716 93.0053 5.18081L91.6602 1.79358C91.6237 1.69722 91.5586 1.61426 91.4738 1.55571C91.389 1.49717 91.2884 1.46582 91.1853 1.46582C91.0822 1.46582 90.9816 1.49717 90.8968 1.55571C90.8119 1.61426 90.7469 1.69722 90.7104 1.79358L89.3653 5.18081C89.3301 5.2716 89.2698 5.35051 89.1914 5.40835C89.113 5.46619 89.0199 5.50058 88.9227 5.50752L85.3162 5.74158C84.85 5.77184 84.6628 6.37406 85.0256 6.67608L87.7872 8.97462C87.8642 9.03949 87.9213 9.12477 87.9519 9.22064C87.9826 9.31652 87.9856 9.4191 87.9605 9.51659L87.1353 12.7626C86.9952 13.314 87.5817 13.7591 88.054 13.4598L90.9159 11.6467C90.9964 11.5954 91.0899 11.5682 91.1853 11.5682C91.2807 11.5682 91.3741 11.5954 91.4546 11.6467V11.6467Z" stroke="black" strokeOpacity="0.3" strokeWidth="1.11654" strokeLinecap="round" strokeLinejoin="round" />
              </svg> */}
                <ReactStars
                  count={5}
                  value={feedback.rating}
                  size={24}
                  edit={false}
                  color2={'#ff9409'} />
                <span className="feedback_date">{DateTime.fromMillis(feedback.created_at).toFormat('LLL dd, hh:mm a')}</span>

              </div>
              <p>{feedback.feedback}</p>
            </div>))}
          {props.feedbacks?.length == 0 &&
            <NoDataFound></NoDataFound>
          }
          {props.totalCount > 3 && <button className="btn btn-link" onClick={() => navigate("/feedbacks")}>View all</button>}
        </div>
      </div>

    </>
  );
};

export default Feedbacks;
