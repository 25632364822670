import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { ListParams } from '../../models/common'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common';
import { organizationActions } from './organizationSlice';
import {AddOrganisationPayload, ResendInvitePayload, AddTeamMemberPayload, AddPrivacyPayload} from '../../models/organization'

function* handleListOrganizations(action: PayloadAction<ListParams>) {

  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/organisations${query}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(organizationActions.listOrganizationSuccess(result.payload));
  }
  return { error, result };
}

function* handleAddNewOrganization(action: PayloadAction<AddOrganisationPayload>) {

  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/organisations`,
  });

  if (error) {
    toast.error(error)
    yield put(organizationActions.addNewOrganizationFailed(error));
  } else {
    yield put(organizationActions.addNewOrganizationSuccess(result.payload));
  }
  return { error, result };
}

function* handleListOrganizationDetail(action: PayloadAction) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/organisations/${action.payload}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(organizationActions.listOrganizationMembersSuccess(result.payload));
  }
  return { error, result };
}

function* handleAddNewTeamMember(action: PayloadAction<AddTeamMemberPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload.data,
    method: "put",
    url: `/organisations/${action.payload.organisation_id}/invite-user`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(organizationActions.addNewTeamMemberSuccess(result.payload));
    toast.success(result.message);
  }
  return { error, result };
}

function* handleResendInvitation(action: PayloadAction<ResendInvitePayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload.data,
    method: "put",
    url: `/organisations/${action.payload.organisation_id}/resend-invite`,
  });

  if (error) {
    toast.error(error)
  } else {
    toast.success(result.message);
  }
  return { error, result };
}

function* handleAddPrivacy(action: PayloadAction<AddPrivacyPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload.data,
    method: "put",
    url: `/organisations/${action.payload.organisation_id}/add-keywords-to-exclude`,
  });

  if (error) {
    toast.error(error)
  } else {
    toast.success(result.message);
  }
  return { error, result };
}

export function* organizationSaga() {
  yield takeLatest(organizationActions.listOrganization.type, handleListOrganizations);
  yield takeLatest(organizationActions.addNewOrganization.type, handleAddNewOrganization);
  yield takeLatest(organizationActions.listOrganizationMembers.type, handleListOrganizationDetail);
  yield takeLatest(organizationActions.addNewTeamMember.type, handleAddNewTeamMember);
  yield takeLatest(organizationActions.resendInvite, handleResendInvitation);
  yield takeLatest(organizationActions.addPrivacy, handleAddPrivacy);
}
