import { Modal } from '../../../components/modals/modal';
import { useState, useEffect } from 'react'
import { AddNewFormModal } from '../../../components/modals/addNewForm';
import { ScriptModal } from '../../../components/modals/scriptModal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectFeedbackFormList, feedbackFormActions, selectAddNewFormSuccess, feedbackFormsCount, updateFeedbackFormSuccess, selectFeedbackFormLoading } from '../feedbackFormSlice';
import { NewFeedbackFormPayload } from '../../../models/feedbackForm';
import { Pagination } from '../../../components/pagination';
import { selectCurrentProject } from '../../projects/projectSlice';
import { NoDataFound } from '../../../components/noDataFound';
import { CustomLoader } from '../../../components/customLoader'

const FeedbackForms = () => {
  const [isAddFormShown, setIsAddFormShown] = useState<boolean>(false);
  const toggleAddNewForm = () => {
    setIsAddFormShown(!isAddFormShown)
  }
  const [isCodeSnippetShown, setIsCodeSnippetShown] = useState<boolean>(false);
  const [isUpdateFormShown, setIsUpdateFormShown] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState({})

  const dispatch = useAppDispatch();
  const feedbackFormList = useAppSelector(selectFeedbackFormList);
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const totalForms: number = useAppSelector(feedbackFormsCount); // List of current/active project
  const updateFormStatus: string = useAppSelector(updateFeedbackFormSuccess);
  const fetchFeedbackFormStatus: string = useAppSelector(selectFeedbackFormLoading);

  useEffect(() => {
    let data = {
      skip: 0,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }

    dispatch(feedbackFormActions.listFeedbackForm({ data, project_id: currentProject._id }));
  }, [currentProject]);
  const newFormAdded = useAppSelector(selectAddNewFormSuccess);

  /**Function to add new feedback form  */
  const addNewForm = (data: NewFeedbackFormPayload) => {
    dispatch(feedbackFormActions.addFeedbackForm(data))
  }

  /**Function to update existing feedback form  */
  const updateForm = (data: NewFeedbackFormPayload) => {
    dispatch(feedbackFormActions.updateFeedbackForm(data))
  }

  /** UseEffect to be called when new form added successfully and we need to close the popup */
  useEffect(() => {
    if (newFormAdded == 'success') {
      setIsAddFormShown(false)
    }
  }, [newFormAdded])

  useEffect(() => {
    if (updateFormStatus == 'success') {
      setIsUpdateFormShown(false)
    }
  }, [updateFormStatus])

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {

    setCurrentPage(page)
    let data = {
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(feedbackFormActions.listFeedbackForm({ data, project_id: currentProject._id }));
  }

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">All Forms</h1>
          <div className="d-flex justify-content-between align-items-center">
            <button type="button" className="btn btn-primary addMemberBtn" onClick={() => toggleAddNewForm()}>
              <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7H13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7 1V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              Add Form
            </button>
            <Modal
              isShown={isAddFormShown}
              hide={() => toggleAddNewForm()}
              headerText="Confirmation"
              modalContent={
                <AddNewFormModal
                  message="Are you sure you want to delete element?"
                  onSubmit={addNewForm}
                />
              }
            />

          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="table-responsive">
              {feedbackFormList && feedbackFormList.length > 0 && feedbackFormList.map((form, idx) => (
                fetchFeedbackFormStatus == 'success' && <div className="session__detail team_member__details forms_details table" key={idx}>
                  <div className="session_dtl_col tr">
                    <div className="session_dtl1 td">
                      <div className="media">
                        <span className="media_letter">
                          ID
                        </span>
                        <div className="media-body">
                          <h5 className="mb-0">{form.button_id}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl2 td">
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Globe.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            {form.domain}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl3 td">
                      <div className="media">
                        <div className="media-body">
                          <button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal" onClick={() => setIsCodeSnippetShown(!isCodeSnippetShown)} >Code snippet</button>
                          <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" onClick={() => {
                            setSelectedButton(form); setIsUpdateFormShown(!isUpdateFormShown)
                          }}>Update</button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    isShown={isCodeSnippetShown}
                    hide={() => setIsCodeSnippetShown(!isCodeSnippetShown)}
                    headerText="Code Snippet"
                    modalContent={
                      <ScriptModal
                        scriptCode={form.script}
                      />
                    }
                  />
                </div>))}

              {fetchFeedbackFormStatus == 'pending' && <CustomLoader></CustomLoader>}
              {feedbackFormList && feedbackFormList.length == 0 && fetchFeedbackFormStatus == 'success' &&
                <NoDataFound></NoDataFound>
              }

              <Modal
                isShown={isUpdateFormShown}
                hide={() => setIsUpdateFormShown(!isUpdateFormShown)}
                headerText="Update Form"
                modalContent={
                  <AddNewFormModal
                    message="Are you sure you want to delete element?"
                    onSubmit={updateForm}
                    form={selectedButton}
                    updateForm={updateForm}
                  />
                }
              />

            </div>

            {totalForms && totalForms > 0 && <Pagination
              totalRecords={totalForms}
              currentPage={currentPage}
              onPageChange={(page: number) => onPageChange(page)}
              limit={limit}
              siblingCount={1}
            ></Pagination>}
          </div>
        </div>


      </div>
    </>
  );
};

export default FeedbackForms;
