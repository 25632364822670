
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

interface networkDetailRequestProps {
  currentNetworkRequest: any
}

export const NetworkRequestDetail: FunctionComponent<networkDetailRequestProps> = (props) => {
  console.log('in sub componnet', props.currentNetworkRequest)
  const navigate = useNavigate();


  const getRequestDuration = (network: any) => {
    let i1 = DateTime.fromMillis(network.requestStartTime),
      i2 = DateTime.fromMillis(network.requestEndTime);
    let timeDiff = i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }


    return timeDiff.milliseconds
  }

  return (
    <>
      <div className="network-request">
        <div className="network_top d-flex justify-content-between align-items-center networkRequestTabs">
          <ul className="list-unstyled mb-0">
            <li>Network Request</li>
          </ul>
          <div className="network_filter">
            <button className="bg-transparent border-0">✕</button>
          </div>
        </div>
        <div className="accordion" id="accordionExample" style={{ overflow: 'hidden auto', height: '428px' }}>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span>GENERAL</span>
                {/* <span>COPY URL</span> */}
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body p-0">
                <table className="network-general-list">
                  <tbody>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>URL</span></td>
                      <td>{props.currentNetworkRequest?.url}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>method</span></td>
                      <td>{props.currentNetworkRequest?.method}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>status</span></td>
                      <td>{props.currentNetworkRequest?.status}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>duration</span></td>
                      <td>{(props.currentNetworkRequest && props.currentNetworkRequest.requestEndTime && props.currentNetworkRequest.requestStartTime) ? getRequestDuration(props.currentNetworkRequest) : 0} ms</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>Initiator Type</span></td>
                      <td>Navigation</td>
                    </tr>
                    {/* <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>MMe Type</span></td>
                      <td>unknown</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span>REQUEST HEADERS</span>
                {/* <span>COPY URL</span> */}

              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <table className="network-general-list">
                  <tbody>
                    <tr className="border-bottom">
                      <td style={{ width: "126px" }}>
                        <span>Content-Type</span>
                      </td>
                      <td>{props.currentNetworkRequest?.headers}</td>
                    </tr>
                    {/* <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>URL</span></td>
                      <td>{props.currentNetworkRequest?.url}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>method</span></td>
                      <td>{props.currentNetworkRequest?.method}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>status</span></td>
                      <td>{props.currentNetworkRequest?.status}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>duration</span></td>
                      <td>{(props.currentNetworkRequest && props.currentNetworkRequest.requestEndTime && props.currentNetworkRequest.requestStartTime) ? getRequestDuration(props.currentNetworkRequest): 0} ms</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>Initiator Type</span></td>
                      <td>Navigation</td>
                    </tr> */}
                    {/* <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>MMe Type</span></td>
                      <td>unknown</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span>REQUEST BODY</span>
                {/* <span>COPY URL</span> */}
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <table className="network-general-list">
                  <tbody>
                    {props.currentNetworkRequest && props.currentNetworkRequest.payloadData ? (
                      <tr className="border-bottom">
                        <td style={{ width: "126px" }}>
                          <span>Payload</span>
                        </td>
                        <td>{props.currentNetworkRequest.payloadData}</td>
                      </tr>
                    ) : (
                      <td style={{ width: "126px" }}>
                        <span>No data</span>
                      </td>
                    )}
                    {/* <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>URL</span></td>
                      <td>{props.currentNetworkRequest?.url}</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>method</span></td>
                      <td>GET</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>status</span></td>
                      <td>401</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>duration</span></td>
                      <td>286 ms</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>Initiator Type</span></td>
                      <td>Navigation</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>MMe Type</span></td>
                      <td>unknown</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span>RESPONSE HEADER</span>
                {/* <span>COPY URL</span> */}
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <table className="network-general-list">
                  <tbody>
                    <tr className="border-bottom">
                      <td style={{ width: "136px" }}>
                        <span>Content-Type</span>
                      </td>
                      <td>{props.currentNetworkRequest?.headers}</td>
                    </tr>
                    {/* <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>URL</span></td>
                      <td>https://dev.api.logduck.com/api/v1/organisations?sort_key=created_at&amp;sort_order=1&amp;skip=0&amp;limit=100000</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>method</span></td>
                      <td>GET</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>status</span></td>
                      <td>401</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>duration</span></td>
                      <td>286 ms</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>Initiator Type</span></td>
                      <td>Navigation</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '126px' }}><span>MMe Type</span></td>
                      <td>unknown</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span>RESPONSE BODY</span>
                {/* <span>COPY URL</span> */}
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <table className="network-general-list">
                  <tbody>
                    {props.currentNetworkRequest?.responseText ? (
                      <tr className="border-bottom">
                        <td style={{ width: "136px" }}>
                          <span>Payload</span>
                        </td>
                        <td style={{ height: "100px", overflow: "auto hidden" }}>{props.currentNetworkRequest.responseText}</td>
                      </tr>
                    ) : (
                      <td style={{ width: "136px" }}>
                        <span>No data</span>
                      </td>
                    )}
                    {/* <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>URL</span></td>
                      <td>https://dev.api.logduck.com/api/v1/organisations?sort_key=created_at&amp;sort_order=1&amp;skip=0&amp;limit=100000</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>method</span></td>
                      <td>GET</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>status</span></td>
                      <td>401</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>duration</span></td>
                      <td>286 ms</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>Initiator Type</span></td>
                      <td>Navigation</td>
                    </tr>
                    <tr className="border-bottom">
                      <td style={{ width: '136px' }}><span>MMe Type</span></td>
                      <td>unknown</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default NetworkRequestDetail;
