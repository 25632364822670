
import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentProject } from '../../projects/projectSlice';
import { DateTime } from "luxon";
import { issueActions, selectIssueDetail } from '../issueSlice'
import { useParams, useNavigate } from 'react-router-dom';
import { isUndefined } from 'util';


const IssueDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const issue: any = useAppSelector(selectIssueDetail);

  let { id }: any = useParams();
  console.log('id is', id)

  useEffect(() => {
    if (id) {
      dispatch(issueActions.listIssueDetail(id))
    }

  }, [currentProject, id])

  console.log('issue is---', issue)

  return (
    <>
      <div className="page-content-wrapper pageWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Issue</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="notificationMenu">
              <span>
                <img src="../assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div>
          </div>
        </div>
        {issue && Object.keys(issue).length > 0 && <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="md-cont-wrapper bg-white w-100">
              <span className="mb-3" onClick={() => navigate("/issues")}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.8125 7H2.1875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.125 3.0625L2.1875 7L6.125 10.9375" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                Go back</span>
              <div className="typeError d-flex justify-content-between align-items-center">
                <div className="typeErrorCont">
                  <h5 className="fw-normal mb-1">{issue?.type == 'networkerror' ? 'Network Error' : (issue?.type == 'exception' ? 'Exception' : 'JS Error')}</h5>
                  {/* <p className="fw-normal mb-0"><a href="">https://dev.app.logduck.com/static/js/2.f2ae0891.chunk.js</a></p> */}
                </div>
                <ul className="list-unstyled typeErrorList d-flex mb-0">
                  {issue.user_count && <li className="d-flex align-items-center userTimeLine">
                    <span className="timeline-circle">
                      {issue?.user_count}
                    </span>
                    <div className="repo_cont ms-2">
                      <h6 className="mb-0">User</h6>
                    </div>
                  </li>}
                  {issue.session_count && <li className="d-flex align-items-center userTimeLine">
                    <span className="timeline-circle">
                      {issue?.session_count}
                    </span>
                    <div className="repo_cont ms-2">
                      <h6 className="mb-0">Session</h6>
                    </div>
                  </li>}
                </ul>
              </div>
              <div className="row issuesUserDetails">
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      <img src="../assets/images/icons/user.svg" alt="user" />
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal"> {issue?.user
                        ? issue.user?.firstname +
                        " " +
                        issue.user?.lastname
                        : "Anonymous User"}</h6>
                      <p className="mb-0">{issue?.user ? issue.user?.email : ""}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      <img src={`../assets/images/icons/${issue?.browser}.svg`} alt="user" />
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal">{issue?.browser}</h6>
                      <p className="mb-0">{issue?.geolocation
                        ? issue.geolocation.ip
                        : ""}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      <img src="../assets/images/icons/hour.svg" alt="user" />
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal">History</h6>
                      <p className="mb-0">FIRST SEEN:  {issue?.first_seen
                        ? DateTime.fromMillis(issue?.first_seen).toRelative({ unit: "hours" })
                        : ""}  |  LAST SEEN: {issue?.last_seen
                          ? DateTime.fromMillis(issue?.last_seen).toRelative({ unit: "hours" })
                          : ""}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row issuesUserDetails">
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      {issue.os && issue.os.family && (issue.os.family== 'OS X' || issue.os.family== 'iOS' ) && <img src="../assets/images/icons/iOS.svg" alt="user" />}
                      {issue.os && issue.os.family && issue.os.family== 'Linux' && <img src="../assets/images/icons/linux.svg" alt="user" />}
                      {issue.os && issue.os.family && (issue.os.family== 'Windows' || issue.os.family== 'Windows XP') && <img src="../assets/images/icons/window.svg" alt="user" />}
                      {issue.os && issue.os.family && issue.os.family== 'Debian' && <img src="../assets/images/icons/debian.svg" alt="user" />}
                      {issue.os && issue.os.family && issue.os.family== 'Android' && <img src="../assets/images/icons/Android.svg" alt="user" />}
                      {issue.os && issue.os.family && issue.os.family== 'Windows Phone' && <img src="../assets/images/icons/window-phone.svg" alt="user" />}
                      {(!issue || !issue.os || !issue.os.family) && <img src="../assets/images/icons/window.svg" alt="user" />}
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal">{issue?.os ? issue.os.family : ""}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      <img src="../assets/images/icons/location.svg" alt="user" />
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal">{issue?.geolocation
                        ? issue.geolocation.country_name
                        : "Unknown location"}</h6>
                      <p className="mb-0">{issue?.geolocation
                        ? issue.geolocation.ip
                        : ""}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="media">
                    <span className="media_img">
                      <img src="../assets/images/icons/ChatTeardropDots.svg" alt="user" />
                    </span>
                    <div className="media-body">
                      <h6 className="mb-1 fw-normal">{DateTime.fromMillis(issue?.last_seen).toFormat('LLL dd, y hh:mm a')}</h6>
                      <p className="mb-0"><a href="">{issue?.exception
                        ? issue?.exception.url
                        : ""}</a></p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row issuesUserDetails pt-4">
                <div className="col-12">
                  <h5 className="fw-normal mb-1">Message</h5>
                  <p className="mb-0">{(issue.exception && issue?.exception)
                    ? issue.exception.message
                    : ""}</p>
                </div>
              </div> */}
              {issue?.type != "networkerror" && <div className="row exceptionAccErr pt-4">
                <div className="col-12">
                  <h5 className="fw-normal mb-1">Message</h5>
                  <p>{(issue.exception && issue?.exception)
                    ? issue.exception.message
                    : ""}</p>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          {(issue.exception && issue?.exception)
                            ? issue.exception.source
                            : ""}
                          {(issue.exception && issue.exception.lineno) && <><span className="mx-1"> is on line </span>
                            <span>{issue.exception.lineno}:{issue.exception.colno}</span> </>}
                        </button>
                      </h2>
                      {issue.exception && issue.exception.error && <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <ul className="list-unstyled mb-0 AccChunkList">
                            <li>
                              {issue.exception.error.split('\n').map((item: any, key: number) => {
                                return <span key={key}>{item}<br /></span>
                              })}
                              {/* <div style={{whiteSpace: "pre-wrap"}}>{issue?.exception?.error}
                            </div> */}
                            </li>
                          </ul>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>}

              {issue?.exception && <hr />}

              {issue?.request && (
                <div className="mt-5">
                  <h6>General</h6>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>url:</strong>
                    {issue?.request ? issue.request.url : ""}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>status:</strong>
                    {issue?.request ? issue.request.status : ""}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>method:</strong>
                    {issue?.request ? issue.request.method : ""}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>duration:</strong>
                    {issue?.request
                      ? `${issue.request.timeout} "ms"`
                      : ""}
                  </p>
                </div>
              )}
              {issue?.request && <hr />}

              {issue?.request && (
                <div className="mt-2">
                  <h6>Request Header</h6>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>Accept:</strong>
                    {issue?.request.headers
                      ? issue?.request.headers
                        .split("content-type")[1]
                        .split(";")[0]
                      : ""}
                  </p>
                </div>
              )}
              {issue?.request && <hr />}

              {issue?.request && (
                <div className="mt-2">
                  <h6>Request Body</h6>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>Body:</strong>
                    {issue?.request.payloadData
                      ? issue?.request.payloadData
                      : ""}
                  </p>
                </div>
              )}
              {issue?.request && <hr />}

              {issue?.request && (
                <div className="mt-2">
                  <h6>Response Headers</h6>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>Headers:</strong>
                    {issue?.request.headers
                      ? issue?.request.headers
                      : ""}
                  </p>
                </div>
              )}
              {issue?.request && <hr />}

              {issue?.request && (
                <div className="mt-2">
                  <h6>Response Body</h6>
                  <p className="mb-0">
                    <strong style={{ fontWeight: "bold" }}>Response:</strong>
                    {issue?.request.response
                      ? issue?.request.response
                      : ""}
                  </p>
                </div>
              )}
              {issue?.request && <hr />}


            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default IssueDetail;
