import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListOrganizationResponse, AddOrganisationPayload, ListOrganizationMembers, AddTeamMemberPayload , ResendInvitePayload, AddPrivacyPayload} from '../../models/organization';
import { ListParams, ListResponse } from '../../models/common';

export interface OrganizationState {
  fetchOrganization: string;
  organizations: ListOrganizationResponse[];
  currentOrganization: {};
  setCurrentOrganization: string;
  addNewOrganization: string;
  totalOrganizations: number;
  teamMembers: ListOrganizationMembers[];
  listTeamMembers: string;
  addNewTeamMember: string;
}

const initialState: OrganizationState = {
  fetchOrganization: 'pending',
  organizations: [],
  currentOrganization: {},
  setCurrentOrganization: 'pending',
  addNewOrganization: 'pending',
  totalOrganizations: 0,
  teamMembers: [],
  listTeamMembers: '',
  addNewTeamMember: ''
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    listOrganization(state, action: PayloadAction<ListParams>) {
      state.fetchOrganization = 'pending';
    },
    listOrganizationSuccess(
      state,
      action: PayloadAction<ListResponse<ListOrganizationResponse>>
    ) {
      state.fetchOrganization = 'success';
      state.organizations = action.payload.results;
      state.totalOrganizations = action.payload.count
    },
    listOrganizationFailed(state, action: PayloadAction<string>) {
      state.fetchOrganization = 'failed';
    },
    setCurrentOrganization(state, action) {
      state.currentOrganization = action.payload;
      state.setCurrentOrganization = 'success';
    },
    setCurrentOrganizationInitial(state) {
      state.setCurrentOrganization = 'pending';
    },
    addNewOrganization(state, action: PayloadAction<AddOrganisationPayload>) {
      state.addNewOrganization = 'pending'
    },
    addNewOrganizationSuccess(state, action: PayloadAction<ListOrganizationResponse>) {
      state.addNewOrganization = 'success'
      state.organizations.unshift(action.payload)
      state.totalOrganizations = state.totalOrganizations + 1
    },
    addNewOrganizationFailed(state, action: PayloadAction<string>) {
      state.addNewOrganization = 'failed'
    },
    listOrganizationMembers(state, action: PayloadAction<string>) {
      state.listTeamMembers = 'pending'
    },
    listOrganizationMembersSuccess(state, action: PayloadAction<ListOrganizationResponse>) {
      state.listTeamMembers = 'success'
      state.teamMembers = action.payload.users;
    },
    listOrganizationMembersFailed(state, action: PayloadAction<string>) {
      state.listTeamMembers = 'failed'
    },
    addNewTeamMember(state, action: PayloadAction<AddTeamMemberPayload>) {
      state.addNewTeamMember = 'pending'
    },
    addNewTeamMemberSuccess(state, action: PayloadAction<ListOrganizationResponse>) {
      state.addNewTeamMember = 'success'
      state.teamMembers = action.payload.users
    },
    addNewTeamMemberFailed(state, action: PayloadAction<string>) {
      state.addNewTeamMember = 'failed'
    },
    resendInvite(state, action: PayloadAction<ResendInvitePayload>){
    },
    addPrivacy(state, action: PayloadAction<AddPrivacyPayload>) {

    }


  },
});

// Actions
export const organizationActions = organizationSlice.actions;

// Selectors
export const selectOrganizationList = (state: RootState) => state.organization.organizations;
export const selectCurrentOrganization = (state: RootState) => state.organization.currentOrganization;
export const fetchOrganizationStatus = (state: RootState) => state.organization.fetchOrganization;
export const setCurrentOrganizationStatus = (state: RootState) => state.organization.setCurrentOrganization;
export const addNewOrganizationStatus = (state: RootState) => state.organization.addNewOrganization;
export const getOrganizationCount = (state: RootState) => state.organization.totalOrganizations;
export const getOrganizationTeamMembers = (state: RootState) => state.organization.teamMembers;
export const getAddNewMemberStatus = (state: RootState) => state.organization.addNewTeamMember;
export const fetchTeamMemberStatus =  (state: RootState) => state.organization.listTeamMembers;

// Reducer
const organizationReducer = organizationSlice.reducer;
export default organizationReducer;
