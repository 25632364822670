import { authSaga } from '../features/auth/authSaga';
import { sessionSaga } from '../features/sessions/sessionSaga';
import { feedbackFormSaga } from '../features/feedbackForms/feedbackFormSaga';
import { organizationSaga } from '../features/organizations/organizationSaga';
import { projectSaga } from '../features/projects/projectSaga';
import { feedbackSaga } from '../features/feedbacks/feedbackSaga';
import { dashboardSaga } from '../features/dashboard/dashboardSaga';
import { issueSaga } from '../features/issues/issueSaga';
import { billingSaga } from '../features/settings/pages/billing/billingSaga';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([authSaga(), sessionSaga(), feedbackFormSaga(), organizationSaga(), projectSaga(), feedbackSaga(), dashboardSaga(), issueSaga(), billingSaga()]);
}
