import { useNavigate } from "react-router-dom";

const CommonLayout = ({ component: Component }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <main>
          <div className="topBarSecondVer d-flex justify-content-between align-items-center">
            <div className="logo-container d-flex align-items-center">
              <div>
                <span className="logoIconLD">LD</span>
                <span className="logoIconText ms-2 me-3">LogDuck</span>
              </div>
            </div>
            <div className="topBarUser">
              <button type="button" className="btn btn-info" onClick={() => navigate("/")}>Login</button>
              <button type="button" className="btn btn-info ms-3 saveCrdBtn" onClick={() => navigate("/signup")}>Signup</button>
            </div>
          </div>
          <Component />
        </main>
      </div>
    </>
  );
};

export default CommonLayout;
