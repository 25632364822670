import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { sessionDetail } from '../sessionSlice';
import { useEffect, useState } from 'react';
import { DateTime, Duration } from "luxon";
import { selectCurrentProject } from '../../projects/projectSlice';
import { sessionActions } from '../sessionSlice';
import { useParams } from 'react-router-dom';
import NetworkRequest from '../components/networkRequest';
import NetworkRequestDetail from '../components/networkRequestDetail';
import Logs from '../components/logs';
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

const SessionDetail = () => {

  const dispatch = useAppDispatch();

  const session: any = useAppSelector(sessionDetail);
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const [selectedNetworkRequest, setSelectedNetworkRequest] = useState()
  const [layoutState, setLayoutState] = useState({
    appPane: {
      flex: 0.8
    },
    rightPane: {
      flex: 0.2
    }
  })


  let { id }: any = useParams();

  const setCurrentNetworkRequest = (data: any) => {
    setSelectedNetworkRequest(data)
  }
 
  useEffect(() => {
    dispatch(sessionActions.listSessionDetail(id));
  }, [currentProject]); // Data of session route changes if default project is changed

  useEffect(() => {
    const item = window.localStorage.getItem(
      "re-flex-storage-demo")

  }, []);

  const onResizePane: any = (event: any) => {

    const { name, flex } = event.component.props

    let obj: any = {
      ...layoutState
    }
    obj[name].flex = flex

    window.localStorage.setItem(
      "re-flex-storage-demo",
      JSON.stringify(obj))

    setLayoutState(obj)
  }

  return (

    <div className="page-content-wrapper pageWrapper networkSessionWrapper">

      <ReflexContainer orientation="horizontal">

        <ReflexElement>

          <ReflexContainer orientation="vertical">

            <ReflexElement flex={layoutState.appPane.flex}
              onResize={(e) => onResizePane(e)}
              name="appPane">
              <div>
                
                <NetworkRequest
                  networks={session.networks}
                  setNetworkRequest={setCurrentNetworkRequest}
                ></NetworkRequest>
              </div>

            </ReflexElement>

            {/* {selectedNetworkRequest && <> */}
              <ReflexSplitter />

              <ReflexElement className="bottom-pane">
                <div>
                  <div>
                    <NetworkRequestDetail currentNetworkRequest={selectedNetworkRequest}></NetworkRequestDetail>
                  </div>
                </div>
              </ReflexElement>
            {/* </>} */}

          </ReflexContainer>

        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement flex={layoutState.rightPane.flex}
          onResize={(e) => onResizePane(e)}
          className="right-pane"
          name="rightPane">

          <div>
            <Logs consoleLogs={session.logs}></Logs>
          </div>
        </ReflexElement>

      </ReflexContainer>
      {/* <ReflexContainer orientation="vertical">
        <div className="session-report">
          <div className="network w-100">
            <div className="network_top d-flex justify-content-between align-items-center">
              <ul className="list-unstyled d-flex mb-0">
                <li className="active">Network</li>
                <li>Performance</li>
                <li>View DOM</li>
              </ul>
              <div className="network_filter">
                <input type="text" placeholder="Filter network entries" />
                <button>All</button>
              </div>
            </div>
            <div className="d-flex">
              <NetworkRequest
                networks={session.networks}
                setNetworkRequest={setCurrentNetworkRequest}
              ></NetworkRequest>
              <span role="presentation" className="Resizer vertical"></span>
              <NetworkRequestDetail currentNetworkRequest={selectedNetworkRequest}></NetworkRequestDetail>
            </div>
          </div>
          <div className="resizer-y"></div>
          <div className="resizable-y d-flex justify-content-between">
            <Logs consoleLogs={session.logs}></Logs>
          </div>
        </div>
      </ReflexContainer> */}

    </div>
  )
}
export default SessionDetail;