import React from "react";

const PublicLayout = ({ component: Component }:any) => (
  <>
    <main role="main">
      <Component/>
    </main>
  </>
);

export default PublicLayout;
