import React, { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { AddOrganisationPayload } from '../../models/organization';

interface AddNewCompanyModalProps {
  onSubmit: any;
}

export const AddNewCompanyModel: FunctionComponent<AddNewCompanyModalProps> = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm<AddOrganisationPayload>();

  const onSubmit: SubmitHandler<AddOrganisationPayload> = data => {
    props.onSubmit(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="Email1" className="form-label">Organization Name</label>
        <input type="text" className="form-control" id="InputEmail1" placeholder="Name here" {...register("name", { required: true })} />
        {errors.name && <span>Please enter organization name</span>}
      </div>
      <div className="mb-4 pb-2">
        <label htmlFor="Email1" className="form-label">Project Name</label>
        <input type="text" className="form-control" id="InputEmail1" placeholder="Project name" {...register("project_name", { required: true, })} />
        {errors.project_name && <span>Please enter project name</span>}
      </div>
      <button type="submit" className="btn btn-info py-2 px-4">Add Organization</button>
    </form>
  );
};
