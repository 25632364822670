
const Gdpr = () => {

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">GDPR/CCPA Control</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="notificationMenu">
              <span>
                <img src="../assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="md-cont-wrapper bg-white w-100">
              <div className="col-md-4">
                <div className="mb-5">
                  <h5 className="mb-3">Delete Specific User</h5>
                  <p>If a customer has requested that their data be deleted as part of a GDPR or CCPA request, you can delete all sessions associated with their email address or user ID here. This will also add them to an Excluded users list so no future sessions will be recorded.</p>
                  <p>Please note that this will apply to all apps within your organization. Any existing sessions that are deleted will continue to count towards your session usage</p>
                </div>
                <form className="project-setting-form">
                  <div className="mb-4 pb-2">
                    <label>User ID / Email</label>
                    <input type="text" className="form-control" placeholder="User ID / Email" />
                  </div>
                  <button type="button" className="btn btn-primary primaryDefaultBtn">Save Changes</button>
                </form>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Gdpr;
