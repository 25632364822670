
import { FunctionComponent, useState, useEffect } from "react";
import { useAppSelector } from '../../../../../app/hooks';
import { selectCardsList } from "../billingSlice";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddNewCard from "./addNewCard";
import Swal from 'sweetalert2';

interface ActiveSubProps {
  subscription: any;
  newCardAddStatus: any;
  addNewCard: any
  currentPlan: any
  cancelSubscription: any;
  updateSource: any;
}

const stripePromise = loadStripe('pk_test_51Kt4clH5n0jAi1ojMWCQgf5iCAy5PmgYhYgx0sgVeL2UXpIX3xzBW4tRu0LElTUyJovzwlckvcX7fMO0ZT2C6o4i00ZJk5cWNB');

export const ActiveSubscription: FunctionComponent<ActiveSubProps> = (props) => {

  let { subscription, newCardAddStatus, addNewCard, currentPlan, cancelSubscription, updateSource } = props;
  const cards: any = useAppSelector(selectCardsList);
  const [showAddCards, setShowAddCards] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (newCardAddStatus == 'success') {
      setShowAddCards(false)
    }
  }, [newCardAddStatus])


  const appearance: any = {
    theme: 'stripe',
  };
  const options = {
    appearance,
  };

  const onCancel = () => {
    Swal.fire({
      title: 'Are you sure, you want to cancel subscription ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        cancelSubscription(subscription._id)
      } else if (result.isDenied) {
        console.log('No')
      }
    })
  }

  const clickHandler = (e: any, item: any) => {
    if (e.target.checked) {
      console.log('e.target.checked', e.target.checked)
      console.log('item', item)
      updateSource(item.id)
    }
  }
  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center border-bottom mb-3">
          <h1 className="mb-0">My Plan</h1>
        </div>
        <div className="proPlanTop bg-white d-flex justify-content-between align-items-center border-bottom p-4">
          <div className="media align-items-center">
            <div className="media-body">
              <h5 className="mb-1 text-secondary">{subscription.product_name} Plan <button type="button" className="btn btn-outline-secondary changeBtn">Change</button></h5>
              <p className="mb-0">Monthly payment | € EUR | Annual cost: <span className="h5 text-dark" style={{ marginLeft: "4px" }}>€ {(subscription.product_price) / 100}</span></p>

            </div>
          </div>
          <div className="upgradePlanBtn">
            <button type="button" className="btn btn-outline-secondary" onClick={() => onCancel()}>Cancel Plan</button>
            {/* <button type="button" className="btn btn-info ms-3">Upgrade Plan</button> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex">
            <div className="sm-cont-wrapper bg-white w-100 p-0 h-auto">

              <Elements options={options} stripe={stripePromise}>
                <div>
                  {!showAddCards && <form className="project-setting-form">
                    <div className="continer-fluid">
                      <div className=" pt-4 px-4 pb-5">
                        <h5>Saved cards</h5>
                        <div className="row border-bottom pb-4">
                          <div className="col-md-5">
                            <label>Visa Card</label>
                            {cards.length > 0 && cards.map((card: any, idx: number) => (
                              <div className="form-check cardCheckForm custom-address-check">
                                <input className="form-check-input" type="radio" value={card.id} defaultChecked={card.id == card.customer.default_source} id="cardCheckForm" name="cardCheck" onClick={(e) => clickHandler(e, card)} />
                                <label className="form-check-label d-flex justify-content-between align-items-center mb-0 ms-3" htmlFor="cardCheckForm">
                                  <div>
                                    <span className="d-block">**************{card.last4}</span>
                                    <small>{card.name ? card.name : 'n/a'}</small>
                                  </div>
                                  <img src="../assets/images/card.svg" alt="Payment Card" />
                                </label>
                              </div>

                            ))}
                          </div>
                          {errorMessage != '' && <span className="text-danger">{errorMessage}</span>}
                          <div className="col-12 pb-3">
                            <button type="button" className="btn btn-outline-info addNewCardButton" onClick={() => setShowAddCards(true)}>
                              <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 7H13" stroke="#8BB2BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7 1V13" stroke="#8BB2BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                              Add new card
                            </button>
                          </div>

                        </div>

                        {/* <div className="col-12 formBottom companyInfoFromBottom"> */}
                        {/* <p className="text-dark pb-1 mt-3">Your card will be charged € {(currentPlan.amount) / 100} monthly, By clicking ‘Pay now’ you are agree to our <a href="">terms and conditions</a></p> */}
                        {/* <button type="button" className="btn btn-outline-secondary">Back</button> */}
                        {/* <button type="submit" className="btn btn-info ms-3">Pay Now</button> */}
                        {/* </div> */}

                      </div>
                    </div>
                  </form>}
                  {showAddCards &&
                    <AddNewCard
                      currentPlan={currentPlan}
                      setShowAddCards={setShowAddCards}
                      newCardAddStatus={newCardAddStatus}
                      addNewCard={addNewCard}
                      showPaymentButton={false}
                    ></AddNewCard>}
                </div>
              </Elements>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveSubscription;
