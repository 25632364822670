import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import routes from "./Routes";
import NotFound from "../features/auth/pages/notFound";
import { useAppSelector } from '../app/hooks';
import { selectIsLoggedIn } from '../features/auth/authSlice'
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import CommonLayout from "../layouts/CommonLayout";

const Navigation = () => {

  const isUser = useAppSelector(selectIsLoggedIn);
  console.log("Is User Logged in", isUser);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ component: Component, path, type, to, title }) => (
          <React.Fragment key={Date.now()}>
            {(type === "public" && !isUser) &&
              <Route
                path={path}
                key={Date.now()}
                element={<PublicLayout component={Component}>
                </PublicLayout>}
              ></Route>}

            {(type === "private" && isUser && path == '/settings') &&
              <Route path={path} element={<Navigate replace to='/dashboard' />}>
              </Route>}

            {(type === "private" && isUser) &&
              <Route
                path={path}
                key={Date.now()}
                element={<PrivateLayout component={Component}>
                </PrivateLayout>}
              ></Route>}

            {(type === "common" && !isUser) &&
              <Route
                path={path}
                key={Date.now()}
                element={<CommonLayout component={Component}>
                </CommonLayout>}
              ></Route>}

            {(type === "private" && !isUser || type === "public" && isUser || type === 'common' && isUser) &&
              <Route path={path} element={<Navigate replace to={to} />}>
              </Route>}


          </React.Fragment>
        ))}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
