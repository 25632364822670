import React, { useState, FunctionComponent, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { AddProjectPayload } from '../../models/project';
import { Typeahead } from 'react-bootstrap-typeahead';

interface AddSlackChannelProps {
  channels: any;
  updateSlackChannel: any;
  savedChannel: string
}

const AddSlackChannel: FunctionComponent<AddSlackChannelProps> = (props) => {

  const [selected, setSelected] = useState<{ id?: string; name: string }[]>(
    [],
  );

  const [showError, setShowError] = useState<boolean>(false);

  console.log('previosa change,', props.savedChannel)

  // if(props.savedChannel){
  //   setSelected(props.savedChannel)
  // }

  useEffect(() => {
    if (props.savedChannel) {
      let obj = {
        name: props.savedChannel,
      }
      setSelected([obj])
    }
  }, [])

  const saveChannel = () => {
    console.log('on submit---', selected)

    if (!selected || selected.length == 0) setShowError(true)
    props.updateSlackChannel(selected[0])
  }

  return (
    <form >
      <div className="mb-4 pb-2">
        <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          onChange={(selected: any) => {
            if (selected.length > 0) {
              console.log("@@@@ selected value", selected[0])
              setSelected(selected)
              setShowError(false)
            } else {
              console.log('else')
              setSelected([])
            }
          }}
          options={props.channels}
          placeholder="Choose channel...."
          selected={selected}
        />
        {showError && <span>Please select a channel</span>}
      </div>
      <button type="button" className="btn btn-info py-2 px-4" disabled={showError} onClick={() => saveChannel()}>Add Project</button>
    </form>
  );
};

export default React.memo(AddSlackChannel)
