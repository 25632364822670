import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authActions , currentUserDetail} from '../../features/auth/authSlice';
import { Modal } from '../../components/modals/modal';
import { AddNewCompanyModel } from '../../components/modals/addNewOrganization';
import { AddNewProjectModel } from '../../components/modals/addNewProject';
import React, { Component, FunctionComponent, useEffect, useState } from 'react';
import { organizationActions, selectOrganizationList, fetchOrganizationStatus, setCurrentOrganizationStatus, selectCurrentOrganization, addNewOrganizationStatus } from '../../features/organizations/organizationSlice';
import { projectActions, selectProjectList, fetchProjectStatus, selectCurrentProject, addNewProjectStatus } from '../../features/projects/projectSlice';
import { ListOrganizationResponse, AddOrganisationPayload } from '../../models/organization';
import { AddProjectPayload } from '../../models/project'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { nameToInitials, capitalizeString } from '../../utils/common';

const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  /**Function to be called on clicking logout */
  const handleLogout = () => {
    dispatch(authActions.logout())
    dispatch({ type: 'logout' })
  }
  const [isAddCompanyModalShown, setIsAddCompanyModalShown] = useState<boolean>(false);
  const [isAddProjectModalShown, setIsAddProjectModalShown] = useState<boolean>(false);
  let location = useLocation();

  const organizations = useAppSelector(selectOrganizationList); // Organization List
  const projects = useAppSelector(selectProjectList); // Project List
  const fetchOrganizations = useAppSelector(fetchOrganizationStatus); // Status of fetched organization
  const organizationSelected: string = useAppSelector(setCurrentOrganizationStatus); // Status of organization changed or new organization selected from dropdown list
  const currentOrganization: any = useAppSelector(selectCurrentOrganization); // List of current/active organisation
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const fetchProjects: string = useAppSelector(fetchProjectStatus); //Status of fetched project list
  const addNewOrgStatus: string = useAppSelector(addNewOrganizationStatus) // Status of if new organisation added succes or not
  const addNewProjStatus: string = useAppSelector(addNewProjectStatus); // Status of if new project added successfully or not
  const userDetail: any = useAppSelector(currentUserDetail);

  /**List organization on page load */
  useEffect(() => {
    const data = {
      limit: 20,
      skip: 0,
      sort_key: "created_at",
      sort_order: 1,
    };
    dispatch(organizationActions.listOrganization(data))
  }, [])

  /** When organizations fetched successfully, set the first organization as default current organization
   * so that projects can be fetched of that organization
   */
  useEffect(() => {
    if (fetchOrganizations == 'success' && location.pathname != '/organisation') {
      if (organizations.length) {
        let org = organizations[0];
        dispatch(organizationActions.setCurrentOrganization(org))
      }
      else {
        setIsAddCompanyModalShown(true)
      }
    }
  }, [fetchOrganizations, addNewOrgStatus])

  /**
   * When current organization is set, fetch the projects of that organization
   */

  useEffect(() => {
    if (organizationSelected == 'success' && location.pathname != '/project') {
      let obj = {
        organisation_id: currentOrganization._id,
        skip: 0,
        limit: 20
      }
      dispatch(projectActions.listProject(obj))
      dispatch(organizationActions.setCurrentOrganizationInitial())
    }
  }, [organizationSelected])

  /** When project of organization are fetched successfully, set the current project
   * initially set the first project as current project
   */

  useEffect(() => {
    if (fetchProjects == 'success' && location.pathname != '/project') {
      if (projects.length) {
        let proj = projects[0];
        dispatch(projectActions.setCurrentProject(proj))
      }
    }
  }, [fetchProjects])

  /**Function to be called on submit of add new organisation form */
  const addNewOrganization = (data: AddOrganisationPayload) => {
    dispatch(organizationActions.addNewOrganization(data))
  }

  /**Function to be called on submit of add new project form */
  const addNewProject = (data: AddProjectPayload) => {
    dispatch(projectActions.addNewProject(data))
  }

  /**When new organization added successfully, hide the add new org popup */
  useEffect(() => {
    if (addNewOrgStatus == 'success') {
      setIsAddCompanyModalShown(false)
    }
  }, [addNewOrgStatus])

  /** When new project added successfully, close the add new proj popup */
  useEffect(() => {
    if (addNewProjStatus == 'success') {
      setIsAddProjectModalShown(false)
    }
  }, [addNewProjStatus])

  /**Set current organization, if any other org is selected */
  const changeOrganization = (org: any) => {
    dispatch(organizationActions.setCurrentOrganization(org))
  }

  /** Set current project, if any other project is selected */
  const changeProject = (proj: any) => {
    dispatch(projectActions.setCurrentProject(proj));
  }

  return (
    <div className="topBar topBarSecondVer d-flex justify-content-between align-items-center">
      <div className="logo-container d-flex align-items-center">
        <div className="main-logo-icon me-3">
          <span className="logoIconLD">LD</span>
          <span className="logoIconText ms-2 me-3">LogDuck</span>
        </div>

        <div className="dropdown logoDropdown py-0 ">
          <button className="btn btn-primary dropdown-toggle" type="button" id="logodropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.3618 18.324V10.8087C19.3618 4.85223 14.5115 0.00195312 8.55502 0.00195312C8.08701 0.00195312 7.70408 0.384886 7.70408 0.852897V6.97955H1.63142C1.58606 6.97548 1.53966 6.97525 1.49235 6.97955L0.811605 7.02209C0.343594 7.02209 -0.0393391 7.44756 0.00323941 7.87303C0.0399975 8.27745 0.331054 8.58604 0.683987 8.66257V18.3141C0.296796 18.3935 0.00323941 18.738 0.00323941 19.1478C0.00323941 19.6158 0.386172 19.9987 0.854183 19.9987H1.53493H8.55506H18.5109H19.1491C19.6171 19.9987 20 19.6158 20 19.1478C20 18.7532 19.7277 18.4191 19.3618 18.324ZM17.6599 10.8087V18.2969H9.40597V7.83049C9.40597 7.73826 9.39089 7.6494 9.36343 7.56604V1.7038C14.001 2.12927 17.6599 6.04352 17.6599 10.8087ZM7.70412 18.2969H5.87461V14.8506C5.87461 14.3826 5.49168 13.9997 5.02367 13.9997C4.55566 13.9997 4.17272 14.3826 4.17272 14.8506V18.2969H2.34326C2.34326 18.2125 2.34326 8.6814 2.34326 8.6814H7.70408V18.2969H7.70412Z" fill="#4B4949" />
              <path d="M13.5326 8.72431C14.0006 8.72431 14.3835 8.34138 14.3835 7.87337V7.57555C14.3835 7.10754 14.0006 6.72461 13.5326 6.72461C13.0646 6.72461 12.6816 7.10754 12.6816 7.57555V7.87337C12.6816 8.34138 13.0646 8.72431 13.5326 8.72431Z" fill="#4B4949" />
              <path d="M13.5326 12.6384C14.0006 12.6384 14.3835 12.298 14.3835 11.7874V11.4896C14.3835 11.0216 14.0006 10.6387 13.5326 10.6387C13.0646 10.6387 12.6816 11.0216 12.6816 11.4896V11.7874C12.6816 12.2554 13.0646 12.6384 13.5326 12.6384Z" fill="#4B4949" />
              <path d="M13.5326 16.5954C14.0006 16.5954 14.3835 16.2551 14.3835 15.7445V15.4466C14.3835 14.9786 14.0006 14.5957 13.5326 14.5957C13.0646 14.5957 12.6816 14.9786 12.6816 15.4466V15.7445C12.6816 16.2125 13.0646 16.5954 13.5326 16.5954Z" fill="#4B4949" />
            </svg>
            {currentOrganization ? currentOrganization.name : ''}
          </button>

          <ul className="dropdown-menu logDuckCompanyDropDown pt-0 pb-3" aria-labelledby="logodropdown">
            {organizations && organizations.length && organizations.map((org: ListOrganizationResponse, idx: number) => (
              <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center" key={idx} onClick={() => changeOrganization(org)}>
                <div className="media">
                  <div className="media-img me-2">
                    <span> {org.name[0]}</span>
                  </div>
                  <div className="media-body">
                    {org.name}
                  </div>
                </div>
                {currentOrganization && currentOrganization._id == org._id && <span className="okayIcon">
                  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L4.33333 7.66637L1 4.33333" stroke="#68DE0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>}
              </li>))}
            <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center" onClick={() => navigate("/settings/organisation")}>
              <div className="media">
                <div className="media-img me-2">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.3936 4.16626C12.1942 3.68064 11.9184 3.2403 11.6018 2.83043C11.8142 1.8637 11.4029 0.832075 10.5001 0.310794C9.59605 -0.212748 8.49654 -0.0509455 7.7657 0.616679C7.51275 0.583065 7.26214 0.53917 7.00007 0.53917C6.73743 0.53917 6.48681 0.583065 6.23328 0.616679C5.50127 -0.0509455 4.40125 -0.212748 3.50007 0.310794C2.59715 0.832002 2.1859 1.8637 2.39837 2.83043C2.08162 3.2403 1.80593 3.68064 1.60657 4.16626C0.676155 4.47302 0 5.33942 0 6.3725C0 7.40559 0.676157 8.27205 1.60643 8.57882C1.80578 9.06444 2.08155 9.50478 2.39823 9.91465C2.18575 10.8814 2.59707 11.913 3.49993 12.4343C3.86677 12.6468 4.26898 12.7476 4.66433 12.7476C5.24198 12.7476 5.80249 12.5251 6.23605 12.1287C6.48842 12.162 6.73852 12.2059 7 12.2059C7.26148 12.2059 7.51158 12.162 7.76395 12.1287C8.19744 12.5252 8.75802 12.7476 9.33567 12.7476C9.73102 12.7476 10.1332 12.6468 10.5001 12.4343C11.403 11.9131 11.8142 10.8814 11.6018 9.91465C11.9185 9.50478 12.1942 9.06444 12.3936 8.57882C13.3238 8.27205 14 7.40559 14 6.3725C14 5.33942 13.3238 4.47302 12.3936 4.16626ZM7 8.70584C5.71142 8.70584 4.66667 7.66109 4.66667 6.3725C4.66667 5.08392 5.71142 4.03917 7 4.03917C8.28858 4.03917 9.33333 5.08392 9.33333 6.3725C9.33333 7.66109 8.28858 8.70584 7 8.70584Z" fill="#4B4949" />
                  </svg>
                </div>
                <div className="media-body">
                  Manage Companies
                </div>
              </div>
            </li>
            <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center">
              <div className="mediaAddCompany">
                <button type="button" className="btn btn-primary addMemberBtn" onClick={() => setIsAddCompanyModalShown(!isAddCompanyModalShown)}>
                  <span>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 6.5H12" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.5 1V12" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                  Add new organization
                </button>
              </div>
            </li>
            <Modal
              isShown={isAddCompanyModalShown}
              hide={() => setIsAddCompanyModalShown(!isAddCompanyModalShown)}
              headerText="Add Organization"
              modalContent={
                <AddNewCompanyModel
                  onSubmit={addNewOrganization}
                />
              }
            />
          </ul>
        </div>
        <div className="dropdown logoDropdown py-0">
          <button className="btn btn-primary dropdown-toggle" type="button" id="logodropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 4V14.5833C19 14.7601 18.9298 14.9297 18.8047 15.0547C18.6797 15.1798 18.5101 15.25 18.3333 15.25H1.75C1.55109 15.25 1.36032 15.171 1.21967 15.0303C1.07902 14.8897 1 14.6989 1 14.5V1.75C1 1.55109 1.07902 1.36032 1.21967 1.21967C1.36032 1.07902 1.55109 1 1.75 1H6.75C6.91228 1 7.07018 1.05263 7.2 1.15L9.8 3.1C9.92982 3.19737 10.0877 3.25 10.25 3.25H18.25C18.4489 3.25 18.6397 3.32902 18.7803 3.46967C18.921 3.61032 19 3.80109 19 4Z" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

            {currentProject ? currentProject.name : ''}
          </button>

          <ul className="dropdown-menu logDuckCompanyDropDown pt-0 pb-3" aria-labelledby="logodropdown">
            {projects && projects.length && projects.map((project, idx) => (
              <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center" key={idx} onClick={() => changeProject(project)}>
                <div className="media">
                  <div className="media-img me-2">
                    <span>{project.name[0]}</span>
                  </div>
                  <div className="media-body">
                    {project.name}
                  </div>
                </div>
                {currentProject && currentProject._id == project._id && <span className="okayIcon">
                  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L4.33333 7.66637L1 4.33333" stroke="#68DE0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>}
              </li>))}
            <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center" onClick={() => navigate("/settings/project")}>
              <div className="media">
                <div className="media-img me-2">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.3936 4.16626C12.1942 3.68064 11.9184 3.2403 11.6018 2.83043C11.8142 1.8637 11.4029 0.832075 10.5001 0.310794C9.59605 -0.212748 8.49654 -0.0509455 7.7657 0.616679C7.51275 0.583065 7.26214 0.53917 7.00007 0.53917C6.73743 0.53917 6.48681 0.583065 6.23328 0.616679C5.50127 -0.0509455 4.40125 -0.212748 3.50007 0.310794C2.59715 0.832002 2.1859 1.8637 2.39837 2.83043C2.08162 3.2403 1.80593 3.68064 1.60657 4.16626C0.676155 4.47302 0 5.33942 0 6.3725C0 7.40559 0.676157 8.27205 1.60643 8.57882C1.80578 9.06444 2.08155 9.50478 2.39823 9.91465C2.18575 10.8814 2.59707 11.913 3.49993 12.4343C3.86677 12.6468 4.26898 12.7476 4.66433 12.7476C5.24198 12.7476 5.80249 12.5251 6.23605 12.1287C6.48842 12.162 6.73852 12.2059 7 12.2059C7.26148 12.2059 7.51158 12.162 7.76395 12.1287C8.19744 12.5252 8.75802 12.7476 9.33567 12.7476C9.73102 12.7476 10.1332 12.6468 10.5001 12.4343C11.403 11.9131 11.8142 10.8814 11.6018 9.91465C11.9185 9.50478 12.1942 9.06444 12.3936 8.57882C13.3238 8.27205 14 7.40559 14 6.3725C14 5.33942 13.3238 4.47302 12.3936 4.16626ZM7 8.70584C5.71142 8.70584 4.66667 7.66109 4.66667 6.3725C4.66667 5.08392 5.71142 4.03917 7 4.03917C8.28858 4.03917 9.33333 5.08392 9.33333 6.3725C9.33333 7.66109 8.28858 8.70584 7 8.70584Z" fill="#4B4949" />
                  </svg>
                </div>
                <div className="media-body">
                  Manage Projects
                </div>
              </div>
            </li>
            <li className="logDuckCompanyDrpDwn position-relative d-flex justify-content-between align-items-center">
              <div className="mediaAddCompany">
                <button type="button" className="btn btn-primary addMemberBtn" onClick={() => setIsAddProjectModalShown(!isAddProjectModalShown)}>
                  <span>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 6.5H12" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.5 1V12" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                  Add new project
                </button>
              </div>
            </li>
            <Modal
              isShown={isAddProjectModalShown}
              hide={() => setIsAddProjectModalShown(!isAddProjectModalShown)}
              headerText="Add Project"
              modalContent={
                <AddNewProjectModel
                  onSubmit={addNewProject}
                  organisation_id={currentOrganization._id}
                />
              }
            />
          </ul>
        </div>
        <div className="dropdown trialBtn">
          {currentOrganization && currentOrganization.active_subscription  && <button className="btn btn-light rounded-pill" type="button" id="dropdownMenuButton1" onClick={() => navigate("/settings/plans")}>
           { currentOrganization.active_subscription.subscription_id =='free' ? 'Trial Plan' : `${currentOrganization.active_subscription.product_name} Plan`} 
          </button>}
          {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul> */}
        </div>
      </div>
      <div className="topBarUser topBarUserDropdown py-0">
        <button className="btn dropdown-toggle p-0" type="button" id="logodropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <span className="topUserTxt">{userDetail ? nameToInitials(userDetail.firstname + " " + userDetail.lastname) : "AU"}</span>
        </button>
        <ul className="dropdown-menu py-1 px-0" aria-labelledby="logodropdown">
          <li><button className="dropdown-item" onClick={() => handleLogout()}>Sign out</button></li>
        </ul>

      </div>
    </div>
  );
};

export default Header;
