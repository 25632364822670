
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React from 'react';

const Analytics = () => {

  return (
    <>
      <div className="analytics_col mb-5">
        <h2 className="sub_heading mb-3">Analytics</h2>

        <div>
          <form className="d-flex justify-content-between align-items-center mb-4">
            <div className="dropdown sessionsDropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Sessions
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another</a></li>
                <li><a className="dropdown-item" href="#">Something</a></li>
              </ul>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Monthly
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another</a></li>
                  <li><a className="dropdown-item" href="#">Something</a></li>
                </ul>
              </div>
              <span className="from_spn">From</span>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  12 May, 2022
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another</a></li>
                  <li><a className="dropdown-item" href="#">Something</a></li>
                </ul>
              </div>
              <span className="to_spn">To</span>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  12 Dec, 2022
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another</a></li>
                  <li><a className="dropdown-item" href="#">Something</a></li>
                </ul>
              </div>
            </div>

          </form>
          <img className="w-100" src="assets/images/graph.svg" />
        </div>
      </div>

    </>
  );
};

export default Analytics;
