import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectSessionList, sessionActions, sessionCount, selectSessionLoading } from '../sessionSlice';
import { useEffect, useState } from 'react';
import { DateTime, Duration } from "luxon";
import { selectCurrentProject } from '../../projects/projectSlice';
import { Pagination } from '../../../components/pagination';
import { useNavigate } from 'react-router-dom';
import { NoDataFound } from '../../../components/noDataFound';
import { CustomLoader } from '../../../components/customLoader'


const Session = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sessionList = useAppSelector(selectSessionList);
  const sessionFetchStatus = useAppSelector(selectSessionLoading);

  console.log('status', sessionFetchStatus)

  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const totalSessions: number = useAppSelector(sessionCount); // List of current/active project 
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per

  useEffect(() => {

    let data = {
      project_id: currentProject._id,
      skip: 0,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }

    dispatch(sessionActions.listProjectSessions(data));
  }, [currentProject]); // Data of session route changes if default project is changed

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {

    setCurrentPage(page)

    let data = {
      project_id: currentProject._id,
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }

    dispatch(sessionActions.listProjectSessions(data));

  }

  const getOSInfo = (session: any): string => {
    if (session.browser_info) {
      const {
        os: { family },
      } = session.browser_info;
      return family;
    } else {
      return "N/A";
    }
  };

  const getDeviceInfo = (session: any): string => { // If name is like Chrome Mobile, then it is mobile browser else Desktop
    if (session.browser_info) {
      let name = session.browser_info.name.split(" ");
      if (name.length > 1) {
        return name[1]
      }
      else {
        return "Desktop"
      }
    } else {
      return "N/A";
    }
  };

  const getSessionDuration = (session: any) => {
    /**First check if current session is of more than 15 minutes from current time and session last activity
     * if yes, then the duration is diff between last activity and session start time
     * else its in progress. Currently if there is no activity for more than 15 min, session ends
     */
    if (session.last_activity) {
      let current_time = DateTime.now();
      let last_activity = DateTime.fromMillis(session.last_activity);
      let duration: any = current_time.diff(last_activity, ['minutes']).toObject()

      if (duration?.minutes > 15) {
        let difference: any = last_activity.diff(DateTime.fromMillis(session.start_time))
        let session_duration = Duration.fromMillis(difference).toFormat('hh:mm:ss')
        return session_duration
      } else {
        return 'In Progress'
      }

    }
  }
  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Sessions</h1>
          {/* <div className="notificationMenu">
            <span>
              <img src="assets/images/icons/Bell.svg" alt="notification" />
            </span>
            <span className="notify_dot"></span>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="table-responsive">
              {sessionList && sessionList.length > 0 && sessionList.map((session, idx) => (
                sessionFetchStatus== 'success' && 
                <div className="session__detail table">
                  <div className="session_dtl_col tr" key={idx} >
                    <div className="session_dtl1 td" onClick={() => navigate(`/session-detail/${session.session_id}`)}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/user.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <h5 className="mb-1">{session.user_identify ? `${session.user_identify.firstname} ${session.user_identify.lastname}` : 'Anonymous User'}</h5>
                          <p className="mb-0"><span>IP:</span>{session?.geolocation?.ip}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl2 td">
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Play.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            <span className="me-md-3 me-lg-4 me-2 d-flex align-items-center"><img className="me-2" src="assets/images/icons/CalendarBlank.svg" />{DateTime.fromMillis(session.start_time).toFormat('dd LLL, y')} |  {DateTime.fromMillis(session.start_time).toFormat('hh:mm a')}</span>
                            <span className="d-flex align-items-center"><img className="me-2" src="assets/images/icons/Timer.svg" />{getSessionDuration(session)}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl3 td">
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Gear2.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            <span className="me-md-3 me-lg-4 me-2 d-flex align-items-center">
                              {getOSInfo(session) && (getOSInfo(session) == 'OS X' || getOSInfo(session) == 'iOS') && <img src="assets/images/icons/iOS.svg" alt="user" />}
                              {getOSInfo(session) && getOSInfo(session) == 'Linux' && <img src="assets/images/icons/linux.svg" alt="user" />}
                              {getOSInfo(session) && (getOSInfo(session) == 'Windows' || getOSInfo(session) == 'Windows XP') && <img src="assets/images/icons/window.svg" alt="user" />}
                              {getOSInfo(session) && getOSInfo(session) == 'Debian' && <img src="assets/images/icons/debian.svg" alt="user" />}
                              {getOSInfo(session) && getOSInfo(session) == 'Android' && <img src="assets/images/icons/Android.svg" alt="user" />}
                              {getOSInfo(session) && getOSInfo(session) == 'Windows Phone' && <img src="assets/images/icons/window-phone.svg" alt="user" />}
                              {(getOSInfo(session) == 'N/A') && <img src="assets/images/icons/window.svg" alt="user" />}
                              {getOSInfo(session)}
                            </span>
                            <span className="me-md-3 me-lg-4 me-2 d-flex align-items-center"><img className="me-2" src="assets/images/icons/Monitor.svg" />{getDeviceInfo(session)}</span>
                            <span className="me-md-3 me-lg-4 me-2 d-flex align-items-center"><img className="me-2" src={`assets/images/icons/${session?.browser_info?.name}.svg`} />{session.browser_info ? session.browser_info.name : 'n/a'}</span>
                            <span className="me-md-3 me-lg-4 me-2 d-flex align-items-center"><img className="me-2" src="assets/images/icons/MapPin.svg" />{session?.geolocation?.state_prov}, {session?.geolocation?.country_code2}</span></p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              ))}
              {sessionFetchStatus && sessionFetchStatus == 'pending' && <CustomLoader></CustomLoader>}
              {sessionList && sessionList.length == 0 && sessionFetchStatus && sessionFetchStatus == 'success' &&
                <NoDataFound></NoDataFound>
              }
            </div>
            <Pagination
              totalRecords={totalSessions}
              currentPage={currentPage}
              onPageChange={(page: number) => onPageChange(page)}
              limit={limit}
              siblingCount={1}
            ></Pagination>
          </div>
        </div>
      </div>
    </>
  );
};

export default Session;
