import { Action, combineReducers, configureStore, ThunkAction , AnyAction} from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer from '../features/auth/authSlice';
import sessionReducer from '../features/sessions/sessionSlice';
import feedbackFormReducer from '../features/feedbackForms/feedbackFormSlice';
import organizationReducer from '../features/organizations/organizationSlice';
import projectReducer from '../features/projects/projectSlice';
import feedbackReducer from '../features/feedbacks/feedbackSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import issueReducer from '../features/issues/issueSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { persistReducer,persistStore,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { history } from '../utils';
import billingReducer from '../features/settings/pages/billing/billingSlice';

const combinedReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  session: sessionReducer,
  feedbackForm: feedbackFormReducer,
  organization: organizationReducer,
  project: projectReducer,
  feedback: feedbackReducer,
  dashboard: dashboardReducer,
  issue: issueReducer,
  billing: billingReducer
})

const persistConfig = {
  key: 'root',
  storage,
};
const rootReducer = (state: any, action:AnyAction) => {
  if (action.type === 'logout') {
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ LOGOUT CALLED @@@@@@@@@@@@@@@@@@@@@@')

    // storage.removeItem('persist:root')
    state = undefined;
    
  }
  return combinedReducer(state, action);
};


const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }}).concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
