import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { LoginPayload, authActions } from './authSlice';
import { push } from 'connected-react-router';
import { LoginResponse, Auth, SignupPayload, ForgotPasswordPayload, AcceptInvitePayload, ResetPasswordPayload, EditProfilePayload, ChangePasswordPayload } from '../../models/auth';
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";


function* handleLogin(action: PayloadAction<Auth>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/users/login`,
  });

  if (error) {
    yield put(authActions.loginFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {

    if (result.payload.email) {
      //if (result.payload.organisations === true) {
      yield put(authActions.loginSuccess(result.payload));
      yield put(push({ pathname: "/dashboard" }));
      // }
    }
  }
  return { error, result };
}

function* handleRegister(action: PayloadAction<SignupPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: action.payload.verification_token ? `/users/register-invited-user` : `/users`
  });
  if (error) {
    toast.error(error);
  } else {
    toast.success(result.message);
  }
  return { error, result };
}

function* handleForgotPassword(action: PayloadAction<ForgotPasswordPayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/users/forgot-password`,
  });
  if (error) {
    toast.error(error);
  } else {
    toast.success(result.message);
  }
  return { error, result };
}

export function* handleLogout(action: PayloadAction) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/users/logout`,
  });

  if (error) {
    console.log('error in logout--', error)
    toast.error(error);
  } else {
    console.log('logout success')
    
  }

  return { error, result };
}

export function* handleAcceptInvite(action: PayloadAction<AcceptInvitePayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/users/accept-company-invite`,
  });

  if (error) {
    yield put(authActions.acceptInviteFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.acceptInviteSuccess())
  }

  return { error, result };
}

export function* handleEmailVerification(action: PayloadAction<string>) {
  const { result, error } = yield call(httpClient, {
    method: "put",
    url: `/users/verify-email/${action.payload}`,
  });

  if (error) {
    yield put(authActions.emailVerificationFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.emailVerificationSuccess())
  }

  return { error, result };
}

export function* handleResetPasswordLinkVerification(action: PayloadAction<string>) {
  const { result, error } = yield call(httpClient, {
    data: { token: action.payload },
    method: "put",
    url: `users/verify-reset-password-token`,
  });

  if (error) {
    yield put(authActions.resetPasswordLinkVerificationFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.resetPasswordLinkVerificationSuccess())
  }

  return { error, result };
}

export function* handleResetPassword(action: PayloadAction<ResetPasswordPayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/users/reset-password`,
  });

  if (error) {
    yield put(authActions.resetPasswordFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.resetPasswordSuccess())
  }

  return { error, result };
}

export function* handleEditProfile(action: PayloadAction<EditProfilePayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/users/edit-profile`,
  });

  if (error) {
    yield put(authActions.updateProfileFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.updateProfileSuccess(result.payload))
  }

  return { error, result };
}

export function* handleChangePassword(action: PayloadAction<ChangePasswordPayload>) {
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/users/change-password`,
  });

  if (error) {
    yield put(authActions.changePasswordFailed())
  } else {
    toast.success(result.message)
    yield put(authActions.changePasswordSuccess())
  }

  return { error, result };
}

export function* authSaga() {
  yield takeLatest(authActions.login.type, handleLogin);
  yield takeLatest(authActions.register.type, handleRegister);
  yield takeLatest(authActions.forgotPassword.type, handleForgotPassword);
  yield takeLatest(authActions.logout.type, handleLogout);
  yield takeLatest(authActions.acceptInvite.type, handleAcceptInvite);
  yield takeLatest(authActions.emailVerification.type, handleEmailVerification);
  yield takeLatest(authActions.resetPasswordLinkVerification.type, handleResetPasswordLinkVerification);
  yield takeLatest(authActions.resetPassword.type, handleResetPassword);
  yield takeLatest(authActions.updateProfile.type, handleEditProfile);
  yield takeLatest(authActions.changePassword.type, handleChangePassword)
}
