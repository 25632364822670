import React, { FunctionComponent } from 'react';

interface FeedbackScreenshotModalProps {
  url: string;
}

export const FeedbackScreenshotModel: FunctionComponent<FeedbackScreenshotModalProps> = (props) => {
  return (
    <div>
      <img alt="screenshot" className="img-fluid" src={props.url} />
    </div>

  );
};
