import { Modal } from '../../../components/modals/modal';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AddTeamMemberModal } from '../../../components/modals/addTeamMemberModal';
import { organizationActions, getOrganizationTeamMembers, getAddNewMemberStatus, selectCurrentOrganization, fetchTeamMemberStatus } from '../../../features/organizations/organizationSlice';
import { capitalizeString, nameToInitials } from '../../../utils/common';
import { AddTeamMember } from '../../../models/organization';
import { CustomLoader } from '../../../components/customLoader';

const TeamMembers = () => {

  const dispatch = useAppDispatch();

  const [isAddTeamMemberModalShown, setIsAddTeamMemberModalShown] = useState<boolean>(false);
  const currentOrganization: any = useAppSelector(selectCurrentOrganization); // List of current/active organisation
  const teamMembers: any = useAppSelector(getOrganizationTeamMembers);
  const addNewMemberStatus: string = useAppSelector(getAddNewMemberStatus);
  const getTeamMemberStatus: string = useAppSelector(fetchTeamMemberStatus);

  /**List organization on page load */
  useEffect(() => {
    dispatch(organizationActions.listOrganizationMembers(currentOrganization._id))
  }, [currentOrganization])

  /**Disptach action to call api to add new team member */
  const addNewTeamMember = (data: AddTeamMember) => {
    let obj = {
      data,
      organisation_id: currentOrganization._id
    }
    dispatch(organizationActions.addNewTeamMember(obj))
  }

  /**When team member added, close the popup */
  useEffect(() => {
    if (addNewMemberStatus == 'success') {
      setIsAddTeamMemberModalShown(false)
    }
  }, [addNewMemberStatus])

  const resendInvite = (user_id: string) => {
    let obj = {
      data: { user_id: user_id },
      organisation_id: currentOrganization._id
    }
    dispatch(organizationActions.resendInvite(obj))
  }

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Team Members</h1>
          <div className="d-flex justify-content-between align-items-center">
            <button type="button" className="btn btn-primary addMemberBtn" data-bs-toggle="modal" onClick={() => setIsAddTeamMemberModalShown(!isAddTeamMemberModalShown)}>
              <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7H13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7 1V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              Add Member
            </button>
            {/* <div className="notificationMenu">
              <span>
                <img src="assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div> */}
          </div>
        </div>
        <Modal
          isShown={isAddTeamMemberModalShown}
          hide={() => setIsAddTeamMemberModalShown(!isAddTeamMemberModalShown)}
          headerText="Confirmation"
          modalContent={
            <AddTeamMemberModal
              addNewTeamMember={addNewTeamMember}
              onCancel={() => setIsAddTeamMemberModalShown(false)}
            />
          }
        />
        <div className="row">
          <div className="col-12 mt-4">
            <div className="table-responsive">
              {teamMembers && teamMembers.length > 0 && teamMembers.map((user: any, idx: number) => (
                getTeamMemberStatus == 'success' && <div className="session__detail team_member__details table" key={idx}>
                  <div className="session_dtl_col tr">
                    <div className="session_dtl1 td">
                      <div className="media">
                        {user.user_id?.firstname && <span className="media_letter">
                          {user.user_id?.firstname ? nameToInitials(`${user.user_id?.firstname} ${user.user_id?.lastname}`) : 'n/a'}
                        </span>}
                        {user.user_id?.firstname == '' && <span className="media_letter">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.00025 12.5552C12.1911 12.5552 14.7778 9.96847 14.7778 6.7776C14.7778 3.58672 12.1911 1 9.00025 1C5.80937 1 3.22266 3.58672 3.22266 6.7776C3.22266 9.96847 5.80937 12.5552 9.00025 12.5552Z" stroke="#5A341F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M1 16.7117C1.90234 15.4282 3.10026 14.3806 4.49261 13.6574C5.88497 12.9343 7.43088 12.5567 8.99984 12.5566C10.5688 12.5566 12.1147 12.934 13.5072 13.6571C14.8996 14.3802 16.0976 15.4276 17 16.7111" stroke="#5A341F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg>
                        </span>}
                        <div className="media-body">
                          <h5 className="mb-0">{user.user_id?.firstname ? capitalizeString(`${user.user_id?.firstname} ${user.user_id?.lastname}`) : 'n/a'} </h5>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl2 td" style={{ minWidth: '305px' }}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/email.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            {user.user_id?.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl3 td" style={{ minWidth: '255px' }}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Gear2.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">{user.role == 'org-admin' ? 'Admin' : 'User'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl2 td" style={{ minWidth: '290px' }}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/email.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            {user.status == 1 ? 'Active' : (user.status == 2 ? 'Pending' : 'Deleted')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl3 td" style={{ minWidth: '290px' }}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Gear2.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          {user.status != 2 && <p className="mb-0 d-flex align-items-center">{'Invite Sent'}</p>}
                          {user.status == 2 && <button className="btn btn-primary" onClick={() => resendInvite(user.user_id._id)}> Resend Invite</button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>))}
            </div>
          </div>
          {getTeamMemberStatus && getTeamMemberStatus == 'pending' && <CustomLoader></CustomLoader>}
          {(!teamMembers && teamMembers.length == 0) && getTeamMemberStatus == 'success' && <div className="noDataFound text-center my-4">
            <svg width="100%" height="auto" viewBox="0 0 415 290" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1126_153)">
                <path d="M415 274.609H0V275.609H415V274.609Z" fill="#739DA8" />
                <path d="M298.579 133.285C294.737 110.261 274.712 92.7148 250.586 92.7148H151.924C127.808 92.7148 107.787 110.247 103.931 133.257C103.485 135.889 103.256 138.595 103.256 141.354V227.266C103.256 230.024 103.485 232.726 103.931 235.358C107 253.69 120.334 268.548 137.83 273.826C142.291 275.174 147.023 275.9 151.924 275.9H250.586C255.487 275.9 260.219 275.174 264.68 273.826C282.18 268.544 295.52 253.676 298.579 235.33C299.02 232.707 299.249 230.015 299.249 227.266V141.354C299.249 138.605 299.02 135.908 298.579 133.285ZM258.936 273.826C256.223 274.308 253.435 274.561 250.586 274.561H151.924C149.075 274.561 146.287 274.308 143.574 273.826C121.421 269.888 104.601 250.539 104.601 227.266V198.749C104.601 118.17 169.958 94.0588 250.586 94.0588C276.722 94.0588 297.909 115.234 297.909 141.354V227.266C234.463 235.751 281.088 269.888 258.936 273.826Z" fill="#BDD4DA" />
                <path d="M220.889 157.545L224.684 182.203L280.355 174.616L274.978 145.807L220.889 157.545Z" fill="#2E515A" />
                <path d="M264.58 19.1836H241.562V42.9946H264.58V19.1836Z" fill="#8BB2BD" />
                <path d="M243.662 44.3742L249.356 27.3037H260.453L261.037 44.3742H243.662Z" fill="#FFB6B6" />
                <path opacity="0.1" d="M243.662 44.3742L249.356 27.3037H260.453L261.037 44.3742H243.662Z" fill="black" />
                <path d="M249.671 196.428L249.039 262.181L248.406 267.239V274.5L257.896 275.458L258.528 260.917C258.528 260.917 268.65 232.466 266.12 210.337C263.589 188.209 264.854 194.531 264.854 194.531L249.671 196.428Z" fill="#FFB6B6" />
                <path d="M255.697 201.592L232.352 263.07L231.1 268.011L228.458 274.774L219.27 272.217L223.973 258.442C223.973 258.442 224.901 228.26 235.313 208.568C245.724 188.876 242.245 194.305 242.245 194.305L255.697 201.592V201.592Z" fill="#FFB6B6" />
                <path d="M243.662 42.4775L226.581 50.0644L237.487 106.341L235.764 187.114L224.377 211.139C224.377 211.139 222.469 224.879 242.08 216.027C261.692 207.176 283.833 204.647 283.833 204.647L278.456 167.661L273.711 70.6122L274.344 49.116L260.426 40.2646L243.662 42.4775V42.4775Z" fill="#C1DBE2" />
                <path d="M234.148 212.017L215.193 180.306L236.854 106.342L226.581 50.0649L244.604 42.3535C244.604 42.3535 253.935 51.4341 253.935 58.9239C253.935 66.4137 234.148 212.017 234.148 212.017V212.017Z" fill="#8BB2BD" />
                <path d="M275.27 205.695L258.064 57.986C257.847 56.1209 258.166 54.2325 258.983 52.5417L263.906 42.3535L283.201 47.852L275.445 108.239L296.17 167.662L275.27 205.695H275.27Z" fill="#8BB2BD" />
                <path d="M259.794 274.194C259.794 274.194 247.775 267.239 246.509 271.033C245.244 274.826 244.612 277.987 244.612 277.987C244.612 277.987 241.448 290 250.938 290C260.427 290 259.794 284.536 259.794 284.536V274.194Z" fill="#8BB2BD" />
                <path d="M217.96 270.35C217.96 270.35 231.688 268.242 231.486 272.235C231.283 276.229 230.722 279.403 230.722 279.403C230.722 279.403 229.296 291.743 220.457 288.293C211.618 284.843 214.196 279.984 214.196 279.984L217.96 270.35V270.35Z" fill="#8BB2BD" />
                <path d="M255.645 36.595C264.331 36.595 271.372 29.5581 271.372 20.8776C271.372 12.1971 264.331 5.16016 255.645 5.16016C246.959 5.16016 239.918 12.1971 239.918 20.8776C239.918 29.5581 246.959 36.595 255.645 36.595Z" fill="#FFB6B6" />
                <path d="M268.209 6.87097C267.477 2.99575 263.497 0.374616 259.564 0.0459335C255.632 -0.282749 251.78 1.2012 248.239 2.94109C245.839 4.12053 243.475 5.44989 241.531 7.28574C239.586 9.12158 238.076 11.5198 237.727 14.1703C237.604 15.108 237.62 16.0878 237.236 16.9522C236.756 18.0321 235.726 18.7655 235.104 19.7705C233.99 21.573 234.502 24.1953 236.213 25.4465C234.062 26.3627 231.852 27.3183 230.212 28.9843C228.572 30.6502 227.625 33.2222 228.525 35.3791C228.843 36.1422 229.369 36.8102 229.642 37.591C230.158 39.0736 229.667 40.7579 228.75 42.033C227.834 43.3081 226.54 44.2536 225.27 45.1767C228.535 45.8163 231.868 46.3148 235.183 46.0163C238.497 45.7179 241.823 44.5571 244.223 42.2534C246.623 39.9497 247.944 36.3961 247.083 33.1837C246.423 30.7228 244.578 28.5664 244.579 26.0188C244.58 24.0604 245.682 22.2939 246.746 20.6493L253.717 9.87472C254.135 9.22867 254.575 8.56014 255.236 8.16498C256.359 7.49274 257.817 7.78837 259.001 8.34632C260.186 8.90429 261.268 9.7048 262.531 10.0507C265.109 10.7568 268.099 9.13534 268.911 6.59075" fill="#694F4F" />
                <path d="M258.919 38.8359C257.608 40.1042 255.719 40.5128 253.969 41.0267C252.219 41.5407 250.357 42.349 249.569 43.9935C248.797 45.6059 249.357 47.6528 250.636 48.9028C251.915 50.1529 253.773 50.6794 255.562 50.6546C257.35 50.6298 259.093 50.1059 260.776 49.4976C265.375 47.8342 269.731 45.5009 273.664 42.5941C271.117 41.8898 269.45 38.9169 270.179 36.3779C270.773 34.3125 272.586 32.872 273.821 31.1126C275.946 28.0832 276.3 24.0899 275.596 20.4576C274.893 16.8252 273.236 13.4581 271.596 10.1409C271.078 9.09234 270.547 8.026 269.738 7.1805C268.93 6.335 267.785 5.72926 266.621 5.8504C264.689 6.05142 263.456 8.17065 263.443 10.1115C263.43 12.0524 264.282 13.8764 264.928 15.7068C265.594 17.5925 266.056 19.5913 265.878 21.583C265.728 23.252 265.121 24.8778 264.139 26.2364C263.381 27.2857 262.415 28.1662 261.591 29.1643C260.954 29.936 260.389 30.8101 260.222 31.7965C259.917 33.5915 260.971 35.4077 260.682 37.2054C260.377 39.1057 258.376 40.5605 256.473 40.266" fill="#694F4F" />
                <path d="M210.773 257.481C240.471 257.481 264.546 233.42 264.546 203.74C264.546 174.06 240.471 150 210.773 150C181.075 150 157 174.06 157 203.74C157 233.42 181.075 257.481 210.773 257.481Z" fill="#56A5BB" />
                <path d="M225.834 149.964C225.183 154.621 222.251 158.06 219.285 157.646C216.32 157.231 214.444 153.12 215.096 148.461C215.331 146.596 216.009 144.815 217.074 143.266L220.083 123.568L229.302 125.176L225.357 144.424C225.957 146.206 226.12 148.106 225.834 149.964V149.964Z" fill="#FFB6B6" />
                <path d="M233.856 49.1161C233.856 49.1161 224.367 45.9549 223.101 57.3352C221.836 68.7156 220.571 82.6248 220.571 82.6248C220.571 82.6248 214.877 95.9019 217.408 102.857C219.938 109.811 224.367 104.962 219.938 110.864C215.51 116.766 216.127 136.184 216.127 136.184L230.377 135.467C230.377 135.467 238.284 83.2571 238.284 81.9926C238.284 80.7281 233.856 49.1161 233.856 49.1161V49.1161Z" fill="#8BB2BD" />
                <path d="M295.536 147.85C295.713 152.549 293.429 156.449 290.437 156.561C287.445 156.672 284.877 152.954 284.701 148.253C284.605 146.376 284.96 144.504 285.737 142.792L285.241 122.872L294.599 122.839L294.094 142.48C294.997 144.129 295.492 145.971 295.536 147.85V147.85Z" fill="#FFB6B6" />
                <path d="M280.928 48.9031C280.928 48.9031 290.472 45.9118 291.533 57.3129C292.595 68.714 293.611 89.5983 293.611 89.5983C293.611 89.5983 299.067 102.975 296.412 109.883C293.758 116.792 289.417 111.864 293.739 117.844C298.061 123.824 296.51 140.688 296.51 140.688L282.275 139.717C282.275 139.717 275.89 89.9142 275.912 88.6499C275.935 87.3856 280.928 48.9031 280.928 48.9031V48.9031Z" fill="#8BB2BD" />
                <path d="M216.88 173.19L214.36 218.1H206.8L204.19 173.19H216.88ZM205.9 235.74C204.64 234.42 204.01 232.86 204.01 231.06C204.01 229.26 204.64 227.7 205.9 226.38C207.16 225.06 208.69 224.4 210.49 224.4C212.35 224.4 213.91 225.06 215.17 226.38C216.49 227.7 217.15 229.26 217.15 231.06C217.15 232.86 216.49 234.42 215.17 235.74C213.91 237 212.35 237.63 210.49 237.63C208.69 237.63 207.16 237 205.9 235.74Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1126_153">
                  <rect width="415" height="290" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h4 className="text-dark mt-5">No data found</h4>
          </div>}
        </div>
      </div>
    </>
  );
};

export default TeamMembers;
