import React, {Fragment, FunctionComponent} from 'react';
import ContentLoader from "react-content-loader";

export const CustomLoader: FunctionComponent = () => {
    const limit = 10;
    const rowHeight = 60
    return(
        <ContentLoader viewBox={`0 0 1500 ${rowHeight * limit}`}>
            {new Array(limit).fill(' ').map((el, index) => {
              const contentVerticalPosition = (contentHeight: any) =>
                limit > 1 ? contentHeight + rowHeight * index : contentHeight
              return (
                <Fragment key={index}>
                  {/* <rect
                    x="20"
                    y={`${contentVerticalPosition(20)}`}
                    rx="4"
                    ry="4"
                    width="40"
                    height="20"
                  /> */}
                  <rect
                    x="20"
                    y={`${contentVerticalPosition(20)}`}
                    rx="4"
                    ry="4"
                    width="470"
                    height="20"
                  />
                  <rect
                    x="550"
                    y={`${contentVerticalPosition(20)}`}
                    rx="10"
                    ry="4"
                    width="500"
                    height="20"
                  />
                  <rect
                    x="1100"
                    y={`${contentVerticalPosition(20)}`}
                    rx="10"
                    ry="4"
                    width="500"
                    height="20"
                  />
                  {/* <rect
                    x="1450"
                    y={`${contentVerticalPosition(20)}`}
                    rx="4"
                    ry="4"
                    width="20"
                    height="20"
                  /> */}
                  {/* <rect
                    y={`${contentVerticalPosition(59)}`}
                    x="10"
                    ry="10"
                    width="1500"
                    height="1"
                  /> */}
                </Fragment>
              )
            })}
          </ContentLoader>
    );
}
