
import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentProject, projectActions, getSlackChannels, getUpdateProjectStatus } from '../../projects/projectSlice';
import { Modal } from '../../../components/modals/modal';
import AddSlackChannel from '../../../components/modals/addSlackChannel';

const Integration = () => {

  const dispatch = useAppDispatch();
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const updateProjectStatus: any = useAppSelector(getUpdateProjectStatus); // List of current/active project 

  const [isSlackChannelModalShown, setIsSlackChannelModalShown] = useState<boolean>(false);
  // const [slackChannels, setSlackChannels] = useState<{name: string; id: string}[]>(
  //   [],
  // );
  const channels: any = useAppSelector(getSlackChannels)
 
  useEffect(() => {
    if (currentProject && currentProject.slack?.token) {
      dispatch(projectActions.listSlackChannel(currentProject._id))
    }
  }, [currentProject])

  console.log('currentProject', currentProject)

  const updateSlackChannel = (channel: any) => {
   
    let data = {
      slack_channel: channel.name
    }
    dispatch(projectActions.updateCurrentProject({ data, project_id: currentProject._id }))
  }

  useEffect(() => {
    if (updateProjectStatus && updateProjectStatus == 'success') {
      setIsSlackChannelModalShown(false)
    }
  }, [updateProjectStatus])

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Integrations</h1>
          <div className="d-flex justify-content-between align-items-center">
            {/* <div className="notificationMenu">
              <span>
                <img src="assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="integrations_col d-flex flex-column justify-content-center align-items-center position-relative" onClick={() => setIsSlackChannelModalShown(!isSlackChannelModalShown)}>
              {/* <span className="int_select success"><svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </span> */}
              <div className="integrations_contnt text-center">
                <img src="assets/images/slack-logo.svg" />
                <h6 className="mb-3">Slack</h6>
                <button className="btn btn-outline-light rounded-pill" data-bs-toggle="modal" data-bs-target="#slackChannelModal">
                  {currentProject && (!currentProject.slack || !currentProject.slack.token) && <>
                    <span>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.808V5.192L10.5655 4.682C10.4665 4.3385 10.3295 4.011 10.16 3.7045L10.814 2.329L9.6715 1.1865L8.296 1.84C7.9895 1.6705 7.6615 1.5335 7.3185 1.4345L6.808 0H5.192L4.6815 1.4345C4.3385 1.5335 4.0105 1.67 3.704 1.84L2.3285 1.186L1.186 2.3285L1.84 3.7045C1.6705 4.011 1.533 4.3385 1.4345 4.682L0 5.192V6.808L1.4345 7.318C1.533 7.6615 1.6705 7.989 1.84 8.2955L1.186 9.671L2.3285 10.814L3.704 10.16C4.0105 10.3295 4.3385 10.4665 4.6815 10.5655L5.192 12H6.808L7.3185 10.5655C7.662 10.4665 7.9895 10.3295 8.296 10.16L9.6715 10.814L10.814 9.671L10.16 8.2955C10.3295 7.989 10.4665 7.6615 10.5655 7.318L12 6.808ZM6 8C4.8955 8 4 7.1045 4 6C4 4.8955 4.8955 4 6 4C7.1045 4 8 4.8955 8 6C8 7.1045 7.1045 8 6 8Z" fill="#CCCCCC" />
                      </svg>
                    </span>
                    <a href={`${process.env.REACT_APP_SLACK_URL}?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID
                      }&scope=chat:write,channels:read
    &redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&state=${currentProject && currentProject._id
                      }`}
                      target="_blank">Configure</a>
                  </>}
                  {currentProject && currentProject.slack?.token &&
                    <>
                      <span>
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 1L4 6.99973L1 4" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      <span>Installed</span>
                    </>}
                </button>
              </div>

            </div>
            <div className="integrations_col d-flex flex-column justify-content-center align-items-center position-relative">

              <div className="integrations_contnt text-center">
                <img src="assets/images/icons/github.svg" />
                <h6 className="mb-3">Github</h6>
                <button className="btn btn-outline-light rounded-pill" data-bs-toggle="modal" data-bs-target="#repoSetupModal">Connect</button>
              </div>

            </div>

          </div>
        </div>
        <Modal
          isShown={isSlackChannelModalShown}
          hide={() => setIsSlackChannelModalShown(!isSlackChannelModalShown)}
          headerText="Add Slack Channel"
          modalContent={
            <AddSlackChannel
              channels={channels}
              updateSlackChannel={updateSlackChannel}
              savedChannel={currentProject?.slack?.channel ? currentProject.slack.channel : []}
            />
          }
        />
      </div>
    </>
  );
};

export default Integration;
