import { FunctionComponent, useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { NewFeedbackFormPayload } from '../../models/feedbackForm';

interface AddNewFormModalProps {
  message: string;
  onSubmit: any;
  form?: any
  updateForm?: any
}

export const AddNewFormModal: FunctionComponent<AddNewFormModalProps> = (props) => {

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<NewFeedbackFormPayload>();

  const onSubmit: SubmitHandler<NewFeedbackFormPayload> = data => {
    if (!data.emoji && !data.rating && !data.feedback) {
      setShowValidationError(true)
    } else {
      setShowValidationError(false)
      if (props.form) {
        data.button_id = props.form.button_id
        props.updateForm(data)
      } else {
        props.onSubmit(data)
      }

    }
  }

  useEffect(() => {
    if (props.form) {
      let defaultValues: any = {};
      defaultValues.emoji = props.form.form_fields.emoji;
      defaultValues.rating = props.form.form_fields.rating;
      defaultValues.feedback = props.form.form_fields.feedback;
      defaultValues.position = props.form.position;
      defaultValues.domain = props.form.domain;
      defaultValues.save_screenshot = props.form.save_screenshot;
      reset({ ...defaultValues });
    }

  }, []);


  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-check-col mb-3">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="integrationcompletedCheck" {...register("emoji", {
            onChange: (e) => console.log(e)
          })} />
          <div className="form-check-label-col">
            <label className="form-check-label" htmlFor="integrationcompletedCheck">
              Integration completed
            </label>
            <ul className="list-unstyled d-flex ratingSmiley mt-3 mb-0">
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.8975 12.25C13.5021 12.9338 12.9338 13.5016 12.2496 13.8964C11.5654 14.2911 10.7894 14.4989 9.99953 14.4989C9.20964 14.4989 8.43364 14.2911 7.74944 13.8964C7.06525 13.5017 6.49694 12.9339 6.10156 12.2501" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.62402 9.625C7.45245 9.625 8.12402 8.95343 8.12402 8.125C8.12402 7.29657 7.45245 6.625 6.62402 6.625C5.7956 6.625 5.12402 7.29657 5.12402 8.125C5.12402 8.95343 5.7956 9.625 6.62402 9.625Z" fill="#6F6691" />
                  <path d="M13.374 9.625C14.2025 9.625 14.874 8.95343 14.874 8.125C14.874 7.29657 14.2025 6.625 13.374 6.625C12.5456 6.625 11.874 7.29657 11.874 8.125C11.874 8.95343 12.5456 9.625 13.374 9.625Z" fill="#6F6691" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.8975 12.25C13.5021 12.9338 12.9338 13.5016 12.2496 13.8964C11.5654 14.2911 10.7894 14.4989 9.99953 14.4989C9.20964 14.4989 8.43364 14.2911 7.74944 13.8964C7.06525 13.5017 6.49694 12.9339 6.10156 12.2501" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.62402 9.625C7.45245 9.625 8.12402 8.95343 8.12402 8.125C8.12402 7.29657 7.45245 6.625 6.62402 6.625C5.7956 6.625 5.12402 7.29657 5.12402 8.125C5.12402 8.95343 5.7956 9.625 6.62402 9.625Z" fill="#6F6691" />
                  <path d="M13.374 9.625C14.2025 9.625 14.874 8.95343 14.874 8.125C14.874 7.29657 14.2025 6.625 13.374 6.625C12.5456 6.625 11.874 7.29657 11.874 8.125C11.874 8.95343 12.5456 9.625 13.374 9.625Z" fill="#6F6691" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.8975 12.25C13.5021 12.9338 12.9338 13.5016 12.2496 13.8964C11.5654 14.2911 10.7894 14.4989 9.99953 14.4989C9.20964 14.4989 8.43364 14.2911 7.74944 13.8964C7.06525 13.5017 6.49694 12.9339 6.10156 12.2501" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.62402 9.625C7.45245 9.625 8.12402 8.95343 8.12402 8.125C8.12402 7.29657 7.45245 6.625 6.62402 6.625C5.7956 6.625 5.12402 7.29657 5.12402 8.125C5.12402 8.95343 5.7956 9.625 6.62402 9.625Z" fill="#6F6691" />
                  <path d="M13.374 9.625C14.2025 9.625 14.874 8.95343 14.874 8.125C14.874 7.29657 14.2025 6.625 13.374 6.625C12.5456 6.625 11.874 7.29657 11.874 8.125C11.874 8.95343 12.5456 9.625 13.374 9.625Z" fill="#6F6691" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.8975 12.25C13.5021 12.9338 12.9338 13.5016 12.2496 13.8964C11.5654 14.2911 10.7894 14.4989 9.99953 14.4989C9.20964 14.4989 8.43364 14.2911 7.74944 13.8964C7.06525 13.5017 6.49694 12.9339 6.10156 12.2501" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.62402 9.625C7.45245 9.625 8.12402 8.95343 8.12402 8.125C8.12402 7.29657 7.45245 6.625 6.62402 6.625C5.7956 6.625 5.12402 7.29657 5.12402 8.125C5.12402 8.95343 5.7956 9.625 6.62402 9.625Z" fill="#6F6691" />
                  <path d="M13.374 9.625C14.2025 9.625 14.874 8.95343 14.874 8.125C14.874 7.29657 14.2025 6.625 13.374 6.625C12.5456 6.625 11.874 7.29657 11.874 8.125C11.874 8.95343 12.5456 9.625 13.374 9.625Z" fill="#6F6691" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.8975 12.25C13.5021 12.9338 12.9338 13.5016 12.2496 13.8964C11.5654 14.2911 10.7894 14.4989 9.99953 14.4989C9.20964 14.4989 8.43364 14.2911 7.74944 13.8964C7.06525 13.5017 6.49694 12.9339 6.10156 12.2501" stroke="#6F6691" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.62402 9.625C7.45245 9.625 8.12402 8.95343 8.12402 8.125C8.12402 7.29657 7.45245 6.625 6.62402 6.625C5.7956 6.625 5.12402 7.29657 5.12402 8.125C5.12402 8.95343 5.7956 9.625 6.62402 9.625Z" fill="#6F6691" />
                  <path d="M13.374 9.625C14.2025 9.625 14.874 8.95343 14.874 8.125C14.874 7.29657 14.2025 6.625 13.374 6.625C12.5456 6.625 11.874 7.29657 11.874 8.125C11.874 8.95343 12.5456 9.625 13.374 9.625Z" fill="#6F6691" />
                </svg>
              </span>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="form-check-col mb-3">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="addStartRatingCheck" {...register("rating", {
            onChange: (e) => console.log(e)
          })} />
          <div className="form-check-label-col">
            <label className="form-check-label" htmlFor="addStartRatingCheck">
              Add Star Rating
            </label>

            <ul className="list-unstyled d-flex ratingSmiley mt-3 mb-0">
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3817 15.4288L14.7463 18.194C15.3042 18.5475 15.9969 18.0218 15.8314 17.3705L14.5703 12.4099C14.5348 12.2718 14.539 12.1264 14.5825 11.9905C14.6259 11.8546 14.7068 11.7338 14.8159 11.6418L18.7298 8.38423C19.2441 7.9562 18.9786 7.1027 18.3179 7.05982L13.2066 6.7281C13.0689 6.71826 12.9369 6.66952 12.8258 6.58754C12.7148 6.50557 12.6293 6.39374 12.5794 6.26507L10.6731 1.46452C10.6213 1.32795 10.5291 1.21037 10.4089 1.1274C10.2887 1.04443 10.1461 1 10 1C9.85393 1 9.71132 1.04443 9.5911 1.1274C9.47088 1.21037 9.37874 1.32795 9.32692 1.46452L7.42062 6.26507C7.37068 6.39374 7.2852 6.50557 7.17416 6.58754C7.06312 6.66952 6.93107 6.71826 6.7934 6.7281L1.6821 7.05982C1.02137 7.1027 0.755936 7.9562 1.27021 8.38423L5.18411 11.6418C5.29318 11.7338 5.37409 11.8546 5.41753 11.9905C5.46097 12.1264 5.46517 12.2718 5.42966 12.4099L4.2602 17.0103C4.06154 17.7918 4.89275 18.4227 5.56224 17.9985L9.61828 15.4288C9.73235 15.3562 9.86477 15.3176 10 15.3176C10.1352 15.3176 10.2677 15.3562 10.3817 15.4288V15.4288Z" stroke="#6F6690" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3817 15.4288L14.7463 18.194C15.3042 18.5475 15.9969 18.0218 15.8314 17.3705L14.5703 12.4099C14.5348 12.2718 14.539 12.1264 14.5825 11.9905C14.6259 11.8546 14.7068 11.7338 14.8159 11.6418L18.7298 8.38423C19.2441 7.9562 18.9786 7.1027 18.3179 7.05982L13.2066 6.7281C13.0689 6.71826 12.9369 6.66952 12.8258 6.58754C12.7148 6.50557 12.6293 6.39374 12.5794 6.26507L10.6731 1.46452C10.6213 1.32795 10.5291 1.21037 10.4089 1.1274C10.2887 1.04443 10.1461 1 10 1C9.85393 1 9.71132 1.04443 9.5911 1.1274C9.47088 1.21037 9.37874 1.32795 9.32692 1.46452L7.42062 6.26507C7.37068 6.39374 7.2852 6.50557 7.17416 6.58754C7.06312 6.66952 6.93107 6.71826 6.7934 6.7281L1.6821 7.05982C1.02137 7.1027 0.755936 7.9562 1.27021 8.38423L5.18411 11.6418C5.29318 11.7338 5.37409 11.8546 5.41753 11.9905C5.46097 12.1264 5.46517 12.2718 5.42966 12.4099L4.2602 17.0103C4.06154 17.7918 4.89275 18.4227 5.56224 17.9985L9.61828 15.4288C9.73235 15.3562 9.86477 15.3176 10 15.3176C10.1352 15.3176 10.2677 15.3562 10.3817 15.4288V15.4288Z" stroke="#6F6690" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3817 15.4288L14.7463 18.194C15.3042 18.5475 15.9969 18.0218 15.8314 17.3705L14.5703 12.4099C14.5348 12.2718 14.539 12.1264 14.5825 11.9905C14.6259 11.8546 14.7068 11.7338 14.8159 11.6418L18.7298 8.38423C19.2441 7.9562 18.9786 7.1027 18.3179 7.05982L13.2066 6.7281C13.0689 6.71826 12.9369 6.66952 12.8258 6.58754C12.7148 6.50557 12.6293 6.39374 12.5794 6.26507L10.6731 1.46452C10.6213 1.32795 10.5291 1.21037 10.4089 1.1274C10.2887 1.04443 10.1461 1 10 1C9.85393 1 9.71132 1.04443 9.5911 1.1274C9.47088 1.21037 9.37874 1.32795 9.32692 1.46452L7.42062 6.26507C7.37068 6.39374 7.2852 6.50557 7.17416 6.58754C7.06312 6.66952 6.93107 6.71826 6.7934 6.7281L1.6821 7.05982C1.02137 7.1027 0.755936 7.9562 1.27021 8.38423L5.18411 11.6418C5.29318 11.7338 5.37409 11.8546 5.41753 11.9905C5.46097 12.1264 5.46517 12.2718 5.42966 12.4099L4.2602 17.0103C4.06154 17.7918 4.89275 18.4227 5.56224 17.9985L9.61828 15.4288C9.73235 15.3562 9.86477 15.3176 10 15.3176C10.1352 15.3176 10.2677 15.3562 10.3817 15.4288V15.4288Z" stroke="#6F6690" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3817 15.4288L14.7463 18.194C15.3042 18.5475 15.9969 18.0218 15.8314 17.3705L14.5703 12.4099C14.5348 12.2718 14.539 12.1264 14.5825 11.9905C14.6259 11.8546 14.7068 11.7338 14.8159 11.6418L18.7298 8.38423C19.2441 7.9562 18.9786 7.1027 18.3179 7.05982L13.2066 6.7281C13.0689 6.71826 12.9369 6.66952 12.8258 6.58754C12.7148 6.50557 12.6293 6.39374 12.5794 6.26507L10.6731 1.46452C10.6213 1.32795 10.5291 1.21037 10.4089 1.1274C10.2887 1.04443 10.1461 1 10 1C9.85393 1 9.71132 1.04443 9.5911 1.1274C9.47088 1.21037 9.37874 1.32795 9.32692 1.46452L7.42062 6.26507C7.37068 6.39374 7.2852 6.50557 7.17416 6.58754C7.06312 6.66952 6.93107 6.71826 6.7934 6.7281L1.6821 7.05982C1.02137 7.1027 0.755936 7.9562 1.27021 8.38423L5.18411 11.6418C5.29318 11.7338 5.37409 11.8546 5.41753 11.9905C5.46097 12.1264 5.46517 12.2718 5.42966 12.4099L4.2602 17.0103C4.06154 17.7918 4.89275 18.4227 5.56224 17.9985L9.61828 15.4288C9.73235 15.3562 9.86477 15.3176 10 15.3176C10.1352 15.3176 10.2677 15.3562 10.3817 15.4288V15.4288Z" stroke="#6F6690" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              </li>
              <li><span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3817 15.4288L14.7463 18.194C15.3042 18.5475 15.9969 18.0218 15.8314 17.3705L14.5703 12.4099C14.5348 12.2718 14.539 12.1264 14.5825 11.9905C14.6259 11.8546 14.7068 11.7338 14.8159 11.6418L18.7298 8.38423C19.2441 7.9562 18.9786 7.1027 18.3179 7.05982L13.2066 6.7281C13.0689 6.71826 12.9369 6.66952 12.8258 6.58754C12.7148 6.50557 12.6293 6.39374 12.5794 6.26507L10.6731 1.46452C10.6213 1.32795 10.5291 1.21037 10.4089 1.1274C10.2887 1.04443 10.1461 1 10 1C9.85393 1 9.71132 1.04443 9.5911 1.1274C9.47088 1.21037 9.37874 1.32795 9.32692 1.46452L7.42062 6.26507C7.37068 6.39374 7.2852 6.50557 7.17416 6.58754C7.06312 6.66952 6.93107 6.71826 6.7934 6.7281L1.6821 7.05982C1.02137 7.1027 0.755936 7.9562 1.27021 8.38423L5.18411 11.6418C5.29318 11.7338 5.37409 11.8546 5.41753 11.9905C5.46097 12.1264 5.46517 12.2718 5.42966 12.4099L4.2602 17.0103C4.06154 17.7918 4.89275 18.4227 5.56224 17.9985L9.61828 15.4288C9.73235 15.3562 9.86477 15.3176 10 15.3176C10.1352 15.3176 10.2677 15.3562 10.3817 15.4288V15.4288Z" stroke="#6F6690" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              </li>
            </ul>


          </div>
        </div>
      </div>
      <div className="form-check-col mb-4">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="addTextAreaCheck" {...register("feedback", {
            onChange: (e) => console.log(e)
          })} />
          <div className="form-check-label-col">
            <label className="form-check-label" htmlFor="addTextAreaCheck">
              Add text area
            </label>
          </div>

        </div>
        {/* <div className="textAreaCol">
          <textarea className="form-control" id="exampleFormControlTextarea1" rows={2}></textarea>
        </div> */}
      </div>
      {showValidationError && <span>Please select at least one option</span>}
      <div className="mb-20">
        <label htmlFor="positionBtn" className="form-label">Select button position</label>
        <select className="form-select" aria-label="Default select" {...register("position", { required: true })}>
          <option value="">---Select Position---</option>
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
          <option value="left">Left</option>
          <option value="right">Right</option>
        </select>
        {errors.position && <span>Please select position</span>}
      </div>
      <div className="mb-4 pb-2">
        <label htmlFor="domainName" className="form-label">Domain name</label>
        <input type="text" className="form-control" id="InputEmail1" placeholder="www.example.com" {...register("domain", { required: true, pattern: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/ })} />
        {errors.domain?.type == 'required' && <span>Please enter domain</span>}
        {errors.domain?.type == 'pattern' && <span>Please enter a valid domain name</span>}
      </div>
      <div className="mb-4">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="saveScreenshotCheck" {...register("save_screenshot")} />
          <div className="form-check-label-col">
            <label className="form-check-label" htmlFor="saveScreenshotCheck">
              Do you want to save screenshot?
            </label>
          </div>

        </div>
      </div>
      <button type="submit" className="btn btn-primary">Update Form</button>
      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
    </form>
    //  </div>

    //     </div>
    //   </div>
    // </div>
  )
}