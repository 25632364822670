import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListParams, ListResponse } from '../../models/common';
import { DashbaordDataResponse } from '../../models/dashboard'

export interface DashboardState {
  fetchDashboardData: string
  dashboardData: any
}

const initialState: DashboardState = {
  fetchDashboardData: '',
  dashboardData: {}
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardStats(state, action: PayloadAction<ListParams>) {
      state.fetchDashboardData = 'pending';
    },
    getDashboardStatsSuccess(
      state,
      action: PayloadAction<ListResponse<DashbaordDataResponse>>
    ) {
      console.log('api data---', action.payload)
      state.fetchDashboardData = 'success';
      state.dashboardData = action.payload
    },
    getDashboardStatsFailed(state, action: PayloadAction<string>) {
      state.fetchDashboardData = 'failed';
    },
  },
});

// Actions
export const dashboardActions = dashboardSlice.actions;

// Selectors
export const selectDashboardData = (state: RootState) => state.dashboard.dashboardData;

// Reducer
const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
