import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListParams, ListResponse } from '../../models/common';
import { ListFeedbackResponse } from '../../models/feedback'

export interface FeedbackState {
  feedbacks?: ListFeedbackResponse[];
  totalFeedbacks: number;
  listFeedbacks: string
}

const initialState: FeedbackState = {
  feedbacks: [],
  totalFeedbacks: 0,
  listFeedbacks: ''
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    listFeedback(state, action: PayloadAction<ListParams>) {
      state.listFeedbacks = 'pending';
    },
    listFeedbackSuccess(
      state,
      action: PayloadAction<ListResponse<ListFeedbackResponse>>
    ) {
      state.listFeedbacks = 'success';
      state.feedbacks = action.payload.results;
      state.totalFeedbacks = action.payload.count
    },
    listFeedbackFormsFailed(state, action: PayloadAction<string>) {
      state.listFeedbacks = 'failed';
    }

  },
});

// Actions
export const feedbackActions = feedbackSlice.actions;

// Selectors
export const selectFeedbackList = (state: RootState) => state.feedback.feedbacks;
export const feedbacksCount = (state: RootState) => state.feedback.totalFeedbacks
export const feedbackListStatus = (state: RootState) => state.feedback.listFeedbacks

// Reducer
const feedbackReducer = feedbackSlice.reducer;
export default feedbackReducer;
