import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ListParams } from '../../models/common'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common'
import { feedbackActions } from './feedbackSlice'


function* handleListFeedbacks(action: PayloadAction<ListParams>) {
  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/feedback${query}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(feedbackActions.listFeedbackSuccess(result.payload));
  }
  return { error, result };
}

export function* feedbackSaga() {
  yield takeLatest(feedbackActions.listFeedback.type, handleListFeedbacks);
}
