import { RootState } from './../../app/store';
import { User } from './../../models/user';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LoginResponse, SignupPayload, ForgotPasswordPayload, AcceptInvitePayload, ResetPasswordPayload, EditProfilePayload , EditProfileResponse, ChangePasswordPayload} from '../../models/auth'

export interface LoginPayload {
  email: string;
  password: string;
}

export interface AuthState {
  isLoggedIn: boolean;
  currentUser?: LoginResponse;
  acceptInvitation: string;
  emailVerification: string;
  resetPasswordLinkVerification: string;
  resetPassword: string;
  updateProfileStatus: string;
  changePasswordStatus: string;
}

const initialState: AuthState = {
  isLoggedIn: false,
  currentUser: undefined,
  acceptInvitation: '',
  emailVerification: '',
  resetPasswordLinkVerification: '',
  resetPassword: '',
  updateProfileStatus: '',
  changePasswordStatus: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.isLoggedIn = false;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.isLoggedIn = true;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.isLoggedIn = false;
    },
    register(state, action: PayloadAction<SignupPayload>) {
    },
    forgotPassword(state, action: PayloadAction<ForgotPasswordPayload>) {
    },
    // forgotPasswordSuccess(state, action: PayloadAction<string>) {
    // },
    // forgotPasswordFailed(state, action: PayloadAction<string>) {
    // },
    logout(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
    },
    acceptInvite(state, action: PayloadAction<AcceptInvitePayload>) {
      state.acceptInvitation = 'pending'
    },
    acceptInviteSuccess(state, action: PayloadAction) {
      state.acceptInvitation = 'success'
    },
    acceptInviteFailed(state, action: PayloadAction) {
      state.acceptInvitation = 'failed'
    },
    emailVerification(state, action: PayloadAction<string>) {
      state.emailVerification = 'pending'
    },
    emailVerificationSuccess(state, action: PayloadAction) {
      state.emailVerification = 'success'
    },
    emailVerificationFailed(state, action: PayloadAction) {
      state.emailVerification = 'failed'
    },
    resetPasswordLinkVerification(state, action: PayloadAction<string>) {
      state.resetPasswordLinkVerification = 'pending'
    },
    resetPasswordLinkVerificationSuccess(state, action: PayloadAction) {
      state.resetPasswordLinkVerification = 'success'
    },
    resetPasswordLinkVerificationFailed(state, action: PayloadAction) {
      state.resetPasswordLinkVerification = 'failed'
    },
    resetPassword(state, action: PayloadAction<ResetPasswordPayload>) {
      state.resetPassword = 'pending'
    },
    resetPasswordSuccess(state, action: PayloadAction) {
      state.resetPassword = 'success'
    },
    resetPasswordFailed(state, action: PayloadAction) {
      state.resetPassword = 'failed'
    },
    updateProfile(state, action: PayloadAction<EditProfilePayload>) {
      state.updateProfileStatus = 'pending'
    },
    updateProfileSuccess(state: any, action: PayloadAction<EditProfileResponse>) {
      state.updateProfileStatus = 'success'
      state.currentUser.firstname = action.payload.firstname;
      state.currentUser.lastname = action.payload.lastname;
    },
    updateProfileFailed(state, action: PayloadAction) {
      state.updateProfileStatus = 'failed'
    },
    changePassword(state, action: PayloadAction<ChangePasswordPayload>){
      state.changePasswordStatus = 'pending'
    },
    changePasswordSuccess(state, action: PayloadAction){
      state.changePasswordStatus = 'success'
    },
    changePasswordFailed(state, action: PayloadAction){
      state.changePasswordStatus = 'failed'
    }
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const acceptInviteStatus = (state: RootState) => state.auth.acceptInvitation;
export const emailVerifiactionStatus = (state: RootState) => state.auth.emailVerification;
export const resetPasswordVerificationLinkStatus = (state: RootState) => state.auth.resetPasswordLinkVerification;
export const resetPasswordStatus = (state: RootState) => state.auth.resetPassword;
export const currentUserDetail = (state: RootState) => state.auth.currentUser;
export const changePasswordStatus = (state: RootState) => state.auth.changePasswordStatus;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
