import { RootState } from '../../app/store';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListProjectResponse, AddProjectPayload, SlackAuthenticationPayload, SlackChannelListResponse, UpdateProjectPayload } from '../../models/project';
import { ListParams, ListResponse } from '../../models/common';

export interface ProjectState {
  fetchProject: string;
  projects: ListProjectResponse[];
  currentProject: {},
  setCurrentProject: string,
  addNewProject: string,
  totalProject: number,
  slackAuthenticate: string,
  slackChannels: SlackChannelListResponse[],
  listSlackChannels: string;
  updateProject: string

}

const initialState: ProjectState = {
  fetchProject: '',
  projects: [],
  currentProject: {},
  setCurrentProject: '',
  addNewProject: '',
  totalProject: 0,
  slackAuthenticate: '',
  slackChannels: [],
  listSlackChannels: '',
  updateProject: ''
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    listProject(state, action: PayloadAction<ListParams>) {
      state.fetchProject = 'pending'
    },
    listProjectSuccess(
      state,
      action: PayloadAction<ListResponse<ListProjectResponse>>
    ) {
      state.projects = action.payload.results;
      state.totalProject = action.payload.count;
      state.fetchProject = 'success'
    },
    listProjectFailed(state, action: PayloadAction<string>) {
      state.fetchProject = 'failed'
    },
    setCurrentProject(state, action) {
      state.currentProject = action.payload;
      state.setCurrentProject = 'success';
    },
    addNewProject(state, action: PayloadAction<AddProjectPayload>) {
      state.addNewProject = 'pending'
    },
    addNewProjectSuccess(state, action: PayloadAction<ListProjectResponse>) {
      state.addNewProject = 'success'
      state.projects.push(action.payload)
      state.totalProject = state.totalProject + 1
    },
    addNewProjectFailed(state, action: PayloadAction<string>) {
      state.addNewProject = 'failed'
    },
    slackAuthenticate(state, action: PayloadAction<SlackAuthenticationPayload>) {
      state.slackAuthenticate = 'pending'
    },
    slackAuthenticateSuccess(state, action: PayloadAction<ListProjectResponse>) {
      state.slackAuthenticate = 'success'
    },
    slackAuthenticateFailed(state, action: PayloadAction) {
      state.slackAuthenticate = 'failed'
    },
    listSlackChannel(state, action: PayloadAction<string>) {
      state.listSlackChannels = 'pending'
    },
    listSlackChannelSuccess(state, action: PayloadAction<ListResponse<SlackChannelListResponse>>) {
      state.listSlackChannels = 'success';
      state.slackChannels = action.payload.results
    },
    listSlackChannelFailed(state, action: PayloadAction) {
      state.listSlackChannels = 'failed'
    },
    updateCurrentProject(state, action: PayloadAction<UpdateProjectPayload>) {
      state.updateProject = 'pending'
    },
    updateCurrentProjectSuccess(state, action) {
      state.currentProject = action.payload;
      state.updateProject = 'success';
    },
    updateCurrentProjectFailed(state, action) {
      state.updateProject = 'failed';
    },

  },
});

// Actions
export const projectActions = projectSlice.actions;

// Selectors
export const selectProjectList = (state: RootState) => state.project.projects;
export const selectCurrentProject = (state: RootState) => state.project.currentProject;
export const fetchProjectStatus = (state: RootState) => state.project.fetchProject;
export const setCurrentProjectStatus = (state: RootState) => state.project.setCurrentProject;
export const addNewProjectStatus = (state: RootState) => state.project.addNewProject;
export const getTotalProjectsCount = (state: RootState) => state.project.totalProject;
export const getSlackAuthenticateStatus = (state: RootState) => state.project.slackAuthenticate;
export const getSlackChannels =  (state: RootState) => state.project.slackChannels;
export const getUpdateProjectStatus = (state: RootState) => state.project.updateProject;

// Reducer
const projectReducer = projectSlice.reducer;
export default projectReducer;
