import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SessionResponse } from '../../models/session';
import { ListParams, ListResponse } from '../../models/common';
export interface SessionState {
  fetchSession: string,
  sessions?: SessionResponse[];
  sessionDetail: {},
  totalSessions: number;
  fetchSessionDetail: string;
}

const initialState: SessionState = {
  fetchSession: 'pending',
  sessions: [],
  sessionDetail: {},
  totalSessions: 0,
  fetchSessionDetail: ''

};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    listProjectSessions(state, action: PayloadAction<ListParams>) {
      state.fetchSession = "pending";
    },
    listProjectSessionsSuccess(
      state,
      action: PayloadAction<ListResponse<SessionResponse>>
    ) {
      state.fetchSession = "success";
      state.sessions = action.payload.results;
      state.totalSessions = action.payload.count
    },
    listProjectSessionsFailed(state, action: PayloadAction<string>) {
      state.fetchSession = "failed";
    },
    listSessionDetail(state, action) {
      state.fetchSessionDetail = 'pending'
    },
    listSessionDetailSuccess(state, action) {
      state.fetchSessionDetail = 'success';
      state.sessionDetail = action.payload;
    },
    listSessionDetailFailed(state, action: PayloadAction<string>) {
      state.fetchSessionDetail = 'failed';
    }

  },
});

// Actions
export const sessionActions = sessionSlice.actions;

// Selectors
export const selectSessionLoading = (state: RootState) => state.session.fetchSession;
export const selectSessionList = (state: RootState) => state.session.sessions;
export const sessionCount = (state: RootState) => state.session.totalSessions;
export const sessionDetail = (state: RootState) => state.session.sessionDetail;

// Reducer
const sessionReducer = sessionSlice.reducer;
export default sessionReducer;
