
import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectFeedbackList, feedbacksCount, feedbackActions, feedbackListStatus } from '../feedbackSlice';
import { Pagination } from '../../../components/pagination';
import { selectCurrentProject } from '../../projects/projectSlice';
import { DateTime } from "luxon";
import ReactStars from 'react-stars';
import { FeedbackScreenshotModel } from '../../../components/modals/feedbackScreenshotModal';
import { Modal } from '../../../components/modals/modal';
import { NoDataFound } from '../../../components/noDataFound';
import {CustomLoader} from '../../../components/customLoader'

const FeedbackList = () => {
  const dispatch = useAppDispatch();
  const feedbackFormList = useAppSelector(selectFeedbackList);
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const totalFeedbacks: number = useAppSelector(feedbacksCount); // List of current/active project
  const [isScreenshotModalShown, setIsScreenshotModalShown] = useState<boolean>(false);
  const [screenshotUrl, setScreenshotUrl] = useState<string>("");
  const getFeedbackListStatus = useAppSelector(feedbackListStatus)

  useEffect(() => {
    let data = {
      project_id: currentProject._id,
      skip: 0,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }

    dispatch(feedbackActions.listFeedback(data));
  }, [currentProject]);

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {

    setCurrentPage(page)
    let data = {
      project_id: currentProject._id,
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(feedbackActions.listFeedback(data));
  }

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Feedbacks</h1>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
          <div className="table-responsive feedback-table-responsive">
            {feedbackFormList && feedbackFormList.length > 0 && feedbackFormList.map((feedback, idx) => (
              getFeedbackListStatus == 'success' && <div className="session__detail team_member__details forms_details feedbacks_details table" key={idx}>
                <div className="session_dtl_col tr">
                  <div className="session_dtl1 td">
                    <div className="media">
                      <span className="media_letter">
                        ID
                      </span>
                      <div className="media-body">
                        <h5 className="mb-0">{feedback.button_id?.button_id}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="session_dtl2 sessionCondenced td" style={{ minWidth: '400px' }}>
                    <div className="media">
                      <span className="media_img">
                        <img src="assets/images/icons/ChatTeardropDots.svg" alt="user" />
                      </span>
                      <div className="media-body">
                        <p className="mb-0 d-flex align-items-center">
                          {feedback.feedback ? feedback.feedback : 'n/a'}</p>
                      </div>
                    </div>
                  </div>
                  <div className="session_dtl3 td">
                    <div className="media">
                      <span className="media_letter">
                        SID
                      </span>
                      <div className="media-body">
                        <h5 className="mb-0"><a className="fw-normal" href="">{feedback.session_id ? feedback.session_id : 'n/a'}</a></h5>
                      </div>
                    </div>
                  </div>
                  <div className="session_dtl4 sessionCondenced td">
                    <div className="media">
                      <span className="media_img">
                        <img src="assets/images/icons/dateTime.svg" alt="user" />
                      </span>
                      <div className="media-body">
                        <p className="mb-0 d-flex align-items-center">
                          {DateTime.fromMillis(feedback.created_at).toFormat('MMMM dd, yyyy hh:mm a')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="session_dtl5 td">
                    <div className="media">
                      <div className="media-body">
                        <ul className=" d-flex list-unstyled mb-0">
                          <ReactStars
                            count={5}
                            value={feedback.rating}
                            size={24}
                            edit={false}
                            color2={'#ff9409'} />
                          {/* <li className="me-1">
                            <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9484 6.08838C14.886 5.89239 14.7659 5.71977 14.6039 5.59303C14.4419 5.4663 14.2455 5.39134 14.0402 5.37793L10.3283 5.11817L8.95135 1.65088C8.87683 1.45919 8.74615 1.29448 8.57642 1.17834C8.40669 1.0622 8.20584 1.00004 8.00017 1H7.9992C7.79385 1.00037 7.5934 1.06269 7.42405 1.17881C7.25469 1.29494 7.12432 1.45947 7.04998 1.65088L5.65252 5.13818L1.96111 5.37793C1.75586 5.39134 1.55941 5.4663 1.3974 5.59303C1.23539 5.71977 1.11534 5.89239 1.05291 6.08838C0.987051 6.28715 0.982571 6.50115 1.04005 6.7025C1.09753 6.90385 1.21431 7.08324 1.37517 7.21729L4.21502 9.61768L3.37029 12.9404C3.31207 13.1643 3.32277 13.4004 3.40101 13.6181C3.47925 13.8357 3.62137 14.0246 3.80877 14.1602C3.9897 14.2918 4.20606 14.3659 4.4297 14.3729C4.65334 14.3798 4.8739 14.3193 5.06267 14.1992L7.99236 12.3433C7.99627 12.3403 8.00017 12.3384 8.00896 12.3433L11.1613 14.3403C11.3324 14.4497 11.5326 14.5048 11.7356 14.4985C11.9386 14.4923 12.1349 14.4249 12.299 14.3052C12.4688 14.1824 12.5976 14.0113 12.6685 13.8141C12.7394 13.617 12.7492 13.403 12.6965 13.2002L11.799 9.57031L14.6262 7.21729C14.787 7.08324 14.9038 6.90385 14.9613 6.7025C15.0188 6.50115 15.0143 6.28715 14.9484 6.08838Z" fill="#FF9409" />
                            </svg>
                            </span>
                          </li>
                          <li className="me-1">
                            <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9484 6.08838C14.886 5.89239 14.7659 5.71977 14.6039 5.59303C14.4419 5.4663 14.2455 5.39134 14.0402 5.37793L10.3283 5.11817L8.95135 1.65088C8.87683 1.45919 8.74615 1.29448 8.57642 1.17834C8.40669 1.0622 8.20584 1.00004 8.00017 1H7.9992C7.79385 1.00037 7.5934 1.06269 7.42405 1.17881C7.25469 1.29494 7.12432 1.45947 7.04998 1.65088L5.65252 5.13818L1.96111 5.37793C1.75586 5.39134 1.55941 5.4663 1.3974 5.59303C1.23539 5.71977 1.11534 5.89239 1.05291 6.08838C0.987051 6.28715 0.982571 6.50115 1.04005 6.7025C1.09753 6.90385 1.21431 7.08324 1.37517 7.21729L4.21502 9.61768L3.37029 12.9404C3.31207 13.1643 3.32277 13.4004 3.40101 13.6181C3.47925 13.8357 3.62137 14.0246 3.80877 14.1602C3.9897 14.2918 4.20606 14.3659 4.4297 14.3729C4.65334 14.3798 4.8739 14.3193 5.06267 14.1992L7.99236 12.3433C7.99627 12.3403 8.00017 12.3384 8.00896 12.3433L11.1613 14.3403C11.3324 14.4497 11.5326 14.5048 11.7356 14.4985C11.9386 14.4923 12.1349 14.4249 12.299 14.3052C12.4688 14.1824 12.5976 14.0113 12.6685 13.8141C12.7394 13.617 12.7492 13.403 12.6965 13.2002L11.799 9.57031L14.6262 7.21729C14.787 7.08324 14.9038 6.90385 14.9613 6.7025C15.0188 6.50115 15.0143 6.28715 14.9484 6.08838Z" fill="#FF9409" />
                            </svg>
                            </span>
                          </li>
                          <li className="me-1">
                            <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9484 6.08838C14.886 5.89239 14.7659 5.71977 14.6039 5.59303C14.4419 5.4663 14.2455 5.39134 14.0402 5.37793L10.3283 5.11817L8.95135 1.65088C8.87683 1.45919 8.74615 1.29448 8.57642 1.17834C8.40669 1.0622 8.20584 1.00004 8.00017 1H7.9992C7.79385 1.00037 7.5934 1.06269 7.42405 1.17881C7.25469 1.29494 7.12432 1.45947 7.04998 1.65088L5.65252 5.13818L1.96111 5.37793C1.75586 5.39134 1.55941 5.4663 1.3974 5.59303C1.23539 5.71977 1.11534 5.89239 1.05291 6.08838C0.987051 6.28715 0.982571 6.50115 1.04005 6.7025C1.09753 6.90385 1.21431 7.08324 1.37517 7.21729L4.21502 9.61768L3.37029 12.9404C3.31207 13.1643 3.32277 13.4004 3.40101 13.6181C3.47925 13.8357 3.62137 14.0246 3.80877 14.1602C3.9897 14.2918 4.20606 14.3659 4.4297 14.3729C4.65334 14.3798 4.8739 14.3193 5.06267 14.1992L7.99236 12.3433C7.99627 12.3403 8.00017 12.3384 8.00896 12.3433L11.1613 14.3403C11.3324 14.4497 11.5326 14.5048 11.7356 14.4985C11.9386 14.4923 12.1349 14.4249 12.299 14.3052C12.4688 14.1824 12.5976 14.0113 12.6685 13.8141C12.7394 13.617 12.7492 13.403 12.6965 13.2002L11.799 9.57031L14.6262 7.21729C14.787 7.08324 14.9038 6.90385 14.9613 6.7025C15.0188 6.50115 15.0143 6.28715 14.9484 6.08838Z" fill="#FF9409" />
                            </svg>
                            </span>
                          </li>
                          <li className="me-1">
                            <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9484 6.08838C14.886 5.89239 14.7659 5.71977 14.6039 5.59303C14.4419 5.4663 14.2455 5.39134 14.0402 5.37793L10.3283 5.11817L8.95135 1.65088C8.87683 1.45919 8.74615 1.29448 8.57642 1.17834C8.40669 1.0622 8.20584 1.00004 8.00017 1H7.9992C7.79385 1.00037 7.5934 1.06269 7.42405 1.17881C7.25469 1.29494 7.12432 1.45947 7.04998 1.65088L5.65252 5.13818L1.96111 5.37793C1.75586 5.39134 1.55941 5.4663 1.3974 5.59303C1.23539 5.71977 1.11534 5.89239 1.05291 6.08838C0.987051 6.28715 0.982571 6.50115 1.04005 6.7025C1.09753 6.90385 1.21431 7.08324 1.37517 7.21729L4.21502 9.61768L3.37029 12.9404C3.31207 13.1643 3.32277 13.4004 3.40101 13.6181C3.47925 13.8357 3.62137 14.0246 3.80877 14.1602C3.9897 14.2918 4.20606 14.3659 4.4297 14.3729C4.65334 14.3798 4.8739 14.3193 5.06267 14.1992L7.99236 12.3433C7.99627 12.3403 8.00017 12.3384 8.00896 12.3433L11.1613 14.3403C11.3324 14.4497 11.5326 14.5048 11.7356 14.4985C11.9386 14.4923 12.1349 14.4249 12.299 14.3052C12.4688 14.1824 12.5976 14.0113 12.6685 13.8141C12.7394 13.617 12.7492 13.403 12.6965 13.2002L11.799 9.57031L14.6262 7.21729C14.787 7.08324 14.9038 6.90385 14.9613 6.7025C15.0188 6.50115 15.0143 6.28715 14.9484 6.08838Z" fill="#FF9409" />
                            </svg>
                            </span>
                          </li>
                          <li className="me-1">
                            <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9484 6.08838C14.886 5.89239 14.7659 5.71977 14.6039 5.59303C14.4419 5.4663 14.2455 5.39134 14.0402 5.37793L10.3283 5.11817L8.95135 1.65088C8.87683 1.45919 8.74615 1.29448 8.57642 1.17834C8.40669 1.0622 8.20584 1.00004 8.00017 1H7.9992C7.79385 1.00037 7.5934 1.06269 7.42405 1.17881C7.25469 1.29494 7.12432 1.45947 7.04998 1.65088L5.65252 5.13818L1.96111 5.37793C1.75586 5.39134 1.55941 5.4663 1.3974 5.59303C1.23539 5.71977 1.11534 5.89239 1.05291 6.08838C0.987051 6.28715 0.982571 6.50115 1.04005 6.7025C1.09753 6.90385 1.21431 7.08324 1.37517 7.21729L4.21502 9.61768L3.37029 12.9404C3.31207 13.1643 3.32277 13.4004 3.40101 13.6181C3.47925 13.8357 3.62137 14.0246 3.80877 14.1602C3.9897 14.2918 4.20606 14.3659 4.4297 14.3729C4.65334 14.3798 4.8739 14.3193 5.06267 14.1992L7.99236 12.3433C7.99627 12.3403 8.00017 12.3384 8.00896 12.3433L11.1613 14.3403C11.3324 14.4497 11.5326 14.5048 11.7356 14.4985C11.9386 14.4923 12.1349 14.4249 12.299 14.3052C12.4688 14.1824 12.5976 14.0113 12.6685 13.8141C12.7394 13.617 12.7492 13.403 12.6965 13.2002L11.799 9.57031L14.6262 7.21729C14.787 7.08324 14.9038 6.90385 14.9613 6.7025C15.0188 6.50115 15.0143 6.28715 14.9484 6.08838Z" fill="#FF9409" />
                            </svg>
                            </span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="media session_dtl6 sessionCondenced td">
                    <div className="media">
                      {feedback.emoji == 1 && <span className="media_img me-4">
                        <img src="assets/images/icons/smile.svg" alt="user" />
                      </span>}
                      {feedback.emoji == 2 && <span className="media_img me-4">
                        <img src="assets/images/icons/smile.svg" alt="user" />
                      </span>}
                      {feedback.emoji == 3 && <span className="media_img me-4">
                        <img src="assets/images/icons/smile.svg" alt="user" />
                      </span>}
                      {feedback.emoji == 4 && <span className="media_img me-4">
                        <img src="assets/images/icons/smile.svg" alt="user" />
                      </span>}
                      {feedback.emoji == 5 && <span className="media_img me-4">
                        <img src="assets/images/icons/smile.svg" alt="user" />
                      </span>}
                      {feedback.screenshot && <div className="media-body" style={{ cursor: 'pointer' }} onClick={() => { setScreenshotUrl(feedback.screenshot); setIsScreenshotModalShown(!isScreenshotModalShown); }}>
                        <img width="45px" src={`${feedback.screenshot}`} alt="user" />
                      </div>}
                    </div>
                  </div>
                </div>
              </div>))}
            <Modal
              isShown={isScreenshotModalShown}
              hide={() => setIsScreenshotModalShown(!isScreenshotModalShown)}
              headerText="Feedback Screenshot"
              modalContent={
                <FeedbackScreenshotModel
                  url={screenshotUrl}
                />
              }
            />
            {(feedbackFormList && feedbackFormList.length == 0) && getFeedbackListStatus == 'success' && 
              <NoDataFound></NoDataFound>
            }
            {getFeedbackListStatus == 'pending' && <CustomLoader></CustomLoader>}
            </div>
            {(totalFeedbacks && totalFeedbacks > 0) && <Pagination
              totalRecords={totalFeedbacks}
              currentPage={currentPage}
              onPageChange={(page: number) => onPageChange(page)}
              limit={limit}
              siblingCount={1}
            ></Pagination>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackList;
