import React, { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { AddTeamMember, AddTeamMemberPayload } from '../../models/organization'
interface AddTeamMemberModalProps {
  addNewTeamMember: (data: AddTeamMember) => void
  onCancel: () => void
}

export const AddTeamMemberModal: FunctionComponent<AddTeamMemberModalProps> = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm<AddTeamMember>();

  const onSubmit: SubmitHandler<AddTeamMember> = data => {
     props.addNewTeamMember(data)
  }

  const onCancel = () =>{
    props.onCancel()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="Email1" className="form-label">Email address</label>
        <input type="email" className="form-control" id="InputEmail1" placeholder="Enter email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
        {errors.email?.type == 'required' && <span>Please enter an email</span>}
        {errors.email?.type == 'pattern' && <span>Please enter a valid email</span>}
      </div>
      <div className="mb-4 pb-2">
        <label htmlFor="Email1" className="form-label">Role</label>
        <select className="form-select" aria-label="Default select example" {...register("role", { required: true })}>
          <option value="">Select Role</option>
          <option value="org-admin">Admin</option>
          <option value="org-user">User</option>
        </select>
        {errors.role && <span>Please select role</span>}
      </div>

      <button type="submit" className="btn btn-primary">Send Invitation</button>
      <button type="button" className="btn btn-secondary" onClick={()=> onCancel()}>Cancel</button>
    </form>
  );
};
