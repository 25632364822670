import TagsInput from 'react-tagsinput';
import React, { useEffect, useState } from "react";
import { organizationActions, selectCurrentOrganization } from '../../../features/organizations/organizationSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

const Privacy = () => {

  const dispatch = useAppDispatch();
  const [tags, setTags] = useState<any>([])
  const [error, setError] = useState<boolean>(false)
  const currentOrganization: any = useAppSelector(selectCurrentOrganization);
  console.log('current org', currentOrganization)

  useEffect(() => {
    if (currentOrganization.excluded_keywords && currentOrganization.excluded_keywords.length > 0) {
      setTags(currentOrganization.excluded_keywords)
    }
  }, [currentOrganization])

  const handleChange = (tags: any) => {
    if (tags && tags.length > 0) setError(false)
    setTags(tags)
  }

  const saveChanges = () => {
    if (tags.length == 0) {
      setError(true)
    }
    else {
      let obj = {
        data: { keywords: tags },
        organisation_id: currentOrganization._id
      }
      dispatch(organizationActions.addPrivacy(obj))
    }

  }

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Privacy</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="notificationMenu">
              <span>
                <img src="../assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="md-cont-wrapper bg-white w-100">
              <div className="col-md-4">
                <form className="project-setting-form">
                  <div className="mb-4 pb-2">
                    <label htmlFor="">Keywords to exclude</label>
                    {/* <input type="text" className="form-control" placeholder="Type something and press enter..." /> */}
                    <TagsInput value={tags} onChange={handleChange} />
                    {error && <span className="text-danger">Please enter the keywords to exclude</span>}
                  </div>
                  <button type="button" className="btn btn-primary primaryDefaultBtn" onClick={() => saveChanges()}>Save Changes</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
