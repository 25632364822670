import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
  showHideButton?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
  showHideButton=true
}) => {

  const modal = (
    <React.Fragment>
      <div className={`modal fade ${isShown ? 'show' : ''}`} aria-labelledby="CreateNewCompanyModalLabel" aria-hidden="true" style={{display: 'block'}} tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pt-0 px-0">
              <h5 className="modal-title" id="CreateNewCompanyModalLabel">{headerText}</h5>
              {showHideButton && <button type="button" className="btn-close" aria-label="Close" onClick={hide}></button>}
            </div>
            <div className="modal-body px-0 pb-0">
              {modalContent}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </React.Fragment>
    
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
