
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Feedbacks from '../components/feedbacks';
import Analytics from '../components/analytics';
import { dashboardActions, selectDashboardData } from '../dashboardSlice';
import { useEffect } from 'react';
import { selectCurrentProject } from '../../projects/projectSlice'
import { feedbackActions, selectFeedbackList, feedbacksCount } from '../../feedbacks/feedbackSlice';


const Dashboard = () => {
  const dispatch = useAppDispatch();
  const dashboardInsights: any = useAppSelector(selectDashboardData);
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const feedbackList: any = useAppSelector(selectFeedbackList) // List feedbacks
  const totalFeedbacks: number = useAppSelector(feedbacksCount) // Get total feedbacks

  /**Get dashboard data based on current project, if current project is changed, fetch data again */
  useEffect(() => {
    let data = {
      project_id: currentProject._id
    }
    if (currentProject?._id) {
      dispatch(dashboardActions.getDashboardStats(data.project_id))
    }

  }, [currentProject])

  /** Get feedbacks to be shown on dahsboard */
  useEffect(() => {
    let data = {
      project_id: currentProject._id,
      skip: 0,
      limit: 3,
      sort_key: 'created_at',
      sort_order: -1
    }
    if (currentProject?._id) {
      dispatch(feedbackActions.listFeedback(data));
    }
   
  }, [currentProject]);

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Dashboard</h1>
        </div>
        <div className="row">
          <div className="col-lg-9 pe-5 mt-4">
            <div className="session__col_row d-flex justify-content-between align-items-center">
              <div className="session__col position-relative">
                <h6>Sessions in progress</h6>
                <div className="session_rate">
                  {dashboardInsights.sessionInProgress}
                  <span>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
              <div className="session__col position-relative" style={{ background: '#FB8500' }}>
                <h6>Today’s session</h6>
                <div className="session_rate">
                  {dashboardInsights.todaySessions}
                  <span style={{ background: '#023047', color: '#fff' }}>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
              <div className="session__col position-relative" style={{ background: '#081C15' }}>
                <h6>Total number sessions
                </h6>
                <div className="session_rate">
                  {dashboardInsights.totalSessions}
                  <span style={{ color: '#fff' }}>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
            </div>
            {/* Load graph component */}
            <Analytics></Analytics>
            <div className="today_session_row d-flex justify-content-between align-items-center pb-5">
              <div className="session__col position-relative" style={{ background: '#ffffff' }}>
                <h6>Today’s feedback</h6>
                <div className="session_rate">
                  {dashboardInsights.todayFeedback}
                  <span>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
              <div className="session__col position-relative" style={{ background: '#ffffff' }}>
                <h6>Today’s issues</h6>
                <div className="session_rate">
                  {dashboardInsights.todayIssues}
                  <span style={{ background: '#023047', color: '#fff' }}>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
              <div className="session__col position-relative" style={{ background: '#ffffff' }}>
                <h6>Total issues</h6>
                <div className="session_rate">
                  {dashboardInsights.totalIssues}
                  <span style={{ color: '#fff' }}>3% <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.15 0.275019L0.968183 3.45683C0.923687 3.50134 0.893386 3.55803 0.881112 3.61975C0.868837 3.68147 0.87514 3.74545 0.899224 3.80359C0.923308 3.86173 0.96409 3.91142 1.01642 3.94638C1.06874 3.98134 1.13026 4 1.19319 4L7.55682 4C7.61975 4 7.68126 3.98134 7.73359 3.94638C7.78591 3.91141 7.82669 3.86172 7.85078 3.80358C7.87486 3.74545 7.88116 3.68147 7.86889 3.61975C7.85662 3.55803 7.82632 3.50134 7.78182 3.45683L4.60001 0.275019C4.57046 0.245471 4.53538 0.222031 4.49677 0.206039C4.45817 0.190048 4.41679 0.181817 4.375 0.181817C4.33321 0.181817 4.29184 0.190048 4.25323 0.206039C4.21462 0.222031 4.17955 0.245471 4.15 0.275019Z" fill="#023047" />
                  </svg>
                  </span>
                </div>
              </div>
            </div>

          </div>
          {/* Load feedback component */}
          <Feedbacks feedbacks={feedbackList} totalCount={totalFeedbacks}></Feedbacks>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
