
import { FunctionComponent } from "react";
import { usePagination, DOTS } from '../app/usePagination';

interface PaginationProps {
  totalRecords: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  limit: number;
  siblingCount: number;
}


export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  let totalCount = props.totalRecords;
  let pageSize = props.limit;
  let siblingCount = props.siblingCount;
  let currentPage = props.currentPage

  const paginationRange: any = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage
  });

  if (currentPage === 0 ||( paginationRange?.length < 2)) {
    return null;
  }

  const onNext = () => {
    props.onPageChange(currentPage + 1);
    console.log('Next clicked')
  };

  const onPrevious = () => {
    console.log('previuos clicked')
    props.onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className={currentPage === 1 ? 'page-item preItem me-1 disabled' : 'page-item preItem me-1'} 
         onClick={onPrevious}>
          <span className="page-link">
            <span className="me-1">
              <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 1L1 4L4 7" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            </span>
            Previous</span>
        </li>
        {/* {totalPage && totalPage > 0 && Array.from(Array(totalPage), (e, i) => (
          <li className="page-item"><a className="page-link" href="#">{i + 1}</a></li>

        ))} */}

        {paginationRange.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return <li className="page-item d-flex align-items-center pagesDot"><span>---</span></li>
          }

          return (
            <li className={pageNumber === currentPage ? 'page-item active' : 'page-item'}
            onClick={() => props.onPageChange(pageNumber)}>
              <span className="page-link">{pageNumber}</span></li>

          );
        })}
        <li className={currentPage === lastPage ? 'page-item nextItem ms-1 disabled' : 'page-item nextItem ms-1'} onClick={onNext}>
          <span className="page-link">Next 
          <span className="ms-1"><svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L4 4L1 7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          </span></span>
        </li>
      </ul>
    </nav>
  );
};

