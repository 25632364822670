import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ListParams } from '../../models/common'
import { NewFeedbackFormPayload, NewFeedbackFormResponse} from '../../models/feedbackForm'
import { feedbackFormActions } from './feedbackFormSlice'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common'


function* handleListFeedbackForms(action: PayloadAction<ListParams>) {

  const query = getQuery(action.payload.data);
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/projects/${action.payload.project_id}/buttons${query}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(feedbackFormActions.listFeedbackFormSuccess(result.payload));
  }
  return { error, result };
}

function* handleAddNewFeedbackForms(action: PayloadAction<NewFeedbackFormPayload>) {

  const { result, error } = yield call(httpClient, {
    data: {
      domain: action.payload.domain,
      form_fields: {
        emoji: action.payload.emoji,
        rating: action.payload.rating,
        feedback: action.payload.feedback,
      },
      position: action.payload.position,
      project_id: '62737b9ecbdf330c2d0ae34c',
      save_screenshot: action.payload.save_screenshot,
    },
    method: "post",
    url: `/buttons`,
  });

  if (error) {
    toast.error(error);
  } else {
    yield put(feedbackFormActions.addFeedbackFormSuccess(result.payload))
    toast.success(result.message);
  }

  return { error, result };
}

function* handleUpdateFeedbackForms(action: PayloadAction<NewFeedbackFormPayload>) {

  const { result, error } = yield call(httpClient, {
    data: {
      domain: action.payload.domain,
      form_fields: {
        emoji: action.payload.emoji,
        rating: action.payload.rating,
        feedback: action.payload.feedback,
      },
      position: action.payload.position,
      save_screenshot: action.payload.save_screenshot,
    },
    method: "put",
    url: `/buttons/${action.payload.button_id}`,
  });

  if (error) {
    toast.error(error);
  } else {
    yield put(feedbackFormActions.updateFeedbackFormSuccess(result.payload))
    toast.success(result.message);
  }

  return { error, result };
}

export function* feedbackFormSaga() {
  yield takeLatest(feedbackFormActions.listFeedbackForm.type, handleListFeedbackForms);
  yield takeLatest(feedbackFormActions.addFeedbackForm.type, handleAddNewFeedbackForms);
  yield takeLatest(feedbackFormActions.updateFeedbackForm.type, handleUpdateFeedbackForms);
}
