import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentProject, projectActions } from '../../projects/projectSlice';
import { useForm, SubmitHandler } from "react-hook-form";

const ProjectSetting = () => {

  type ProjectSettingInput = {
    name: string,
    app_id: string,
  };

  const dispatch = useAppDispatch();
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const { register, handleSubmit, formState: { errors }, reset } = useForm<ProjectSettingInput>();

  const onSubmit: SubmitHandler<ProjectSettingInput> = data => {
    let obj = {
      name: data.name
    }
    dispatch(projectActions.updateCurrentProject({ data: obj, project_id: currentProject._id }))
  }

  useEffect(() => {
    if (currentProject) {
      let defaultValues: any = {};
      defaultValues.name = currentProject.name;
      defaultValues.app_id = currentProject.app_id
      reset({ ...defaultValues });
    }

  }, [currentProject]);

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">General Settings</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="notificationMenu">
              <span>
                <img src="../assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="md-cont-wrapper bg-white w-100">
              <div className="col-md-4">
                <form className="project-setting-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-20">
                    <label>Name</label>
                    <input type="text" className="form-control" placeholder="Project Name"  {...register("name", { required: true })} />
                    {errors.name && <span>Please enter project name</span>}
                  </div>
                  <div className="mb-5">
                    <label>App ID</label>
                    <input type="text" className="form-control" placeholder="gjMr9gVb-frPJMwpb" disabled={true} {...register("app_id", { required: true })} />
                  </div>
                  <button type="submit" className="btn btn-primary">Save Changes</button>
                </form>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSetting;
