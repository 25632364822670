import { useForm, SubmitHandler } from "react-hook-form";
import { FunctionComponent, useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { authActions, currentUserDetail, changePasswordStatus } from '../../auth/authSlice';
import { nameToInitials, capitalizeString } from '../../../utils/common'

const Profile = () => {

  type EditProfileInput = {
    firstname: string,
    lastname: string,
  };

  type ChangePasswordInput = {
    oldPassword: string,
    password: string,
    cpassword: string
  }

  const { register, handleSubmit, formState: { errors }, reset } = useForm<EditProfileInput>();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch1,
    formState: { errors: errors2 },
    getValues: getValues2,
    reset: reset2
  } = useForm<ChangePasswordInput>();

  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<EditProfileInput> = data => {
    dispatch(authActions.updateProfile(data))
  }
  const onSubmitError: SubmitHandler<EditProfileInput> = data => console.log(data);

  const userDetail: any = useAppSelector(currentUserDetail);
  const changePassword: string = useAppSelector(changePasswordStatus)

  const password = watch1('password')

  const onPasswordChangeSubmit: SubmitHandler<ChangePasswordInput> = data => {

    let obj = {
      old_password: data.oldPassword,
      new_password: data.password
    }
    dispatch(authActions.changePassword(obj))
  }

  useEffect(() => {
    if (changePassword == 'success') {
      reset2()
    }
  }, [changePassword])

  useEffect(() => {
    if (userDetail) {
      let defaultValues: any = {};
      defaultValues.firstname = userDetail.firstname ? userDetail.firstname : '';
      defaultValues.lastname = userDetail.lastname ? userDetail.lastname : '';
      reset({ ...defaultValues });
    }
  }, [userDetail]);

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Profile</h1>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex">
            <div className="sm-cont-wrapper bg-white w-100 p-0">
              <div className="UsrProfileSetTop d-flex justify-content-between align-items-center border-bottom p-4">
                <div className="media align-items-center">
                  <span className="logoIconLD">
                    {userDetail ? nameToInitials(userDetail.firstname + " " + userDetail.lastname) : "AU"}
                  </span>
                  <div className="media-body">
                    <h5 className="mb-1 text-secondary">{userDetail ? nameToInitials(userDetail.firstname + " " + userDetail.lastname) : "AU"}</h5>
                    {/* <p className="mb-0">Email: {userDetail?.email}  |  Role: Admin</p> */}
                  </div>
                </div>
                {/* <div className="companies_dtl_btns">
                  <button className="btn btn-outline-light singInBtn">Edit Profile</button>
                </div> */}
              </div>
              <div>
                <form className="project-setting-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="continer-fluid">
                    <div className=" pt-4 px-4 pb-5 border-bottom">
                      <h5>Personal info</h5>
                      <div className="row">
                        <div className="col-md-5 mb-4">
                          <label htmlFor="">First name</label>
                           <input type="text" className="form-control" placeholder="Firstname" {...register("firstname", { required: true })} />
                           {errors.firstname?.type == 'required' && <span>Please enter firstname</span>}
                        </div>
                        <div className="col-md-5 mb-4">
                          <label htmlFor="">Last name</label>
                          <input type="text" className="form-control" placeholder="Lastname" {...register("lastname", { required: true })} />
                          {errors.lastname?.type == 'required' && <span>Please enter lastname</span>}
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="">Email address</label>
                          <input type="email" className="form-control" placeholder="Email address" defaultValue={userDetail?.email} disabled />
                        </div>
                        <div className="col-12">
                          <button type="submit" className="btn btn-info">Edit Profile</button>
                        </div>

                      </div>
                    </div>

                  </div>
                </form>
                <form onSubmit={handleSubmit2(onPasswordChangeSubmit)}>
                  <div className="mb-2 pt-4 px-4 pb-4">
                    <h5>Change password</h5>
                    <div className="row">
                      <div className="col-5 mb-4">
                        <label htmlFor="">Enter old password</label>
                        <input type="password" className="form-control" placeholder="Enter password" {...register2("oldPassword", { required: true })} />
                        {errors2.oldPassword?.type == 'required' && <span>Please enter old password</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 mb-4">
                        <label htmlFor="">New password</label>
                        <input type="password" className="form-control" placeholder="Enter password"  {...register2("password", { required: true })} />
                        {errors2.password?.type == 'required' && <span>Please enter new password</span>}
                      </div>
                      <div className="col-md-5 mb-4 pb-2">
                        <label htmlFor="">Confirm password</label>

                        <input type="password" className="form-control" placeholder="Enter password" {...register2("cpassword", {
                          required: 'Please confirm password again',
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })} />
                        {errors2.cpassword && <span className="text-sm text-red-500">{errors2.cpassword.message}</span>}
                      </div>
                      <div className="col-12 formBottom">
                        <button type="submit" className="btn btn-info">Update Password</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
