
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface networkRequestProps {
  networks: any;
  setNetworkRequest: any
}

export const NetworkRequest: FunctionComponent<networkRequestProps> = (props) => {
  console.log('in sub componnet', props.networks)
  const navigate = useNavigate();

  return (
    <>
      <table className="table table-bordered table-striped mb-0">
        <thead style={{ backgroundColor: '#fff' }}>
          <tr>
            <th style={{ width: '117px' }}>Status</th>
            <th style={{ width: '117px' }}>Method</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3} className="p-0" style={{ verticalAlign: 'top' }}>
              <div style={{ overflow: 'hidden auto', height: '428px' }}>
                <table className="table table-bordered table-striped mb-0">
                  <tbody>
                    {props.networks && props.networks.length > 0 &&
                      props.networks.map((network: any, idx: number) => (
                        <tr key={idx} onClick={() => props.setNetworkRequest(network)}>
                          <td style={{ width: '116px' }}>{network.status}</td>
                          <td style={{ width: '126px' }}>{network.method}</td>
                          <td>{network.url}</td>
                        </tr>))}
                    {(!props.networks || props.networks.length == 0) &&
                      <span>No network request logs found</span>
                    }

                  </tbody>
                </table>

              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </>
  );
};

export default NetworkRequest;
