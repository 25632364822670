import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ListParams, ListResponse } from '../../models/common';
import { ListIssuesResponse  } from '../../models/issue'

export interface IssueState {
  issues?: ListIssuesResponse[];
  totalIssues: number;
  listIssues: string;
  issueDetail: any;
  fetchIssueDetail: string;
  graphData: any[];
  fetchGraphData: string;
}

const initialState: IssueState = {
  issues: [],
  totalIssues: 0,
  listIssues: '',
  issueDetail: {},
  fetchIssueDetail: '',
  graphData: [],
  fetchGraphData: ''
};

const issueSlice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    listIssues(state, action: PayloadAction<ListParams>) {
      state.listIssues = 'pending';
    },
    listIssuesSuccess(
      state,
      action: PayloadAction<ListResponse<ListIssuesResponse>>
    ) {
      state.listIssues = 'success';
      state.issues = action.payload.results;
      state.totalIssues = action.payload.count
    },
    listIssuesFailed(state, action: PayloadAction<string>) {
      state.listIssues = 'failed';
    },
    listIssueDetail(state, action: PayloadAction<ListParams>) {
      state.fetchIssueDetail = 'pending';
    },
    listIssueDetailSuccess(
      state,
      action: PayloadAction<ListResponse<ListIssuesResponse>>
    ) {
      state.fetchIssueDetail = 'success';
      state.issueDetail = action.payload
    },
    listIssueGraphData(state, action: PayloadAction<string>) {
      state.fetchGraphData = 'pending';
    },
    listIssueGraphDataSuccess(state, action: PayloadAction<any>) {
      state.fetchGraphData = 'success';
      state.graphData = action.payload
    },

  },
});

// Actions
export const issueActions = issueSlice.actions;

// Selectors
export const selectIssuesList = (state: RootState) => state.issue.issues;
export const selectIssuesCount = (state: RootState) => state.issue.totalIssues;
export const selectIssueDetail = (state: RootState) => state.issue.issueDetail;
export const selectGraphData = (state: RootState) => state.issue.graphData;
export const listIssuesStatus = (state: RootState) => state.issue.listIssues;

// Reducer
const issueReducer = issueSlice.reducer;
export default issueReducer;
