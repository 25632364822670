import React, { useEffect } from 'react';
import { authActions, resetPasswordVerificationLinkStatus, resetPasswordStatus } from '../authSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";

const ResetPassword = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const linkVerificationStatus: string = useAppSelector(resetPasswordVerificationLinkStatus);
  const resetPwdStatus: string = useAppSelector(resetPasswordStatus);

  const token = searchParams.get('token'); // get token from url query 

  console.log('linkVerificationStatus', linkVerificationStatus)
  console.log('resetPwdStatus', resetPwdStatus)

  useEffect(() => {
    if (token) {
      dispatch(authActions.resetPasswordLinkVerification(token))
    }
  }, [])

  type CredentialInputs = {
    password: string,
    confirmPassword: string
  };

  const { register, handleSubmit, formState: { errors }, watch, getValues } = useForm<CredentialInputs>();

  const onSubmit: SubmitHandler<CredentialInputs> = data => {
    if (token) {
      console.log('data is', data)
      let obj = {
        password: data.password,
        token: token
      }
      dispatch(authActions.resetPassword(obj))
    }


  }

  return (
    <>
      <div>
        {(!resetPwdStatus || resetPwdStatus == '') && (linkVerificationStatus == 'success') && <div className="beforeLoginContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5>Change Password</h5>
            <div className="mb-3 pb-1">
              <label >New password</label>
              <input type="password" className="form-control" placeholder="Enter password" {...register("password", { required: true })} />
              {errors.password && <span>Please enter password</span>}
            </div>
            <div className="mb-3 pb-1">
              <label >Confirm password</label>
              <input type="password" className="form-control" placeholder="Confirm password" {...register("confirmPassword", { required: true })} />
              {errors.confirmPassword && <span>Please enter confirm password</span>}
              {watch("confirmPassword") !== watch("password") &&
                getValues("confirmPassword") ? (
                <p>Password not match</p>
              ) : null}
            </div>
            <button type="submit" className="btn btn-info">Reset Password</button>
          </form>
        </div>}

        {resetPwdStatus && resetPwdStatus == 'success' && linkVerificationStatus == 'success' && <div className="beforeLoginContainer">
          <div className="confirmationMessageCol text-center">
            <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="37.5" cy="37.5" r="37" stroke="#2EB67D" />
              <path d="M52.2812 28.0938L33.4688 46.9054L24.0625 37.5" stroke="#2EB67D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h5>Password has been reset successfully</h5>
            <button type="button" className="btn btn-info lgnBttN" onClick={() => navigate("/")}>Login</button>
          </div>
        </div>}

        {linkVerificationStatus && linkVerificationStatus == 'failed' && <div className="beforeLoginContainer">
          <div className="confirmationMessageCol text-center">
            <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="37.5" cy="37.5" r="37" stroke="#4B4949" />
              <path d="M36.418 26.6727L40.2076 22.8831C41.7875 21.3061 43.9289 20.4208 46.1612 20.4219C48.3934 20.4229 50.534 21.3101 52.1124 22.8886C53.6909 24.467 54.5781 26.6076 54.5791 28.8398C54.5802 31.0721 53.6949 33.2135 52.1179 34.7934L48.3283 38.583" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M38.5834 48.3244L34.7938 52.114C34.0119 52.8969 33.0835 53.518 32.0615 53.9419C31.0395 54.3657 29.944 54.5841 28.8375 54.5844C27.7311 54.5848 26.6355 54.3671 25.6132 53.9438C24.591 53.5206 23.6621 52.9001 22.8798 52.1177C22.0974 51.3354 21.4769 50.4065 21.0537 49.3843C20.6304 48.362 20.4127 47.2664 20.4131 46.1599C20.4134 45.0535 20.6318 43.958 21.0556 42.936C21.4795 41.914 22.1006 40.9856 22.8835 40.2037L26.6731 36.4141" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M31.0029 26.6748V22.3438" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M26.6728 31.0039H22.3418" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M48.3281 43.9961H52.6592" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M43.9961 48.3242V52.6552" stroke="#4B4949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <h5>Password reset link has been expired</h5>
          </div>
        </div>}
      </div>
    </>
  )
}
export default ResetPassword;