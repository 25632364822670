import { FunctionComponent, useState, useEffect } from "react";
import AddNewCard from "./addNewCard";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectCardsList } from "../billingSlice";

interface PaymentProps {
  currentPlan: any;
  setShowPlans: any
  setCurrentPlan: any
  addNewCard: any
  newCardAddStatus: string
  buySubscription: any;
  updateSource: any;
}

const stripePromise = loadStripe('pk_test_51Kt4clH5n0jAi1ojMWCQgf5iCAy5PmgYhYgx0sgVeL2UXpIX3xzBW4tRu0LElTUyJovzwlckvcX7fMO0ZT2C6o4i00ZJk5cWNB');

export const Payment: FunctionComponent<PaymentProps> = (props) => {

  let { currentPlan, newCardAddStatus, addNewCard, buySubscription, updateSource } = props;
  let cards: any = useAppSelector(selectCardsList);
  const [showAddCards, setShowAddCards] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (newCardAddStatus == 'success') {
      setShowAddCards(false)
    }
  }, [newCardAddStatus])

  const backToPreviousPage = () => {
    props.setCurrentPlan({});
    props.setShowPlans()
  }
  const appearance: any = {
    theme: 'stripe',
  };
  const options = {
    appearance,
  };

  const handleInputChange = (cardId: string) => {
    if (cardId) setErrorMessage('')
    setSelectedCardId(cardId)
    updateSource(cardId)
  }

  const handleSubmit = (event: any) => {
    console.log('inside handle submit-----')
    event.preventDefault();
    if (!selectedCardId || selectedCardId == '') {
      let defaultCardId = cards.find((card: any) => card.id == card.customer.default_source)
      console.log('defaultCardId--', defaultCardId)
      if (!defaultCardId) {
        setErrorMessage('Please select any card')
      } else {
        setSelectedCardId(defaultCardId.id)
        let data = {
          "price_id": currentPlan.id,
          "card_id": defaultCardId.id,
        }
        buySubscription(data)
      }
    }
    else {
      setErrorMessage('')
      let data = {
        "price_id": currentPlan.id,
        "card_id": selectedCardId,
      }
      buySubscription(data)
    }
  }

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="proPlanTop bg-white d-flex justify-content-between align-items-center border-bottom p-4">
          <div className="media align-items-center">
            <div className="media-body">
              <h5 className="mb-1 text-secondary">{currentPlan.product.name} plan <button type="button" className="btn btn-outline-secondary changeBtn" onClick={() => backToPreviousPage()}>Change</button></h5>
              <p className="mb-0">20 Users | Annual payment | € EUR</p>
            </div>
          </div>
          <div className="companies_dtl_btns">
            <p className="mb-0">Annual cost <span className="h5 text-dark" style={{ marginLeft: '12px' }}>€ {(currentPlan.amount) / 100}</span></p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex">
            <div className="sm-cont-wrapper bg-white w-100 p-0 h-auto">
              <Elements options={options} stripe={stripePromise}>
                <div>
                  {!showAddCards && <form className="project-setting-form" onSubmit={handleSubmit}>
                    <div className="continer-fluid">
                      <div className=" pt-4 px-4 pb-5">
                        <h5>Saved cards</h5>
                        <div className="row border-bottom pb-4">
                          <div className="col-md-5">
                            <label>Visa Card</label>
                            {cards.length > 0 && cards.map((card: any, idx: number) => (
                              <div className="form-check cardCheckForm custom-address-check">
                                <input className="form-check-input" type="radio" value={card.id} defaultChecked={card.id == card.customer.default_source} id="cardCheckForm" name="cardCheck" onChange={() => handleInputChange(card.id)} />
                                <label className="form-check-label d-flex justify-content-between align-items-center mb-0 ms-3" htmlFor="cardCheckForm">
                                  <div>
                                    <span className="d-block">**************{card.last4}</span>
                                    <small>{card.name ? card.name : 'n/a'}</small>
                                  </div>
                                  <img src="../assets/images/card.svg" alt="Payment Card" />
                                </label>
                              </div>

                            ))}
                          </div>
                          {errorMessage != '' && <span className="text-danger">{errorMessage}</span>}
                          <div className="col-12 pb-3">
                            <button type="button" className="btn btn-outline-info addNewCardButton" onClick={() => setShowAddCards(true)}>
                              <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 7H13" stroke="#8BB2BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7 1V13" stroke="#8BB2BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                              Add new card
                            </button>
                          </div>
                        </div>
                        <div className="col-12 formBottom companyInfoFromBottom">
                          <p className="text-dark pb-1 mt-3">Your card will be charged € {(currentPlan.amount) / 100} monthly, By clicking ‘Pay now’ you are agree to our <a href="">terms and conditions</a></p>
                          <button type="button" className="btn btn-outline-secondary" onClick={() => backToPreviousPage()}>Back</button>
                          <button type="submit" className="btn btn-info ms-3">Pay Now</button>
                        </div>

                      </div>
                    </div>
                  </form>}
                  {showAddCards &&
                    <AddNewCard
                      setShowAddCards={setShowAddCards}
                      currentPlan={currentPlan}
                      newCardAddStatus={newCardAddStatus}
                      addNewCard={addNewCard}
                      showPaymentButton={true}
                    ></AddNewCard>}

                </div>
              </Elements>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Payment;
