import { Navigate } from "react-router-dom";
import { useAppSelector } from '../../app/hooks';
import { selectIsLoggedIn } from '../../features/auth/authSlice'
import Header from "./header";
import Sidebar from "./sideBar";

const PrivateLayout = ({ component: Component }: any) => {
	return (
		<>
			<div>
				<main>
					<Header></Header>
					<Sidebar></Sidebar>
					<Component />
				</main>
			</div>
		</>
	);
};

export default PrivateLayout;
