
import { useState, useCallback, FunctionComponent } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

export const Loader: FunctionComponent = () => {

  return (
    <div className="loader">
      <LoadingOverlay
        className='overlay-loader'
        active={true}
        spinner={true}
        text='Please wait while we complete your payment process...'
      >
      </LoadingOverlay>
    </div>

  );
};
