
import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { issueActions, selectIssuesList, selectIssuesCount, selectGraphData, listIssuesStatus } from '../issueSlice';
import { Pagination } from '../../../components/pagination';
import { selectCurrentProject } from '../../projects/projectSlice';
import { DateTime } from "luxon";
import { ListIssuesResponse } from '../../../models/issue'
import IssueAnalytics from '../components/issueAnalytics';
import { Link } from "react-router-dom";
import { NoDataFound } from '../../../components/noDataFound';
import { CustomLoader } from '../../../components/customLoader'


const Issue = () => {
  const dispatch = useAppDispatch();
  const issuesList: any = useAppSelector(selectIssuesList);
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page used for pagination
  const [limit, setLimit] = useState<number>(10); // Current page limit used for pagination to show no of records per
  const currentProject: any = useAppSelector(selectCurrentProject); // List of current/active project 
  const totalIssues: number = useAppSelector(selectIssuesCount); // List of current/active project
  const graphData: any = useAppSelector(selectGraphData);
  const fetchIssueStatus = useAppSelector(listIssuesStatus);

  useEffect(() => {
    let data = {
      skip: 0,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(issueActions.listIssues({ data, project_id: currentProject._id }));
    dispatch(issueActions.listIssueGraphData(currentProject._id))
  }, [currentProject])

  /**Set current page and dispatch action to fetch sessions with new skip paramter */
  const onPageChange = (page: number) => {
    setCurrentPage(page)
    let data = {
      skip: (page - 1) * 10,
      limit: 10,
      sort_key: 'created_at',
      sort_order: -1
    }
    dispatch(issueActions.listIssues({ data, project_id: currentProject._id }));
  }

  /**
   ** Get the exception message on type of exception type
   */
  const getExceptionMessage = (issue: any): string => {
    let message;
    if (issue.type == 'networkerror') {
      message = `${issue.request.status} ${issue.request.url}`
    }
    else if (issue.exception) {
      message = issue.exception.message.split("\n")[0]
    }
    return message
  };

  return (
    <>
      <div className="page-content-wrapper pageWrapper slideRightWrapper">
        <div className="topBar d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Issues</h1>
            {/* <div className="notificationMenu">
              <span>
                <img src="assets/images/icons/Bell.svg" alt="notification" />
              </span>
              <span className="notify_dot"></span>
            </div> */}
        </div>
        <div className="row">
          <div className="col-lg-12 mt-4">
            {graphData && graphData.length > 0 && <IssueAnalytics graphData={graphData}></IssueAnalytics>}
            <div className="table-responsive">
              {issuesList?.length > 0 && issuesList.map((issue: ListIssuesResponse, idx: number) => (
                fetchIssueStatus == "success" && <div className="session__detail team_member__details forms_details feedbacks_details table" >
                  <div className="session_dtl_col tr" key={idx}>
                    <div className="session_dtl1 td">
                      <div className="media">
                        <span className="media_letter">
                          <img src="assets/images/icons/iIcon.svg" />
                        </span>
                        <div className="media-body">
                          <h5 className="mb-0">
                            <Link to={`/issue-detail/${issue._id}`}>{getExceptionMessage(issue)}</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl2 sessionCondenced td" style={{ minWidth: '200px' }}>
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/Funnel.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">{issue.type == 'exception' ? 'Exception' : (issue.type == 'jserror' ? 'JS Error' : 'Network Error')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl3 td">
                      <div className="media">
                        <span className="media_letter">
                          <img src="assets/images/icons/Play.svg" />
                        </span>
                        <div className="media-body">
                          <h5 className="mb-0">{issue.session_count} Sessions</h5>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl4 sessionCondenced td">
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/user.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            {issue.user_count} User
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="session_dtl5 td">
                      <div className="media">
                        <span className="media_img">
                          <img src="assets/images/icons/hour.svg" alt="user" />
                        </span>
                        <div className="media-body">
                          <p className="mb-0 d-flex align-items-center">
                            {DateTime.fromMillis(issue.last_seen).toRelative({ unit: "days" })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>))}

              {fetchIssueStatus && fetchIssueStatus == 'pending' && <CustomLoader></CustomLoader>}
              {issuesList?.length == 0 && fetchIssueStatus == 'success' &&
                <NoDataFound></NoDataFound>
              }
            </div>
            {totalIssues && totalIssues > 0 && <Pagination
              totalRecords={totalIssues}
              currentPage={currentPage}
              onPageChange={(page: number) => onPageChange(page)}
              limit={limit}
              siblingCount={1}
            ></Pagination>}
          </div>
        </div>

      </div>

    </>
  );
};

export default Issue;
