import { delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { sessionActions } from './sessionSlice'
import { ListParams } from '../../models/common'
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";
import { getQuery } from '../../utils/common'


function* handleListSession(action: PayloadAction<ListParams>) {

  const query = getQuery(action.payload);
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/sessions/${query}`,
  });

  if (error) {
    toast.error(error)
  } else {

    yield put(sessionActions.listProjectSessionsSuccess(result.payload));
  }
  return { error, result };
}


function* handleListSessionDetail(action: PayloadAction<ListParams>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/sessions/${action.payload}`,
  });

  if (error) {
    toast.error(error)
  } else {
    yield put(sessionActions.listSessionDetailSuccess(result.payload));
  }
  return { error, result };
}

export function* sessionSaga() {
  yield takeLatest(sessionActions.listProjectSessions.type, handleListSession);
  yield takeLatest(sessionActions.listSessionDetail.type, handleListSessionDetail);
}
